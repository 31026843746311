import { usePush } from "vue-baidu-analytics";
export default {
  mounted(el, binding) {
    const { value } = binding;
    el.addEventListener("click", () => {
      // // 触发统计代码，使用 value 参数作为事件标签
      console.log(value);
      usePush().event(value[0], "点击", value[1]);
    });
  },
};
