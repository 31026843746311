import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index.js";
import "normalize.css";
import "@/assets/css/theme.less";
import "@/assets/css/media.less";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// import vue3GoogleLogin from "vue3-google-login";
import i18n from "./lang";
import baiduAnalytics from "vue-baidu-analytics";

import "@/utils/flexible";
import "@/utils/rem";
// 登录组件
import Login from "@/views/LoginRegister/login.vue";
// 返回按钮
// import backBtn from "./components/backBtn.vue";

// 引入Avue
import pinia from "./stores";
// Avue报错插件
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// 引入Axios
import axios from "@/plugins/axiosInstance.js";
import analytics from "./utils/analytics";

import { useUsersStore } from "./stores/user";
import { getClientStyleConfiguration } from "./api/file";
import { setThemeColor } from "@/utils/theme";
import { redirectBasedOnDevice } from "@/utils/mobileDetect";

// app.use(baiduAnalytics, {
//   router: router,
//   siteIdList: [`${theme == "Comfier" ? "1" : "1"}`],
//   isDebug: false,
// });
// `${
//   theme == "Comfier"
//     ? "2d00fcfc3e4ba6ce1186d71b5fc2fd22"
//     : "38aa64620e2f8b802f6c1c4282d05b79"
// }`,

// app.use(vue3GoogleLogin, {
//   clientId:
//     "953803733522-vl57ikru9cn48ub38cq7tkn917u64bb2.apps.googleusercontent.com",
// });
// 从服务器获取主题配置的函数
async function fetchThemeConfig() {
  try {
    const response = await getClientStyleConfiguration();
    const themeConfig = await JSON.parse(response.data);
    return themeConfig;
  } catch (error) {
    console.error("Failed to fetch theme config:", error);
    // 在请求失败时，返回一个默认的主题配置或者处理错误
    return {
      /* 默认主题配置 */
    };
  }
}
// 创建应用程序实例的函数
async function createAppInstance() {
  redirectBasedOnDevice();
  const themeConfig = await fetchThemeConfig();
  console.log(themeConfig, "-themeConfig");
  const app = createApp(App);
  app.use(router);
  // 设置主题配置
  app.config.globalProperties.$theme = themeConfig.brandName;
  app.config.globalProperties.$themeConfig = themeConfig;
  useUsersStore().theme = themeConfig.brandName;
  setThemeColor("--color-primary", themeConfig.themeColor);
  setThemeColor("--el-color-primary", themeConfig.themeColor);
  // 挂载
  app.use(pinia);
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
  // window.axios = axios;
  app.config.globalProperties.$axios = axios; //配置axios的全局引用

  app.use(baiduAnalytics, {
    router: router,
    siteIdList: [themeConfig.baiduClientId],
    isDebug: false,
  });
  app
    .use(ElementPlus)
    .component("Login", Login)
    .use(i18n)
    .directive("analytics", analytics)
    .mount("#app");
}

createAppInstance();
