<template>
  <footer>
    <div class="help">
      <div>
        <i class="iconfont icon-_tuceng_1"></i>
        <div class="title">{{ $t("theme.needMoreHelp") }}</div>
        <p>
          <a :href="'mailto:' + $themeConfig.email">
            {{ $t("theme.contactSupport") }}
          </a>
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="main-container">
        <el-row :gutter="20" justify="space-between" align="middle">
          <el-col :xs="24" :md="20">
            <div class="img">
              <img :src="$themeConfig.logoUrl" />
            </div>
            <span class="line"></span>
            <span class="text">
              {{ $t("theme.copyright", { params: $theme }) }}
            </span>
          </el-col>
          <el-col :xs="24" :md="4" class="share-tool">
            <i
              class="iconfont"
              :class="item.icon"
              v-for="item in shareList"
              :key="item.icon"
              @click="jumpPage(item.url)"
            ></i>
          </el-col>
        </el-row>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { getCurrentInstance, computed, ref } from "vue";
const cf_footer_logo = require("@/assets/imgs/comfier-logo.png");
const sl_footer_logo = require("@/assets/imgs/snailax-logo.png");

const { appContext } = getCurrentInstance();
// const shareList = reactive([
//   {
//     icon: "icon-ri_facebook-fill",
//     url: "https://www.facebook.com/stories/create",
//   },
//   {
//     icon: "icon-mdi_twitter",
//     url: "https://twitter.com/compose/tweet",
//   },
//   {
//     icon: "icon-ph_instagram-logo-fill",
//     url: "https://www.instagram.com/",
//   },
//   {
//     icon: "icon-ic_round-tiktok",
//     url: "https://www.tiktok.com/en/",
//   },
//   {
//     icon: "icon-simple-icons_youtube",
//     url: "https://www.youtube.com/",
//   },
// ]);
const themeConfig = appContext.config.globalProperties.$themeConfig;
const shareList = computed(() => {
  return themeConfig.socialMediaList.map((item) => {
    switch (item.value) {
      case "instagram":
        item.icon = "icon-ph_instagram-logo-fill";
        break;
      case "facebook":
        item.icon = "icon-ri_facebook-fill";
        break;
      case "x":
        item.icon = "icon-mdi_twitter";
        break;
      case "tiktok":
        item.icon = "icon-ic_round-tiktok";
        break;
      case "youtube":
        item.icon = "icon-simple-icons_youtube";
        break;
    }
    return item;
  });
});
const jumpPage = (url) => {
  window.parent.open(url, "_blank");
  // emits("click", 3);
};
</script>
<style scoped lang="less">
.help {
  background-color: var(--color-primary);
  height: 12.8889rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  div {
    text-align: center;
  }
  a {
    color: #fff;
  }
  .title {
    font-size: 1.6rem;
  }
  .iconfont {
    font-size: 2.7rem;
    margin-bottom: 15px;
  }
}
.footer {
  background-color: #f3f3f3;
  height: 5.1667rem;
  line-height: 5.1667rem;
  .main-container {
    div {
      display: flex;
      align-items: center;
    }
    .share-tool {
      .iconfont {
        margin-left: 15px;
        font-size: 2.2222rem;
      }
    }
  }

  // border-bottom: 5px solid #a7a7a7;
  .img {
    img {
      width: 8.6111rem;
    }
  }
  .line {
    width: 1px;
    height: 2.7778rem;
    background-color: #e9e9e9;
    margin: 0 1.1111rem;
  }
  .text {
    font-size: 0.8889rem;
    color: #333;
  }
}
</style>
