<script>
import { getLangName, supportLangs } from "@/lang/index";
import { setCache } from "@/utils/cache";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

/**
 * 多语言切换
 */
export default defineComponent({
  name: "Lang",
  props: {
    onChange: Function,
  },
  setup(props, { slots }) {
    console.log(slots);

    const { locale } = useI18n();
    const langName = ref(getLangName(locale.value));
    const router = useRouter();
    const onClick = (lang) => {
      setCache("CacheLang", lang);
      locale.value = lang;
      langName.value = getLangName(lang);
      props.onChange && props.onChange();
      router.go(0);
    };
    return {
      langName,
      langs: Object.keys(supportLangs),
      supportLangs,
      onClick,
      slots,
    };
  },
});
</script>

<template>
  <el-dropdown @command="onClick">
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-for="x in langs" :key="x" :command="x">
          {{ supportLangs[x].langName }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </template>
    <template v-if="slots.default">
      <slot></slot>
    </template>
    <span v-else class="el-dropdown-link" style="display: flex">
      <span class="langName">{{ langName }}</span>
      <el-icon class="el-icon--right" style="font-size: 14px"
        ><arrow-down
      /></el-icon>
    </span>
  </el-dropdown>
</template>
<style lang="less" scoped>
.langName {
  font-size: 1.2rem;
  color: var(--color-primary);
  cursor: pointer;
}
</style>
