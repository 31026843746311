//导入axios
import axios from "axios";
import { ElMessage } from "element-plus";
import router from "@/router/index.js";
import i18n, { getLocaleLang } from "@/lang";

const { t } = i18n.global;
//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = 30000;
const redirectLogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  router.go(0);

  return;
};

// 请求拦截

axios.interceptors.request.use(
  (config) => {
    config.timeout = 30000;
    const token = window.localStorage.getItem("token"); //储存接口
    // 此处对请求进行配置
    if (token) {
      config.headers["token"] = token;
    }
    config.headers["Accept-Language"] = getLocaleLang();
    return config;
  },
  (error) => {
    console.log(error, "error");
    return Promise.reject(error);
  }
);
// /响应拦截
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if ([10021, 10020].includes(response.data.code)) {
      // ElMessage.error("Login timeout, please login again");
      redirectLogin();
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error.message.includes("timeout")) {
      ElMessage.error(t("theme.connectionErr"));
    }
    console.log("error-CHAOSHI", error);
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);

// axios.defaults.baseURL = 'http://192.168.100.187:9999/admin';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//导出我们建立的axios实例模块，ES6 export用法
export default axios;
