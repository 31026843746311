import request from "@/plugins/axiosInstance";
import qs from "qs";
export function getOrderInfo(params) {
  return request({
    url: "/order/orderInfo",
    method: "post",
    data: params,
  });
}
export function submit(params) {
  return request({
    url: "/admin/process/submit",
    method: "post",
    data: qs.stringify(params),
  });
}
export function getShopNameList(query) {
  return request({
    url: "/order/get_shop_name_list",
    method: "get",
    params: query,
  });
}
export function getQuestionConfig(query) {
  return request({
    url: "/order/get_question_config",
    method: "get",
    params: query,
  });
}
export function submitSecond(params) {
  return request({
    url: "/admin/process/submit_second",
    method: "post",
    data: qs.stringify(params),
  });
}

// 延保
export function applyExtendedWarranty(params) {
  return request({
    url: "/afterSales/applyExtendedWarranty",
    method: "post",
    data: params,
  });
}

export function uploadCertificate(params) {
  return request({
    url: "/afterSales/extendedWarrantyUploadCertificate",
    method: "post",
    data: params,
  });
}

// 售后
export function applyAfterSales(params) {
  return request({
    url: "/afterSales/applyAfterSales",
    method: "post",
    data: params,
  });
}
export function myAfterSalesInfos(params) {
  return request({
    url: "/afterSales/myAfterSalesInfos",
    method: "post",
    data: params,
  });
}
export function afterSalesUploadCertificate(params) {
  return request({
    url: "/afterSales/afterSalesUploadCertificate",
    method: "post",
    data: params,
  });
}
// 售后、延保详情
export function afterSalesDetailInfo(params) {
  return request({
    url: "/afterSales/afterSalesDetailInfo",
    method: "post",
    data: qs.stringify(params),
  });
}
// 售后服务评价
export function addOrderAfterSalesComment(params) {
  console.log(params, "params");
  return request({
    url: "/comment/orderaftersalesrecordcustomercomment/addOrderAfterSalesComment",
    method: "post",
    data: params,
  });
}
// 我的已完成未评论售后列表
export function myAfterSalesInfosNoComment() {
  return request({
    url: "/afterSales/myAfterSalesInfosNoComment",
    method: "get",
  });
}
// 退货 填写信息
export function uploadReturnInformation(params) {
  return request({
    url: "/afterSales/uploadReturnInformation",
    method: "post",
    data: params,
  });
}

// 查询物流信息
export function getOrderLogisticsTrack(query) {
  return request({
    url: "/afterSales/getOrderLogisticsTrack",
    method: "get",
    params: query,
  });
}

// 获取问题类型列表
export function faqTypeList() {
  return request({
    url: "/faq/getFaqTypeList",
    method: "get",
  });
}

// 根据问题类型获取问题列表
export function getFaqAnswersListByFaqType(query) {
  return request({
    url: "/faq/getFaqAnswersListByFaqType",
    method: "get",
    params: query,
  });
}
