<template>
  <el-dialog
    v-model="dialogVisible"
    width="30%"
    top="5vh"
    :before-close="handleClose"
    custom-class="address-dialog"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <!-- <h4 :id="titleId" :class="titleClass">This is a custom header!</h4>
        <el-button type="danger" @click="close">
          <el-icon class="el-icon--left"><CircleCloseFilled /></el-icon>
          Close
        </el-button> -->
        {{
          state.type == "add" ? $t("theme.addAddress") : $t("theme.editAddress")
        }}
        <el-icon><Close @click="close" /></el-icon>
      </div>
    </template>
    <el-form
      :model="addressFrom"
      ref="addressFromRef"
      :rules="rules"
      class="form edit-address"
    >
      <el-form-item class="new-form-item" label="" prop="nickNake">
        <div class="label">{{ $t("theme.fullname") }}*</div>
        <el-input
          v-model="addressFrom.nickNake"
          :placeholder="
            $t('form.rulesEnterTips', { params: $t('theme.fullname') })
          "
          maxlength="30"
          @input="handleInput($event, 'nickNake')"
        />
      </el-form-item>
      <el-form-item class="new-form-item" prop="phone">
        <div class="label">{{ $t("theme.phone") }}*</div>
        <el-input
          v-model="addressFrom.phone"
          :placeholder="
            $t('form.rulesEnterTips', { params: $t('theme.phone') })
          "
          maxlength="20"
          @input="handleInput($event, 'phone')"
        />
      </el-form-item>
      <el-form-item
        class="new-form-item"
        prop="receivingAddress"
        style="width: 100%"
      >
        <div class="label">{{ $t("form.address") }}1*</div>
        <el-input
          v-model="addressFrom.receivingAddress"
          @input="handleInput($event, 'receivingAddress')"
          :placeholder="
            $t('form.rulesEnterTips', { params: $t('theme.receivingAddress') })
          "
        />
      </el-form-item>
      <el-form-item
        class="new-form-item"
        prop="receivingAddress1"
        style="width: 100%"
      >
        <div class="label">{{ $t("form.address") }}2</div>
        <el-input
          v-model="addressFrom.receivingAddress1"
          @input="handleInput($event, 'receivingAddress1')"
          :placeholder="
            $t('form.rulesEnterTips', {
              params: $t('theme.receivingAddress'),
            }) + 1
          "
        />
      </el-form-item>
      <el-form-item class="new-form-item" prop="zipCode">
        <div class="label">{{ $t("theme.zipcode") }}*</div>
        <el-input
          v-model.trim="addressFrom.zipCode"
          :placeholder="
            $t('form.rulesEnterTips', { params: $t('theme.zipcode') })
          "
          @blur="blurPostCode"
          @input="handleInput($event, 'zipCode')"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item class="new-form-item" prop="country">
        <div class="label">{{ $t("theme.country") }}*</div>
        <el-select
          v-model="addressFrom.country"
          filterable
          :placeholder="
            $t('form.rulesEnterTips', { params: $t('theme.country') })
          "
          @change="selectCountry"
        >
          <el-option
            v-for="item in countryList"
            :key="item.Iso2"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="new-form-item" prop="state">
        <div class="label">{{ $t("theme.state") }}*</div>
        <el-select
          v-model="addressFrom.state"
          filterable
          :placeholder="
            $t('form.rulesEnterTips', { params: $t('theme.state') })
          "
          @change="selectState"
        >
          <el-option
            v-for="item in statesList"
            :key="item.state_code"
            :label="item.name"
            :value="item.state_code"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="new-form-item" prop="city">
        <div class="label">{{ $t("theme.city") }}</div>
        <el-select
          v-model="addressFrom.city"
          filterable
          :placeholder="$t('form.rulesEnterTips', { params: $t('theme.city') })"
        >
          <el-option
            v-for="item in cityList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="addressFrom.defaultFlag"
          :label="$t('theme.setAddress')"
          :true-label="1"
          :false-label="0"
          size="large"
        />
      </el-form-item>
    </el-form>
    <div class="btn-group">
      <div class="default-btn" @click="handleAdd">
        {{ $t("theme.save") }}
      </div>
      <div class="default-btn cancel-btn" @click="handleClose">
        {{ $t("theme.cancel") }}
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, defineEmits, toRefs } from "vue";
import {
  getCountries,
  getStates,
  getCities,
  searchCodes,
} from "@/api/logistics";
import { createAddress, login, updateAddress } from "@/api/LoginRegister";
import _, { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const $emit = defineEmits(["submit"]);

let dialogVisible = ref(false);
let state = reactive({
  type: "add",
});
let addressFrom = ref({
  city: "",
  country: "",
  defaultFlag: 0,
  nickNake: "",
  phone: "",
  receivingAddress: "",
  receivingAddress1: "",
  state: "",
  zipCode: "",
});
const rules = reactive({
  nickNake: [
    {
      required: true,
      message: "",
      trigger: "blur",
    },
  ],
  phone: {
    required: true,
    message: "",
    trigger: "blur",
  },
  receivingAddress: {
    required: true,
    message: "",
    trigger: "blur",
  },
  zipCode: {
    required: true,
    message: "",
    trigger: "blur",
  },
  country: {
    required: true,
    message: "",
    trigger: "change",
  },
  state: {
    required: true,
    message: "",
    trigger: "change",
  },
});
let addressFromRef = ref(null);
const init = (type, data) => {
  state.type = type;
  if (type == "add") {
    addressFrom.value = {};
    addressFrom.value.defaultFlag = 0;
    // console.log(addressFrom.value, "addressFrom.value");
  } else {
    addressFrom.value = cloneDeep(data);
    // addressFrom.value
    let { receivingAddress } = data;
    let receivingAddressArr = receivingAddress.split("|");
    addressFrom.value.receivingAddress = receivingAddressArr[0];
    addressFrom.value.receivingAddress1 =
      receivingAddressArr.length > 1 ? receivingAddress.split("|")[1] : "";
  }
  if (!countryList.value.length) {
    getCountriesList();
  }
  dialogVisible.value = true;
};
const handleInput = (e, field) => {
  addressFrom.value[field] = e.replace(/,|，/g, " ");
};
// 邮编
const blurPostCode = () => {
  if (addressFrom.value.country) {
    codesToLocation(addressFrom.value.country);
  }
};
// 获取国家信息
let countryList = ref([]);
const getCountriesList = () => {
  getCountries().then((res) => {
    if (!res.data.length) return;
    const USAObj = res.data.find((item) => item.Iso3 == "USA");
    let USAIndex = res.data.findIndex((item) => item.Iso3 == "USA");
    res.data.splice(USAIndex, 1);
    res.data.unshift(USAObj);
    countryList.value = res.data;
    if (state.type == "edit") {
      addressFrom.value.country = res.data.find((item) => {
        return item.Iso2 == addressFrom.value.country;
      }).name;
      getStatesList(addressFrom.value.country);
    }
  });
};
// 选择国家
const selectCountry = (e) => {
  console.log(e, "选择国家");
  if (e) {
    addressFrom.value.state = "";
    addressFrom.value.city = "";
    getStatesList(e);
  }
};
const codesToLocation = (e) => {
  let { zipCode } = addressFrom.value;
  if (!zipCode) return;
  let countryObj = countryList.value.find((item) => {
    return item.name == e;
  });
  searchCodes({
    codes: zipCode,
    country: countryObj.Iso2,
  }).then((res) => {
    let { results } = res;
    if (results[zipCode]) {
      getCitiesList(results[zipCode][0].state_code);
      addressFrom.value.state = results[zipCode][0].state_code;
      addressFrom.value.city = results[zipCode][0].city;
    }
  });
};
// 获取省份/州
let statesList = ref([]);
const getStatesList = (country) => {
  getStates({ country }).then((res) => {
    statesList.value = res.data.states;
    codesToLocation(country);
  });
};
// 选择省/洲
const selectState = (e) => {
  if (e) {
    getCitiesList(e);
  }
};
let cityList = ref([]);
const getCitiesList = (state) => {
  let statesObj = statesList.value.find((item) => {
    return item.name == state || item.state_code == state;
  });
  getCities({ country: addressFrom.value.country, state: statesObj.name }).then(
    (res) => {
      cityList.value = res.data;
    }
  );
};
const handleClose = () => {
  dialogVisible.value = false;
  if (state.type == "add") {
    addressFromRef.value.resetFields();
  }
};
const handleAdd = () => {
  let fun = state.type == "add" ? createAddress : updateAddress;
  addressFromRef.value.validate((valid) => {
    if (valid) {
      console.log(countryList.value);
      let countryObj = countryList.value.find((item) => {
        return (
          item.name == addressFrom.value.country ||
          item.Iso2 == addressFrom.value.country
        );
      });
      if (addressFrom.value.receivingAddress1) {
        addressFrom.value.receivingAddress +=
          "|" + addressFrom.value.receivingAddress1;
      }
      fun({ ...addressFrom.value, country: countryObj.Iso2 }).then((res) => {
        if (res.code == 0) {
          addressFromRef.value.resetFields();
          dialogVisible.value = false;
          $emit("submit");
        }
      });
    }
  });
};
defineExpose({ init });
</script>
<style scoped lang="less">
.my-header {
  padding: 20px;
  background: var(--color-primary);
  color: #fff;
}
.form {
  max-height: 60vh;
  overflow-y: auto;
}
.new-form-item {
  position: relative;
  min-height: 5.2rem;
  border: 0.0556rem solid #d9d9d9;
  border-radius: 0.5556rem;
  margin-top: 1.5rem;
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
  &.is-error {
    border: 3px solid #f56c6c;
  }
  ::v-deep .el-form-item__content {
    flex-direction: column;
    padding-top: 1.2222rem;
    .el-input,
    .el-textarea {
      margin-top: 0.6667rem;
    }
    .el-select {
      width: 100%;
      height: 100%;
      border: none;
      .el-select__caret.el-icon {
        font-size: 1.5rem;
      }
    }

    .is-focus {
      box-shadow: none !important;
      .el-input__wrapper {
        box-shadow: none !important;
      }
    }
    .el-input__wrapper,
    .el-textarea__inner {
      padding: 0.0556rem 1.6667rem;
      box-shadow: none !important;
      .el-input__inner {
        font-size: 1.3333rem;
        height: 3rem;
        line-height: 3rem;
        color: #000;
      }
    }
    .el-textarea__inner {
      resize: none;
      font-size: 1.3333rem;
      height: 3rem;
      line-height: 3rem;
      color: #000;
    }
    .el-form-item__error {
      left: 1.6667rem;
    }
  }

  .label {
    position: absolute;
    z-index: 9;
    top: 4px;
    left: 1.1rem;
    padding: 0 0.5556rem;
    background-color: #fff;
    color: #888888;
    font-size: 1.1111rem;
  }
  .unit {
    position: absolute;
    right: 8px;
    bottom: 5px;
    color: #888888;
    font-size: 1rem;
  }
  .wrapper {
    width: 95%;
    padding-left: 1.6667rem;
    margin-top: 8px;
    font-size: 1.3333rem;
    height: 3.2222rem;
    line-height: 3.2222rem;
    color: #000;
  }
}
.btn-group {
  width: 100%;
  display: flex;
  justify-content: start;
  margin: 10px;
  .btn {
    cursor: pointer;
    color: #fff;
    height: 3rem;
    line-height: 3rem;
    width: 49%;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
  }
  .cancel-btn {
    background-color: #f3f3f3;
    color: var(--color-primary);
  }
  .submit-btn {
    background-color: var(--color-primary);
    margin-right: 15px;
  }
}

::v-deep .el-checkbox {
  .el-checkbox__input {
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      &::after {
        border-width: 3px;
        left: 5px;
        top: 3px;
      }
    }
  }
  .is-checked {
    .el-checkbox__inner {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
  .el-checkbox__label {
    font-size: 16px;
  }
  .is-checked + .el-checkbox__label {
    color: #333;
  }
}
@media screen and (max-width: 518px) {
  .btn-group {
    .btn {
      padding: 15px;
      width: 50%;
    }
  }
}
</style>
