import { createRouter, createWebHashHistory } from "vue-router";
import i18n from "@/lang";
const { t } = i18n.global;
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "Guide",
      component: () => import("@/views/Process/guide.vue"),
      meta: {
        name: t("router.home"),
      },
    },
    {
      path: "/search",
      name: "Search",
      component: () => import("@/views/Process/search.vue"),
      meta: { name: t("router.search") },
    },
    {
      path: "/findOrder",
      name: "findeOrder",
      component: () => import("@/views/Process/components/findeOrder.vue"),
      meta: { name: t("router.findeOrder") },
    },
    {
      path: "/findScreenshot",
      name: "findeScreenshot",
      component: () => import("@/views/Process/components/findScreenshot.vue"),
      meta: { name: t("router.findeScreenshot") },
    },
    {
      path: "/payments",
      name: "Payments",
      component: () => import("@/views/Product/payments.vue"),
    },
    {
      path: "/warrantyInfo",
      name: "WarrantyInfo",
      component: () => import("@/views/Process/warrantyInfo.vue"),
      meta: { name: t("theme.extendedWarranty") },
    },
    {
      path: "/afterSalesInfo",
      name: "AfterSalesInfo",
      component: () => import("@/views/Process/afterSalesInfo.vue"),
      meta: { name: t("router.purchaseInformation") },
    },
    {
      path: "/success",
      name: t("theme.success"),
      component: () => import("@/views/Process/success.vue"),
      meta: {},
    },
    {
      path: "/orderList",
      name: t("router.orderList"),
      component: () => import("@/views/Product/orderList.vue"),
      meta: { name: t("router.orderInformation") },
    },
    {
      path: "/recordPage",
      name: "RecordPage",
      component: () => import("@/views/Product/recordPage.vue"),
      meta: { name: t("router.supportHistory") },
      // children: [
      //   {
      //     path: "details",
      //     name: "RecordDetail",
      //     component: () => import("@/views/Product/recordDetail.vue"),
      //     meta: { name: t("router.orderDetails") },
      //   },
      // ],
    },
    {
      path: "/recordDetail",
      name: "RecordDetail",
      component: () => import("@/views/Product/recordDetail.vue"),
      meta: { name: t("router.orderDetails") },
    },
    {
      path: "/recordList",
      name: "RecordList",
      component: () => import("@/views/Product/recordList.vue"),
    },
    // {
    //   path: "/evaluationList",
    //   name: "EvaluationList",
    //   component: () => import("@/views/Process/evaluationDialog/list.vue"),
    //   meta: { name: "Review History" },
    // },

    {
      path: "/evaluationDetail",
      name: "EvaluationDetail",
      component: () => import("@/views/Process/evaluationDialog/detail.vue"),
      meta: { name: t("router.evaluationRecordDetails") },
    },
    {
      path: "/logistics",
      name: "Logistics",
      component: () => import("@/views/Product/logistics.vue"),
      meta: { name: t("router.shippingInformation") },
    },
    {
      path: "/viewFaceSheet",
      name: "ViewFaceSheet",
      component: () => import("@/views/Product/viewFaceSheet.vue"),
      meta: { name: t("router.shippingLabel") },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/LoginRegister/login.vue"),
    },
    {
      path: "/reset",
      name: "Reset",
      component: () => import("@/views/LoginRegister/reset.vue"),
    },
    {
      path: "/editUser",
      name: "EditUser",
      component: () => import("@/views/LoginRegister/editUser.vue"),
      meta: { name: t("router.editProfile") },
    },
    {
      path: "/address",
      name: "addressManagement",
      component: () => import("@/views/LoginRegister/address.vue"),
      meta: { name: t("router.addressManagement") },
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/LoginRegister/register.vue"),
    },
    {
      path: "/verification",
      name: "Verification",
      component: () => import("@/views/LoginRegister/verification.vue"),
      meta: { name: t("router.mailboxVerification") },
    },
    {
      path: "/forgetpwd",
      name: "Forgetpwd",
      component: () => import("@/views/LoginRegister/forgetpwd.vue"),
      meta: { name: t("router.resetPassword") },
    },
  ],
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (
    !token &&
    [
      "RecordPage",
      "RecordDetail",
      "Logistics",
      "ViewFaceSheet",
      "EditUser",
      "EvaluationList",
      "EvaluationDetail",
      "Success",
    ].includes(to.name)
  ) {
    next("/");
  } else {
    if (to.path) {
      window._hmt.push(["_trackPageview", "/#" + to.name]);
    }
    next();
  }
});
export default router;
