import request from "@/plugins/axiosInstance";
import qs from "qs";

export function fileUpload(params) {
  return request({
    url: "/fileOperator/fileUpload",
    method: "post",
    data: params,
  });
}
export function getDicData(params) {
  return request({
    url: "/sysDictData/getDictDataByType",
    method: "post",
    data: qs.stringify(params),
  });
}
export function getListByConfIdAndLanguage(params) {
  return request({
    url: "/languageCountry/getListByConfIdAndLanguage",
    method: "post",
    data: qs.stringify(params),
  });
}
// 获取UI配置信息
export function getClientStyleConfiguration() {
  return request({
    url: "/sysDictData/getClientStyleConfiguration",
    method: "post",
  });
}
