<template>
  <!--'comfier', 'snailax' 才有app  -->
  <div
    class="install"
    v-if="['comfier', 'snailax'].includes($theme.toLocaleLowerCase())"
  >
    <div class="main-container">
      <el-row :gutter="20" justify="space-between" align="middle">
        <el-col :xs="6" :sm="8" :md="4" class="left">
          <img :src="$theme == 'Comfier' ? cf_iphone_iocn : sl_iphone_iocn"
        /></el-col>
        <el-col :xs="18" :sm="16" :md="14">
          <p class="title text-gray" style="font-size: 22px">
            {{ $theme }} APP
          </p>
          <p class="text-gray">
            {{ $t("theme.APPDesc") }}
            {{ $theme.toLocaleLowerCase() }}.
          </p>
        </el-col>
        <el-col :sm="24" :md="6" class="install-btn">
          <a :href="androidUrl" target="__blank">
            <img src="@/assets/imgs/google-play.png"
          /></a>
          <a :href="iosDownUrl" target="__blank">
            <img src="@/assets/imgs/app-store.png" /></a
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, ref } from "vue";
const cf_iphone_iocn = require("@/assets/imgs/iphone-icon.png");
const sl_iphone_iocn = require("@/assets/imgs/iphone-icon-sl.png");
const { appContext } = getCurrentInstance();
// 从应用程序上获取$theme
const theme = appContext.config.globalProperties.$theme;
const androidUrl = ref(
  theme == "Comfier"
    ? "https://play.google.com/store/apps/details?id=com.ruikang.comfier"
    : "https://play.google.com/store/apps/details?id=com.ruikang.snailax"
);
const iosDownUrl = ref(
  theme == "Comfier"
    ? "https://apps.apple.com/cn/app/comfier/id1602455699"
    : "https://apps.apple.com/cn/app/snailax/id1607225620"
);
</script>
<style scoped lang="less">
.install {
  background-color: #fff;

  .main-container {
    .left {
      width: 14.8889rem;
      height: 11.5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .install-btn {
      text-align: end;
      img {
        width: 8rem;
        height: 2.7rem;
        margin-left: 15px;
      }
    }
  }
}
</style>
