// 德语
export default {
  // 文字中有 | 不要删掉！！
  // {params}平台 将{params}放在对应位置
  login: {
    login: "Anmeldung",
    userName: "Benutzername",
    password: "Passwort",
    email: "E-Mail",
    realEmail: "Echte E-Mail",
    forgotPassword: "Passwort vergessen",
    verificationCode: "Bestätigungscode",
    resend: "Erneut senden",
    send: "Senden",
    sendCode: "Code senden",
    rememberMe: "Erinnere dich an mich",
    signIn: "Melden Sie sich mit Ihrer E-Mail an, um fortzufahren",
    signInDesc:
      "Wenn Sie noch kein {params} Konto haben,| wird automatisch eines für Sie erstellt",
    warrantyTips:
      "Nur noch einen Schritt vom Erfolg der erweiterten Garantie entfernt!",
    warrantyDescTips: `Melden Sie sich jetzt an und aktivieren Sie
          Ihr Konto, um einfachen Zugriff auf Garantie-, Rückgabe- und
          Umtauschprotokolle zu erhalten. Außerdem haben Sie die Chance, 
          Geschenkkarten zu gewinnen!`,
    notReceivedCode: `Haben Sie den Bestätigungscode nicht erhalten? Bitte bestätigen Sie,
          ob die eingegebene E-Mail-Adresse gültig und korrekt ist. Wenn nicht,| klicken Sie hier, um sie zu ändern.`,
    emailTips: `Wenn Sie den Bestätigungscode mehrmals nicht erhalten haben,
          überprüfen Sie bitte, ob die E-Mail-Adresse korrekt eingegeben wurde oder
          ändern Sie die E-Mail-Adresse.`,
    passwordValidate: "Ihr Passwort muss mindestens 6 Zeichen lang sein",
    emailFormat: "Bitte geben Sie das korrekte E-Mail-Format ein",
    vaildFormat: "Bitte geben Sie das richtige {params}-Format ein",
    createYour: "Erstelle dein",
    changeNewPassword: "Neues Passwort ändern",
    changeNewPasswordDesc: `Ihr Passwort muss mindestens 8 Zeichen lang sein, <br />
          Zahlen, Groß- und Kleinbuchstaben enthalten`,
  },
  forgetpwd: {
    title: "Haben Sie Probleme beim Anmelden?",
    titleDesc: "Bitte geben Sie Ihre E-Mail-Adresse ein, um zu beginnen.",
    verificationCode: "Geben Sie die Zeichen im Bild ein",
    sendSuccessTips:
      "Die E-Mail zum Zurücksetzen Ihres Passworts wurde in Ihren Posteingang gesendet. Bitte überprüfen Sie Ihre E-Mails.",
  },
  recordPage: {
    supportHistoryDesc:
      "Überprüfen Sie den Status Ihrer vorhandenen oder früheren Support-Anfragen",
    timespan: "Zeitspanne",
    status: "Status",
    expectedTips1: "Voraussichtlich innerhalb von 3 Arbeitstagen abgeschlossen",
    expectedTips2:
      "Es wird erwartet, dass es innerhalb von 10 Arbeitstagen geliefert wird",
    expectedTips3: "Voraussichtlich innerhalb von 10 Arbeitstagen erhalten",
    review: "Bewerten und Preise gewinnen!",
    additionalRating: "Zusätzliche Bewertung",
    product: "Produkt",
    sku: "Sku",
    type: "Art",
    created: "Erstellt",
    initiated: "eingeleitet",
    update: "Aktualisieren",
    action: "Aktion",
  },
  recordDetail: {
    warrantyStatus: "Garantie-Status",
    headerTips1: "Ihr Antrag wird bearbeitet",
    headerTips2: "Ihr Rückgabeantrag wurde genehmigt",
    headerTips3: "Sie haben die Zahlung erfolgreich abgeschlossen",
    headerTips4: "Label herunterladen und Produkt zurücksenden",
    headerTips5: "Ihr Rücksendung wurde erhalten",
    headerTips6: "Ihr neues Produkt wurde versendet",
    headerTips7: "Ihre erweiterte Garantie wurde abgeschlossen",
    headerTips8: "Ihr Kundendienst wurde abgeschlossen",
    headerTips9: "Ihre Zahlung wurde noch nicht abgeschlossen.",
    underReviewDesc: `Wir haben Ihre Anfrage erhalten! Wir werden sie zeitnah bearbeiten und Sie innerhalb der nächsten 3 
    Werktage per E-Mail auf dem Laufenden halten. Wenn Sie Fragen haben, zögern Sie bitte nicht, uns zu kontaktieren.`,
    returnsInProgressDesc: `Ihr Rückgabeantrag wurde genehmigt! Wir senden Ihnen innerhalb von 3 Werktagen ein Rücksendeetikett 
    über das System zu. Bitte laden Sie es rechtzeitig herunter und senden Sie das Produkt zurück.`,
    notSended: "Nicht gesendet?",
    haveSended: "Gesendet?",
    haveSendedDesc: `Ihr Ersatz wird bearbeitet, sobald wir Ihre Rücksendung erhalten haben. Bitte haben Sie Geduld und zögern Sie nicht, uns bei Fragen oder Bedenken zu kontaktieren. Wir sind hier, um zu helfen! Sie können auf die Schaltfläche unten klicken, um die detaillierten Versandinformationen zu verfolgen.`,
    resendDesc:
      "Ihr neues Produkt wird bald versendet. Bitte halten Sie die Versandstatus-Updates im Auge.",
    awaitingPaymentDesc: `Ihre Zahlung steht noch aus. Bitte schließen Sie diese innerhalb von 15 Minuten ab, sonst wird die Erstellung dieses Etiketts automatisch storniert.`,
    trackShippingStatus: "Versandstatus verfolgen",
    proceedToPayment: "Zur Zahlung gehen",
    successPay: "Sie haben die Zahlung erfolgreich abgeschlossen.",
    returnsInProgressDesc1:
      "Bitte verpacken Sie Ihren Artikel wieder in seiner Originalverpackung (falls zutreffend). Klicken Sie einfach auf die Schaltfläche unten, um das Versandetikett herunterzuladen und auszudrucken. Bringen Sie es danach einfach an Ihrem Paket an und geben Sie es an einer UPS-Station ab. ",
    returnsInProgressDesc2:
      "Ihr Ersatz wird bearbeitet, sobald wir Ihre Rücksendung erhalten haben. Bitte haben Sie Geduld und zögern Sie nicht, uns bei Fragen oder Anliegen zu kontaktieren. Wir sind hier, um zu helfen! Sie können auf die Schaltfläche unten klicken, um die detaillierten Versandinformationen zu verfolgen.",
    exchangeInProgressDesc:
      "Ihr Ersatz wird verarbeitet, sobald wir Ihre Rücksendung erhalten haben. Bitte haben Sie Geduld und zögern Sie nicht, uns bei Fragen oder Bedenken zu kontaktieren. Wir sind hier, um zu helfen!",
    exchangeInProgressDesc1:
      "Ihre Rücksendung wird im Rücksendezentrum bearbeitet. Ihr neuer Artikel wurde für Sie versendet, mit der Sendungsnummer |, Bei Fragen zögern Sie bitte nicht, uns zu kontaktieren.",
    replacementInProgressDesc:
      "Ihr neuer Artikel wurde für Sie versendet, mit der Sendungsnummer |, Bei Fragen zögern Sie bitte nicht, uns zu kontaktieren.",
    refundsInProgressDesc:
      "Ihre Rücksendung wird im Rücksendezentrum bearbeitet. Ihre Rückerstattung wird innerhalb von 3 Werktagen auf Ihr ursprüngliches Konto zurücküberwiesen. Bitte halten Sie Ausschau danach.",
    ReturnOrRefundStatus: "Status der Rücksendung oder Rückerstattung",
    reviewDetails: "Überprüfungsdetails",
    review: "Überprüfung",
    request: "Anfrage",
    trackingNumber: "Sendungsverfolgungsnummer",
    requestSupportInformation: "Unterstützungsinformationen anfordern",
    shippingTips:
      "Ihre Supportanfrage wurde genehmigt. | Bitte vervollständigen Sie die Versandinformationen, um fortzufahren.",
    downloadLabeltIPS:
      "{params} hat ein Rücksendeetikett für Sie gesendet, | Bitte klicken Sie hier, um es herunterzuladen | , drucken Sie es aus und verpacken Sie Ihren Artikel zur Rücksendung.",
    ProcessingMethodForPlatformReview:
      "Bearbeitungsmethode für Plattformüberprüfung",
    reviewInstructions: "Überprüfungshinweise",
    refundGiftCardType: "Erstattung Gutscheinart",
    refundGiftCardID: "Erstattung Gutschein-ID",
    refundGiftCardAmount: "Erstattung Gutscheinbetrag",
    refundVoucherDesc:
      "Ihr Rückerstattungsantrag ({ params }) wurde bearbeitet und der Betrag wurde auf Ihr Zahlungskonto zurückgebucht. Bitte überprüfen Sie Ihr Konto, um den Erhalt zu bestätigen.",
    refundVoucher: "Erstattungsgutschein",
    refundGiftDesc:
      "Ihr Rückerstattungsantrag wird bearbeitet, und der Betrag ({amount}) wird Ihrem {type} (ID {id}) gutgeschrieben. Sie können den Rückerstattungsbeleg einsehen, indem Sie auf die Schaltfläche „Details anzeigen“ klicken.",
    refundAmount: "Rückerstattungsbetrag",
    giftCardType: "Gutscheinart",
    giftCardID: "Geschenkkarten-ID",
    amount: "Betrag",
    gifts: "Geschenke",
    reviewNotApproved: "Überprüfung nicht genehmigt",
    reviewContent: "Überprüfungsinhalt",
    rating: "Bewertung",
    photo: "Foto",
    ratingPhoto: "Bewertungsfoto",
    trackingID: "Tracking-ID",
    shippingCarrier: "Versandunternehmen",
    purchaseDate: "Kaufdatum",
    limitedDate: "Datum des Ablaufs der eingeschränkten Garantie",
    stepDetailTips1: `Ihre Reklamation wurde genehmigt. Bitte senden Sie das defekte Produkt auf eigene Kosten zurück. Verwenden Sie das Symbol "Schätzung und Erhalten eines Versandetiketts", um die geschätzten Versandkosten zu überprüfen. Wenn diese geeignet sind, können Sie unseren Versandservice dann erwerben. Sehen Sie sich bei Bedarf das Anleitungsvideo an.`,
    getLabelStepTitle: "Anleitung zum Zurücksenden Ihres Pakets: ",
    getLabelStep1:
      "1. Bezahlen Sie den Versandservice und erhalten Sie das Etikett.",
    getLabelStep2: "2. Verwenden Sie bei Möglichkeit die Originalverpackung.",
    getLabelStep3:
      "3. Drucken Sie das Etikett aus und befestigen Sie es am Paket.",
    getLabelStep4: "4. Geben Sie das Paket bei UPS ab.",
    estimationAndGetShippingLabel:
      "Schätzung und Erhalten eines Versandetiketts",
    searchOthers: "Andere suchen",
    returnTips: `Ihre Rücksendeanfrage wurde genehmigt! Verpacken Sie bitte Ihren Artikel wieder in der Originalverpackung (falls vorhanden). Klicken Sie einfach auf die Schaltfläche unten, um das Versandetikett herunterzuladen und drucken Sie es aus. Befestigen Sie es dann einfach an Ihrem Paket und geben Sie es an einer UPS-Stelle ab.`,
    downloadLabel: "Etikett herunterladen",
    reasonForReturn: "Grund für die Rücksendung",
    expired: "Abgelaufen",
    underWarranty: "Unter Garantie",
    qty: "Anzahl",
  },
  evaluation: {
    exclusiveOpportunity: "Exklusive Gelegenheit",
    descriptions: `Erhalten Sie die Chance, eines der unten aufgeführten Werbegeschenke oder <br/>einen $20-Gutschein zu erhalten <br/><b>Einfach mitmachen:</b><br/>
    Füllen Sie das Formular in 1-3 Minuten aus, siehe "Details anzeigen"`,
    winGiftCard: "Gewinnen Sie eine Geschenkkarte im Wert von $20-$50!",
    freeTips:
      " Absolut kostenlos - Keine versteckten Gebühren, keine Kreditkarte erforderlich!",
    feedbackTips:
      "Wir schätzen Ihr Feedback aufrichtig und würden uns freuen, wenn Sie sich ein paar Minuten Zeit nehmen könnten, um Ihre Einkaufserfahrung mit anderen Kunden zu teilen. Ihr Beitrag ist für uns unglaublich wertvoll!",
    steps: "3 einfache Schritte zum Gewinnen",
    steps1: "SCHRITT 1:",
    steps1Desc:
      "Machen Sie ein Foto von Ihrem neuen {params} Massager. Stellen Sie sicher, dass Sie einen großartigen Start hinlegen!",
    steps2: "SCHRITT 2:",
    steps2Desc:
      "Posten Sie es auf Facebook, Instagram, Twitter, YouTube, Tiktok mit dem Hashtag #{params}.",
    steps3: "SCHRITT 3:",
    steps3Desc:
      "Kopieren Sie die Post-URL und senden Sie sie im untenstehenden Formular ein.",
    storeGiftCard: "$30 {params} Store Geschenkkarte",
    pasteUrl: "Fügen Sie hier Ihre URL ein.",
    tips: "*Denken Sie daran, Ihren Beitrag auf Öffentlich zu setzen, damit wir ihn sehen können.",
    selectGiftTips: "Wählen Sie das Geschenk aus, das Sie möchten für",
    selectproduct: "Wählen Sie Ihr Produkt",
    selectproductDesc: "Wählen Sie die Produkte aus, die Sie teilen möchten.",
    footerTips:
      "werden weiterhin bestrebt sein, einen gesunden Lebensstil für unsere Kunden zu schaffen. Vielen Dank für die Anerkennung unserer Produkte.",
    fillInAddressTips:
      "Geben Sie Ihre Lieferadresse ein, damit Sie Ihr Geschenk so schnell wie möglich erhalten können.",
    rateTitle: "Sind Sie mit dem Produkt zufrieden?",
    submitSteps1: "Klicken Sie hier | um Ihre Einkaufserfahrung zu teilen.",
    submitSteps2:
      "Machen Sie einen Screenshot von dem Kommentar oder der Bewertung, die Sie hinterlassen haben, und speichern Sie ihn.",
    submitSteps3:
      "Gehen Sie zurück zu dieser Seite und | laden Sie den gerade gespeicherten Screenshot hoch.",
    uploadScreenshot: "Laden Sie Ihren Screenshot hoch",
    copyUrl:
      "Kopieren Sie die Beitrag-URL und geben Sie sie im untenstehenden Formular ein.",
    sharingSuccessTips: "Vielen Dank fürs Teilen",
    sharingSuccessDesc:
      "Wir danken Ihnen für Ihre großartige Unterstützung. Ihre Einsendung wurde erhalten und wird innerhalb von 3 Arbeitstagen überprüft. Die Prämien-ID für die Geschenkkarte wird Ihnen kurz nach Abschluss der Überprüfung per E-Mail zugesandt. Sie können auch die Überprüfungshistorie auf Aktualisierungen überprüfen.",
    sharingSuccessDesc1:
      "Wir entschuldigen uns für die negative Produkterfahrung, die Sie gemacht haben. Wir werden Verbesserungen vornehmen, um Ihnen ein besseres Erlebnis zu bieten.",
    afterSalesRateTitle: "Bitte bewerten Sie diesen Kundendienst",
    placeRate: "Bitte bewerten Sie.",
  },
  logistics: {
    info: "Logistikinformationen",
    orderNumber: "Logistik-Auftragsnummer",
    shippingInfo: "Vollständige Versandinformationen",
    orderTime: "Bestellzeit",
    company: "Logistikunternehmen",
    downloadFaceSheet: "Face Sheet herunterladen",
    shippingInfoDesc:
      "Befolgen Sie die folgenden Schritte, um die Versandinformationen zu vervollständigen",
    buyLable: "Kaufen Sie ein Etikett auf {params}",
    packagingWeight: "Verpackungsgewicht",
    completed: "Abgeschlossen",
    createTime: "Erstellungszeit",
    shippingAddress: "Versandadresse",
    shippingInformation: "Versandinformationen",
    retry: "Erneut versuchen",
    price: "Preis",
    weight: "Gewicht",
    length: "Länge",
    width: "Breite",
    height: "Höhe",
    lb: "lb",
    in: "in",
    tel: "Tel",
    finish: "Fertig",
    ups: "UPS",
    total: "Gesamt (mit Rabatt)",
    $: "$",
    postPaymentInstructions: "Nachzahlungsanweisungen",
    postPaymentInstructionsDesc:
      "Bitte kehren Sie nach erfolgreicher Zahlung auf diese Seite zurück, um das Versandetikett herunterzuladen.",
    paymentAlerts: `Bitte schließen Sie den Vorgang innerhalb von 15 Minuten ab, sonst wird die Erstellung dieses Etiketts automatisch storniert.`,
    payNow: "Jetzt bezahlen",
    publishedCharges: "Veröffentlichte Gebühren",
    payGetLabel: "Bezahlen und Etikett erhalten",
    telephoneNumber: "Telefonnummer",
    uploadLabel: "Laden Sie Ihr eigenes Etikett hoch",
    shipTo: "Sie müssen Ihren Artikel an folgende Adresse versenden",
    createOrderTips:
      "Bitte kehren Sie auf diese Seite zurück, um das Versandetikett nach erfolgreichem Abschluss Ihrer Zahlung herunterzuladen.",
    paymentTips:
      "Bitte schließen Sie die Zahlung auf der neu geöffneten Seite ab",
    paymentTipsDesc:
      "Bitte schließen Sie dieses Fenster nicht, bevor die Zahlung abgeschlossen ist. Nach Abschluss der Zahlung klicken Sie bitte je nach Situation auf die untenstehende Schaltfläche.",
    payTips:
      "Bitte beachten Sie, dass Rabattcodes oder Geschenkkarten für diese Bestellung nicht verwendet werden können. Andernfalls wird die Bestellung ungültig.",
    howGetShippingLabel: "Wie erhalten Sie Ihr Versandetikett?",
    priceTips:
      "*Dieser Preis ist eine Schätzung. Eine Bestellung sichert Ihnen einen ermäßigten Preis.",
    payGetLabel: "Bezahlen und Versandetikett erhalten",
  },
  findOrder: {
    title: "Wie finde ich Ihre Amazon-Bestellnummer?",
    step1: "Schritt 1: Öffnen Sie die Amazon-Startseite",
    step2: `Schritt 2: Klicken Sie auf "Rücksendungen & Bestellungen"`,
    step2Dec: `Sie sollten dies oben rechts finden, zwischen "Konto & Listen" und Ihrem Warenkorb.`,
    step3: `Schritt 3: Finden Sie Ihre Bestellung`,
    step3Dec: `Die Bestellnummer besteht in der Regel aus 17 Ziffern.`,
  },
  findScreenshot: {
    title: "WIE FINDE ICH IHREN BESTELLUNGS-SCREENSHOT?",
    step1:
      "1. Ihre Bestellungen (Wenn es sich um ältere Bestellungen handelt, verwenden Sie den Filter, um einen Zeitraum festzulegen.)",
    step2: '2. Klicken Sie auf "Bestelldetails anzeigen"',
    step3: '3. Klicken Sie auf "Rechnung anzeigen"',
    step4: "4. Rechnung",
  },
  faq: {
    title: "Häufig gestellte Fragen",
    faqDesc:
      "Bevor Sie Unterstützung anfordern, können Sie Ihr Problem schnell durch die FAQ überprüfen",
    faqTips:
      "Wenn Sie die FAQ durchgelesen haben, aber immer noch keine Lösung für Ihr Problem finden können, | Klicken Sie hier",
  },
  theme: {
    all: "Alle",
    addAddress: "Adresse hinzufügen",
    back: "Zurück",
    collapse: "Zusammenbruch",
    continue: "Fortsetzen",
    checking: "Überprüfung...",
    cancel: "Abbrechen",
    canceled: "Abgebrochen",
    delete: "Löschen",
    download: "Herunterladen",
    edit: "Bearbeiten",
    editAddress: "Adresse bearbeiten",
    receivingAddress: "Empfangsadresse",
    fullname: "Vollständiger Name",
    nickname: "Spitzname",
    phone: "Telefon",
    zipcode: "Postleitzahl",
    country: "Land/Region",
    state: "Bundesland",
    set: "Setzen",
    city: "Stadt",
    search: "Suche",
    more: "Mehr",
    next: "Nächster",
    success: "Erfolg",
    history: "Geschichte",
    save: "Speichern",
    ok: "OK",
    others: "Andere",
    logout: "Ausloggen",
    login: "Einloggen",
    notification: "Benachrichtigung",
    firstName: "Vorname",
    lastName: "Nachname",
    changePwd: "Ändere dein Passwort",
    currentPwd: "Aktuelles Passwort",
    newPwd: "Neues Passwort",
    confirmNewPwd: "Neues Passwort bestätigen",
    lastUpdated: "Zuletzt aktualisiert",
    logoutTips: "Bitte bestätigen Sie, ob Sie sich abmelden möchten?", //-----
    viewDetails: "Details anzeigen",
    submit: "Einreichen",
    systemName: "Online-Servicecenter",
    setAddress: "Als Standardadresse festlegen",
    systemDesc:
      "Erstellen und Verwalten Sie Ihre Support- und Garantieanfragen",
    requestSupport: "Support anfordern",
    requestSupportDesc:
      "Lassen Sie uns bei Ihren Erstattungs-, Rückgabe- und Ersatzproblemen helfen",
    extendedWarranty: "Erweiterte Garantie",
    refundsAndReturns: "Rückerstattungen & Rücksendungen",
    submitApplication: "Anwendung einreichen",
    reviewPending: "Überprüfung ausstehend",
    finished: "Beendet",
    returnPending: "Rücksendung ausstehend",
    returnInProgress: "Rücksendung in Bearbeitung",
    refundInProgress: "Rückerstattung in Bearbeitung",
    reshipPending: "Erneute Versendung ausstehend",
    reshipInProgress: "Erneute Versendung in Bearbeitung",
    replacePending: "Austausch ausstehend",
    replaceInProgress: "Austausch in Bearbeitung",
    replacementInProgress: "Ersatz in Bearbeitung",
    close: "Schließen",
    toShip: "Zum Versenden",
    inTransit: "In Transit",
    reviewRejected: "Überprüfung abgelehnt",
    amazonGifts: "Amazon-Geschenke",
    shopifyGifts: "Shopify-Geschenke",
    walmartGifts: "Walmart-Geschenke",
    lazadaGifts: "Lazada-Geschenke",
    shopeeGifts: "Shopee-Geschenke",
    last30Days: "Letzte 30 Tage",
    last90Days: "Letzte 90 Tage",
    lastYear: "Letztes Jahr",
    thisYear: "Dieses Jahr",
    whatsAppContact: "WhatsApp-Kontakt",
    forFill: "Zum Ausfüllen",
    goTodownload: "Zum Download gehen", //-----
    successfully: "Erfolgreich!",
    confirmResetPwd: "Bitte bestätigen Sie das Passwort",
    shareExperience: "Teilen Sie Ihre Erfahrung",
    uploadScreenshot: "Bitte laden Sie Ihren Screenshot hoch",
    userInfoChangeSuccess: "Benutzerinformationen erfolgreich geändert",
    connectionErr:
      "Überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut",
    mustBeJPGFormat: "Avatar-Bild muss im JPG-Format sein!",
    inconsistentPasswords:
      "Die zweimal eingegebenen Passwörter stimmen nicht überein",
    successfulRegistration:
      "Die Registrierung war erfolgreich. Der Aktivierungscode wurde an die Mailbox gesendet",
    ownShippingCostsTips:
      "Vielen Dank, dass Sie uns darauf aufmerksam gemacht haben. Gemäß unserer Garantierichtlinie senden Sie bitte den defekten Artikel auf eigene Kosten an unser Lager zurück. Sobald wir ihn erhalten haben, senden wir Ihnen umgehend einen Ersatz oder erstatten Ihnen den Kaufpreis, wie gewünscht. Zur Unterstützung Ihrer Rücksendung erstellen wir ein Rücksendeetikett und holen ein Preisangebot ein. Sie können auch jeden bevorzugten Spediteur verwenden, aber bitte geben Sie die Sendungsverfolgungsnummer für die Nachverfolgung an. Vielen Dank für Ihre Mitarbeit.",
    shippingCostsTips:
      "Vielen Dank, dass Sie uns über das Problem informiert haben. Wir haben ein vorbezahltes Versandetikett für Ihre Bequemlichkeit erstellt. Bitte senden Sie das defekte Gerät zurück, und wir senden Ihnen umgehend einen Ersatz oder erstatten Ihnen den Kaufpreis, wie gewünscht. Vielen Dank!",
    feedbackTips:
      "Haben Sie ein paar Minuten Zeit, um uns Feedback zu geben? Wir suchen nach Vorschlägen, um unsere Dienstleistungen noch besser zu machen. Wir schätzen Ihre Zeit!",
    extendedWarrantyDesc:
      "Überprüfen Sie Ihren Versicherungsschutz und die erweiterte Garantie",
    supportHistory: "Supportverlauf",
    supportHistoryDesc:
      "Melden Sie sich an, um den Status vorhandener oder früherer Anfragen anzuzeigen",
    APPDesc:
      "Finden und vergleichen Sie Produkte, erhalten Sie Unterstützung und verbinden Sie sich mit",
    needMoreHelp: "Brauchen Sie mehr Hilfe?",
    contactSupport: "Support kontaktieren",
    whereToPurchase: "Wo kaufen",
    systemAutoCorrected: "System automatisch korrigiert:",
    purchasePlatform: "Kaufplattform",
    orderNumberFormat: "Bestellnummer-Format:",
    copyright: "Urheberrecht © { params } Corporation V2.3.0", //-----
    addressManagementDesc:
      "Bearbeiten, hinzufügen oder löschen Sie Ihre Lieferadresse.",
    personalInformation: "Persönliche Informationen",
    personalInformationDesc:
      "Verwalten Sie Ihre persönlichen Informationen, einschließlich Passwort und E-Mail-Adressen, unter denen Sie kontaktiert werden können.",
    quickOptionsTips:
      "Sie können eine vorhandene Bestellung auswählen, um Unterstützung zu erhalten.",
    importantNotice: "WICHTIGE HINWEIS",
    orderProblemsTips1: "Die Garantiezeit ist abgelaufen.",
    orderProblemsTips2:
      "Entschuldigung, dies ist eine Teilebestellung und kann die Garantie oder den Kundendienst nicht verlängern.",
    orderProblemsTips3:
      "Entschuldigung, das gebrauchte Produkt kann keinen Support bieten und kann nicht für die Garantieverlängerung berücksichtigt werden.",
    uploadSuccessful: "Upload erfolgreich",
    submitSuccessfully: "Erfolgreich eingereicht",
    submitSuccessfullyDesc:
      "Wir werden Ihre Bestellung innerhalb von 3 Werktagen überprüfen. Sie können auch den Fortschritt über die Support-Historie überprüfen.",
    findWalmartOrder: "Wie finde ich die Walmart-Bestellnummer?",
    findWalmartOrder1: "1. Melden Sie sich bei Ihrem Walmart-Konto an.",
    findWalmartOrder2: "2. Wählen Sie 'Kaufhistorie'.",
    findWalmartOrder3:
      "3. Scrollen Sie zur gewünschten Bestellung und finden Sie die Bestellnummer.",
    findShopifyOrder: `Sie erhalten bei jedem Kauf auf www.{params}.com eine Bestellnummer per E-Mail.
     Bitte überprüfen Sie Ihr E-Mail-Postfach auf die Bestellbestätigung. Wenn Sie {params1} 
     Produkte als Geschenk erhalten haben, geben Sie uns bitte die Kontaktdaten der Person,
      die sie gekauft hat (Name oder E-Mail-Adresse), und wir helfen Ihnen bei der Überprüfung der Details.`,
    delAddressInfo: "Adresse löschen?",
    pwdError: "Fehler bei der Eingabe des aktuellen Passworts",
    newPwdAgain: "Bitte geben Sie das neue Passwort erneut ein",
    twoInputsDontMatch: "Die beiden Eingaben stimmen nicht überein!",
  },
  search: {
    requestSupportDesc:
      "Befolgen Sie die folgenden Schritte, um eine Rückerstattung, Rücksendung und Ersatz zu beantragen.",
    findOrderTips: "Wie finde ich meine Bestellnummer?",
    findOrderTips1: "Wenn Sie die Bestellnummer nicht finden können, ",
    clickHere: "klicken Sie hier",
    checkProducts: "Produkte überprüfen",
    clear: "Löschen",
    othersPlatform: "Andere Plattform",
    purchaseOrderNo: "Bestellnummer ",
    orderNumber: "Bestellnummer",
    newSupport:
      "Oder geben Sie eine Bestellnummer ein, um neuen Support zu initiieren.",
    purchasePlatformTips: "Bitte wählen Sie die Kaufplattform aus",
    orderNotFoundTips:
      "Entschuldigung, wir konnten Ihre Bestellnummer nicht finden.",
    importantNotice:
      "WICHTIGE HINWEIS: Nur { params }® Produkte sind für Garantie-Support berechtigt, unterliegen den beschränkten Garantiebedingungen von { params }. Bitte beachten Sie, dass im Rahmen der fortlaufenden Bemühungen von { params } um Betrugsprävention auf dem Markt im Falle, dass das Produkt, das Sie für Garantie-Support einreichen, als umgezeichnet oder anderweitig betrügerisch eingestuft wird,{ params } behält sich das Recht vor, das Produkt einzubehalten und/oder ein solches Produkt angemessen zu zerstören.",
    contactCustomer:
      "Es liegt ein nicht-technisches Problem mit Ihrem Antrag vor. Um dies zu lösen, müssen Sie sich an den Kundenservice von {params} unter {mail} wenden.",
  },
  searchTips: {
    orderDoesNotExist: `Bitte überprüfen Sie, ob Sie die richtige Bestellnummer eingegeben haben. Wenn Sie Ihr Produkt offline gekauft haben, laden Sie bitte Ihren Kaufnachweis hoch, und wir werden ihn für Sie registrieren.`,
    OrdersUnderReview: `Wir entschuldigen uns für etwaige Unannehmlichkeiten. Ihre aktuelle Bestellung wird derzeit überprüft. Wir werden sie jedoch schnell bearbeiten und Ihnen die neuesten Updates per E-Mail senden. Sie können auch den aktuellen Status in Ihrer Bestellhistorie überprüfen. Vielen Dank für Ihr Verständnis.
dateOfPurchase: Kaufdatum`,
    warrantyExpiration: "Garantieablauf",
    orderItemTips: "Die Bestellung enthält die folgenden Artikel.",
    skuName: "SKU-Name",
    checkHistory: "Historie überprüfen",
    extendedWarrantyForThisOrder: "Erweiterte Garantie für diese Bestellung",
    uploadPurchaseProof: "Laden Sie Ihren Kaufnachweis hoch",
    uploadPurchaseProofTips:
      "Bitte geben Sie weitere Informationen zu Ihrem Kauf an, um die Garantie zu registrieren.",
    extendedWarrantySuccessTips: `Wir freuen uns, Ihnen mitteilen zu können, dass Ihr Produkt, das mit der angegebenen Bestellnummer verknüpft ist, eine erweiterte Garantie von 3 Jahren erhalten hat. Diese Garantie bleibt bis | gültig. Vielen Dank, dass Sie unser Produkt gewählt haben!`,
    submissionReviewComplete: `Wir werden Ihre Anfrage innerhalb der nächsten 3 Werktage bearbeiten. und wir werden Sie per E-Mail über den Fortschritt auf dem Laufenden halten. | Wir haben Ihre Anfrage erhalten! Wir werden sie prompt bearbeiten, und Sie können innerhalb der nächsten 3 Werktage Updates per E-Mail erwarten.`,
    completeSupportInformation: "Komplette Supportinformationen",
    fillTips: `Bitte füllen Sie die folgenden Informationen aus, um die Garantie erfolgreich zu registrieren.`,
    whatWouldYouLikeToDo: `Was möchten Sie tun?*`,
    itemCondition: "Artikelzustand*",
    withOriginalPackaging: "Mit Originalverpackung",
    withoutOriginalPackaging: "Ohne Originalverpackung",
    reasonForReturn: "Warum senden Sie diesen Artikel zurück?*",
    isExtendedWarranty: "Ihre Bestellung hat bereits eine erweiterte Garantie",
    isGreaterThan90Days:
      "Entschuldigung, das Kaufdatum Ihrer Bestellung liegt über 90 Tage zurück.",
    isGreaterThan90DaysDesc:
      "Es tut uns leid, aber es scheint, dass Ihre Bestellung vor mehr als 90 Tagen gekauft wurde. Leider können wir gemäß unserer Garantie- und Rückgabebestimmungen die Garantie für Ihre Bestellung derzeit nicht verlängern.",
    isInAfterSalesDesc: `Entschuldigung, Ihre Bestellung wird derzeit für den Kundendienst bearbeitet, daher können wir keine doppelten Einsendungen akzeptieren. Sie können auch den aktuellen Status in Ihrer Bestellhistorie überprüfen. Vielen Dank für Ihr Verständnis.`,
    isOvertime:
      " Entschuldigung, Ihre Bestellung hat die Garantiefrist überschritten.",
    isOvertimeDesc1: `Ihre Bestellung hat das Ende der Servicezeit erreicht und kann nicht mehr verlängert werden. Bei Fragen können Sie sich gerne an uns wenden.`,
    isOvertimeDesc2: `Ihre Bestellung hat das Ende der Servicezeit erreicht und kann nicht mehr erstattet oder ersetzt werden.`,
    noOrdersExist: "Es tut uns leid, die aktuelle Bestellung wird überprüft.",
  },
  // {params} 字段名和其他参数
  form: {
    name: "Name",
    address: "Adresse",
    comments: "Kommentare",
    defaultAddress: "Standardadresse",
    returnAddress: "Rücksendeadresse",
    problemType: "Problemart",
    completePurchaseInformation: "Vervollständige die Kaufinformationen",
    orderTip:
      "Wenn du bereits eine Supportanfrage eingereicht hast, kannst du auch | hier klicken |, um den detaillierten Status und die Aufzeichnungen zu überprüfen.",
    uploadPhoto: "Lade ein Foto des Kaufbelegs hoch",
    rulesEnterTips: "Bitte gib {params} ein",
    rulesSelectTips: "Bitte wähle {params} aus",
    formatTips: "Falsches {params}-Format",
    uploadTips: "Bitte lade einen Kaufnachweis hoch",
    uploadReceipt: "Lade ein Foto des Kaufbelegs hoch",
    formatError: "Formatfehler",
    pictureSize: "Bildgröße überschreitet {params}",
    processingResult: "Verarbeitungsergebnis",
    findOrderScreenshot: "Wie finde ich meinen Bestell-Screenshot?",
    helpTips: "Wobei benötigst du Hilfe?",
    processingMethod: "Verarbeitungsmethode",
    readPolicy:
      "Bitte lese | die Rückgabe- und Rückerstattungsrichtlinie | sorgfältig",
    keepOriginalPackaging: "Behältst du die Originalverpackung des Produkts?",
    whetherToKeep: "ob behalten",
    problemDescription: "Problem Beschreibung",
    problemDesc: "eine Beschreibung des Problems",
    uploadUp: "Bis zu {n} Bilder hochladen", //---
    warrantyErrTips:
      "Entschuldigung, Ihre Bestellung hat die Garantiezeit überschritten.",
    warrantyErrTipsDesc:
      "Wenn Sie dennoch Unterstützung benötigen, wenden Sie sich bitte an den technischen Support von {params}. Ein Techniker wird das Problem überprüfen und Ihnen einen Kostenvoranschlag geben, wenn Sie möchten.",
    OutOfwarrantyTips:
      "Dieses Produkt hat die Garantiezeit überschritten und ist nicht für Unterstützung berechtigt.",
    accessoryTips:
      "Dieses Produkt ist ein Zubehör und ist nicht für Unterstützung berechtigt.",
    underMaintenanceTips: "Das Produkt wird derzeit gewartet.",
    clickViewDetail: "Klicken Sie hier, um Details anzuzeigen",
    warrantySuccessful: "Garantieerweiterung erfolgreich",
    warrantySuccessfulDesc:
      "Hiermit bestätigen wir, dass eine Garantieverlängerung um 3 Jahre für Ihr Produkt im Zusammenhang mit der bereitgestellten Bestellnummer gewährt wurde.<br /> Die Garantie ist gültig bis",
    keepPackage: "Behalten Sie die äußere Verpackung des Produkts?",
    uploadDefect: "Laden Sie visuelle Beweise für den Defekt hoch",
    yes: "Ja",
    no: "Nein",
    returnAndRefund: "Rückgabe und Rückerstattung",
    returnAndRefundFee: "Rückgabe und Rückerstattung - Versandgebühr",
    returnsAndReplace: "Rückgabe und Austausch",
    returnsAndReplace: "Retour et Remplacement",
    returnsAndReplace: "Reso e Sostituzione",
    returnsAndExchanges: "Rückgaben und Umtausch",
    returnsAndExchangesFees: "Rückgaben und Umtausch - Versandgebühr",
    noNeedToReturn: "Keine Rücksendung erforderlich - Neuausstellung",
    noNeedToReturnFee:
      "Keine Rücksendung erforderlich - Neuausstellung - Versandgebühr",
    rejection: "Ablehnung",
    reissueAccessory: "Neuausstellung Zubehör",
    alreadyExtendedWarranty:
      "Ihre Bestellung hat bereits eine erweiterte Garantie.",
    outOfExtendedWarranty:
      "Dieses Produkt ist außerhalb der erweiterten Garantie.",
    extendedWarrantyFailTips:
      "Dieses Produkt hat bereits eine erweiterte Garantie beantragt und kann nicht erneut beantragt werden.",
    contactCustomerService:
      "Bitte kontaktieren Sie den Amazon-Kundendienst. Vielen Dank!",
    contactAmazon: "Amazon kontaktieren",
    hasContactAmazon: "Wurde Amazon kontaktiert?",
    addressError: "Fehler bei der Adressverarbeitung, bitte erneut eingeben.",
  },
  router: {
    home: "Startseite",
    search: "Suche",
    findeOrder: "Bestellung finden",
    findeScreenshot: "Screenshot finden",
    purchaseInformation: "Kaufinformationen",
    shippingInformation: "Versandinformationen",
    shippingLabel: "Versandetikett",
    warrantyInfo: "Garantieinformationen",
    afterSalesInfo: "Kundendienstinformationen",
    orderList: "Bestellliste",
    orderInformation: "Bestellinformationen",
    supportHistory: "Support-Verlauf",
    orderDetails: "Bestelldetails",
    evaluationRecordDetails: "Auswertungsdetails",
    editProfile: "Profil bearbeiten",
    addressManagement: "Adressverwaltung",
    mailboxVerification: "E-Mail-Verifizierung",
    resetPassword: "Passwort zurücksetzen",
  },
};
