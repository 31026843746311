<template>
  <div class="breadcrumb">
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <el-breadcrumb-item
        v-for="item in levelList"
        :key="item.path"
        :to="item.path"
      >
        {{ item.meta.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      levelList: [],
    });
    const { t } = useI18n();
    watch(route, () => {
      getBreadcrumb();
    });
    onMounted(() => {
      getBreadcrumb();
    });
    const getBreadcrumb = () => {
      //   let arr = store.state.roles.map(item => item.resName)
      //   console.log(arr, '用户权限信息')
      let matched = route.matched.filter((item) => item.meta && item.meta.name);
      const first = matched[0];
      // if (first && ["RecordDetail", "Logistics"].includes(first.name.trim())) {
      //   matched.unshift({
      //     path: "/recordPage",
      //     meta: { name: "Order Information" },
      //   });
      // }
      if (route.path == "/search" && route.query.type == 1) {
        matched[0].meta.name = t("theme.extendedWarranty");
      }
      if (route.path == "/search" && route.query.type == 2) {
        matched[0].meta.name = t("theme.requestSupport");
      }
      if (
        first &&
        first.name.trim().toLocaleLowerCase() !== "home".toLocaleLowerCase()
      ) {
        matched = [
          { path: "/", meta: { name: t("theme.systemName") } },
          ...matched,
        ];
      }

      state.levelList = matched;
    };
    return {
      ...toRefs(state),
      getBreadcrumb,
    };
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  width: 100%;
  text-align: center;
  background-color: #fff;
}
.app-breadcrumb {
  width: 80%;
  margin: auto;
  display: inline-block;
  height: 5.1667rem;
  line-height: 5.1667rem;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
  ::v-deep .el-breadcrumb__inner {
    font-weight: normal;
    font-size: 1.3333rem;
    color: var(--color-primary);
  }
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    font-weight: 700;
    color: #333333;
  }
}
</style>
