<template>
  <div class="page">
    <f-header v-if="route.path != '/payments'" />
    <Breadcrumb v-if="route.path != '/payments'" />
    <router-view v-if="route.path == '/'" />
    <div v-else class="main-container">
      <router-view />
    </div>
    <installApp v-if="route.path == '/'" />
    <footerVue v-if="route.path != '/payments'" />
  </div>
</template>
<script setup>
import { getUserInfo } from "@/api/LoginRegister";
import { onBeforeMount, onMounted } from "vue";
import { useUsersStore } from "@/stores/user";
import fHeader from "@/components/header.vue";
// 导航
import Breadcrumb from "@/components/breadcrumb.vue";
// app下载
import installApp from "@/components/installApp.vue";
// 底部
import footerVue from "@/components/footer.vue";
import { useRoute } from "vue-router";
const { isLogin } = useUsersStore();
const route = useRoute();
onMounted(() => {
  if (localStorage.getItem("userInfo") == "null") {
    localStorage.removeItem("userInfo");
  }
  if (isLogin) {
    console.log(localStorage.getItem("token"), "token");
    getUserInfo().then((res) => {
      useUsersStore().userInfo = res.data;
      localStorage.setItem("userInfo", JSON.stringify(res.data));
    });
  }
});
</script>

<style>
@import "@/assets/css/font.css";
@import "//at.alicdn.com/t/c/font_4074729_p3vybsa7yj.css";

.page {
  font-weight: 500;
  font-size: 20px;
}
</style>
