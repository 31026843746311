// import { useUsersStore } from "@/stores/user";
import axios from "axios";
import request from "@/plugins/axiosInstance";
axios.defaults.timeout = 8000;

// axios.interceptors.request.use(
//   (config) => {
//     // 此处对请求进行配置
//     if (useUsersStore().logisticsToken) {
//       config.headers["Authorization"] =
//         "Bearer " + useUsersStore().logisticsToken;
//     }
//     config.headers["Accept-Language"] = "en_US";
//     config.headers["api-token"] =
//       "IoqOAOIDrywpg7pr5KbHzOw7d0xJsWWYZe4JBrvxO8-ZpvB8VbvUA8O5ejd1XrZ3qfA";
//     config.headers["user-email"] = "zmk19970720@gmail.com";
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// export function getToken() {
//   return axios({
//     url: "https://www.universal-tutorial.com/api/getaccesstoken",
//     method: "get",
//   });
// }
// export function getCountries() {
//   return axios({
//     url: "https://www.universal-tutorial.com/api/countries",
//     method: "get",
//   });
// }

// export function getStates(query) {
//   return axios({
//     url: "https://www.universal-tutorial.com/api/states/" + query,
//     method: "get",
//   });
// }
// export function getCities(query) {
//   return axios({
//     url: "https://www.universal-tutorial.com/api/cities/" + query,
//     method: "get",
//   });
// }
// export function shopifyLogin() {
//   return axios({
//     url: "/2022-01/graphql.json",
//     method: "post",
//     headers: {
//       "X-Shopify-Storefront-Access-Token": "a3180c2527e32f85d1907b4920366eef",
//       "Content-Type": "application/json",
//     },
//   });
// }

export function searchCodes(query) {
  return axios({
    url: "https://zipcodebase-zip-code-search.p.rapidapi.com/search",
    method: "get",
    params: query,
    headers: {
      "X-RapidAPI-Key": "e5e0764f6fmshfce7769b5b2ec96p1d9153jsn6a1186007375",
      "X-RapidAPI-Host": "zipcodebase-zip-code-search.p.rapidapi.com",
    },
  });
}

export function getCountries() {
  return axios({
    url: "https://countriesnow.space/api/v0.1/countries/iso",
    method: "get",
  });
}

export function getStates(params) {
  return axios({
    url: "https://countriesnow.space/api/v0.1/countries/states",
    method: "post",
    data: params,
  });
}
export function getCities(params) {
  return axios({
    url: "https://countriesnow.space/api/v0.1/countries/state/cities",
    method: "post",
    data: params,
  });
}
// 物流费用查询
export function getLogisticsCostEnquiry(params) {
  return request({
    url: "/afterSales/getLogisticsCostEnquiry",
    method: "post",
    data: params,
  });
}

// 创建退货物流单
export function createLogisticsOrders(params) {
  return request({
    url: "/afterSales/createOnlineShippingLogisticsOrders",
    method: "post",
    data: params,
  });
}
// 获取shopify运费订单付款界面
export function getShopifyPay(params) {
  return request({
    url: "/afterSales/getShopifyDraftOrderInvoicePreviewHtml",
    method: "post",
    data: params,
  });
}
// 确认shopify运费订单是否付款完成
export function confirmShopifyDraftOrderFullPaid(params) {
  return request({
    url: "/afterSales/confirmShopifyDraftOrderFullPaid",
    method: "post",
    data: params,
  });
}

// 获取物流单
export function getLogisticsOrder(params) {
  return request({
    url: "/afterSales/getLogisticsOrder",
    method: "post",
    data: params,
  });
}
