<template>
  <el-dialog
    v-model="dialogTableVisible"
    title=""
    width="515px"
    top="0vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="center-dialog"
  >
    <template #header> </template>
    <div class="login">
      <div class="content">
        <h2>{{ $t("login.signIn") }}</h2>

        <p class="sub-title">
          {{ $t("login.signInDesc", { params: $theme.toLocaleLowerCase() }) }}

          <br />
          {{ $t("login.signInDesc", 2) }}
        </p>
        <el-alert
          v-if="loginType == 1"
          :closable="false"
          :title="$t('login.warrantyTips')"
          :description="$t('login.warrantyDescTips')"
        />
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="rules"
          label-position="top"
        >
          <el-form-item class="account" label="" prop="account">
            <span>{{ $t("login.email") }}*</span>
            <el-input v-model.trim="loginForm.account" @blur="existAccount" />
            <el-icon
              v-loading="emailLoading"
              :color="isExit == 1 ? '#67C23A' : '#999'"
              ><CircleCheck v-if="isExit == 1" /><WarningFilled
                v-if="isExit == 0"
            /></el-icon>
            <!-- <el-tooltip
              effect="light"
              content="Use other email"
              placement="right"
            >
              <el-icon class="question" v-if="isExit == 0" @click="isExit = 2"
                ><QuestionFilled
              /></el-icon>
            </el-tooltip> -->
          </el-form-item>
          <!-- <el-form-item class=" account" label="" prop="account">
            <span>Email*</span>
            <el-input v-model="loginForm.account" />
          </el-form-item> -->
          <el-form-item
            v-if="isExit == 2"
            class="account"
            label=""
            prop="realEmailAddress"
          >
            <span> {{ $t("login.realEmail") }}*</span>
            <el-input
              v-model.trim="loginForm.realEmailAddress"
              @blur="inputBlur"
            />
          </el-form-item>
          <el-form-item
            v-if="isExit == 0 || isExit == 2"
            class="code"
            label=""
            prop="code"
          >
            <span>{{ $t("login.verificationCode") }}*</span>
            <el-input v-model.trim="loginForm.code" />
            <span
              class="send-btn"
              :style="{
                color: send.isSendCode ? '#999' : '',
                backgroundColor: send.isSendCode ? '' : $themeConfig.themeColor,
              }"
              @click="sendCode"
              v-loading="send.isLoading"
              >{{ send.text }}</span
            >
          </el-form-item>
          <p
            class="remarks"
            v-if="
              (send.isSendCode || send.text == $t('login.resend')) &&
              isExit != 2
            "
          >
            {{ $t("login.notReceivedCode", 1) }}
            <span class="link" @click="isExit = 2">
              {{ $t("login.notReceivedCode", 2) }}</span
            >
          </p>
          <el-form-item label="" prop="pwd" class="">
            <span>{{ $t("login.password") }}*</span>
            <el-input
              v-model.trim="loginForm.pwd"
              type="password"
              show-password
            />
          </el-form-item>
          <div>
            <p style="text-align: start">
              <span class="link" @click="goPage">{{
                $t("login.forgotPassword")
              }}</span>
            </p>
          </div>

          <div
            class="login-btn"
            @click="handleLogin"
            v-loading="isLoading"
            v-analytics="[
              'login',
              `login_${
                loginType == 1
                  ? 'warranty'
                  : loginType == 2
                  ? 'afterSales'
                  : 'other'
              }`,
            ]"
          >
            {{ $t("theme.continue") }}
          </div>
          <GoogleLogin
            :clientId="$themeConfig.googleClientId"
            :callback="googleCallback"
            :buttonConfig="buttonConfig"
          />
        </el-form>
        <p class="err-tip" v-if="send.errTip">
          <el-icon><Warning /></el-icon>{{ send.errTip }}
        </p>
        <div class="checkbox">
          <el-checkbox v-model="isRemember" size="medium">{{
            $t("login.rememberMe")
          }}</el-checkbox>
        </div>
        <div class="email-tips">
          {{ $t("login.emailTips") }}
        </div>
      </div>
    </div>
  </el-dialog>
  <addAddRess ref="addressRef" @submit="submitAddress" />
</template>

<script setup>
import { onMounted, reactive, ref, defineExpose } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  login,
  tokenLogin,
  existUserAccount,
  sendActiveEmail,
} from "@/api/LoginRegister";
import { shopifyLogin } from "@/api/logistics";
import addAddRess from "@/views/LoginRegister/editAddress.vue";
import { GoogleLogin } from "vue3-google-login";
import { rule } from "@/utils/rule";
import CryptoJS from "crypto-js";
import _ from "lodash";
import Cookies from "js-cookie";
import dicType from "@/hook/dicType";
import { useUsersStore } from "@/stores/user";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
let { defaultAddress } = dicType();
const router = useRouter();
const loginFormRef = ref();
let isLoading = ref(false);
let isRemember = ref(false);
let loginType = ref(null);
let addressRef = ref(null);
let isExit = ref("-1"); //0不存在  1存在
let send = ref({
  count: 90,
  isSendCode: false,
  isLoading: false,
  text: t("login.sendCode"),
  errTip: "",
});
// theme == "Comfier"
//     ? "953803733522-vl57ikru9cn48ub38cq7tkn917u64bb2.apps.googleusercontent.com"
//     : "442694128640-2q9c3cucp42d07t51mi9aon95t52dhbk.apps.googleusercontent.com"
// let yourClientId = ref(
//   theme == "Comfier"
//     ? "953803733522-vl57ikru9cn48ub38cq7tkn917u64bb2.apps.googleusercontent.com"
//     : "442694128640-2q9c3cucp42d07t51mi9aon95t52dhbk.apps.googleusercontent.com"
// );

const buttonConfig = ref({
  shape: "square",
  text: "signin_with",
  width: "400px",
});
let pageUrl = ref();
const rules = reactive({
  account: [
    {
      required: true,
      validator: rule.checkEmail,
      trigger: "blur",
    },
  ],
  realEmailAddress: [
    {
      required: true,
      validator: rule.checkEmail,
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: "",
      trigger: "blur",
    },
  ],
  pwd: [
    {
      required: true,
      message: "",
      trigger: "blur",
    },
    {
      min: 6,
      message: t("login.passwordValidate"),
      trigger: "blur",
    },
  ],
});
let loginForm = reactive({
  account: "",
  code: "",
  pwd: "",
  realEmailAddress: "",
});
let dialogTableVisible = ref(false);
let callbackFun;
/**
 * path 登录后跳转的页面
 * callback 登录回调
 * type 什么类型的弹窗 1 延保 2售后 3其他
 * @param
 */
let componentVal = ref("");
const init = (path, callback, type = 3, component = "") => {
  componentVal.value = component;
  loginType.value = type;
  if (callback) {
    console.log(path, callback);
    callbackFun = callback;
  }
  dialogTableVisible.value = true;
  pageUrl.value = path;
};
const goPage = () => {
  dialogTableVisible.value = false;
  router.push("/forgetpwd");
};
defineExpose({ init });
// 谷歌登录回调
const googleCallback = (res) => {
  let { credential } = res;

  tokenLogin({
    accessToken: credential,
    itype: 1,
  }).then((res) => {
    loginResultHandle(res);
  });
};
const handleLogin = _.throttle(
  async () => {
    send.value.errTip = "";
    const valid = await loginFormRef.value.validate();
    if (valid) {
      isLoading.value = true;
      //     const query = `mutation SignInWithEmailAndPassword(
      //     $email: String!,
      //     $password: String!,
      // ) {
      //     customerAccessTokenCreate(input: {
      //         email: $email,
      //         password: $password,
      //     }) {
      //         customerAccessToken {
      //             accessToken
      //             expiresAt
      //         }
      //         customerUserErrors {
      //             code
      //             message
      //         }
      //     }
      // }`;
      //     shopifyLogin({
      //       query,
      //       variables: {
      //         email: loginForm.account,
      //         password: loginForm.pwd,
      //       },
      //     }).then((res) => {
      //       console.log(res);
      //     });
      //     return;
      login({
        ...loginForm,
        pwd: loginForm.pwd,
      }).then((res) => {
        loginResultHandle(res);
        // else {
        //   ElNotification({
        //     title: "error",
        //     dangerouslyUseHTMLString: true,
        //     message: `${res.msg} <span class="link"
        //       > Deactivation.</span>`,
        //     type: "error",
        //     onClick() {
        //       router.push({
        //         path: "/verification",
        //       });
        //       localStorage.setItem(
        //         "info",
        //         JSON.stringify({
        //           account: loginForm.account,
        //           pwd: loginForm.pwd,
        //         })
        //       );
        //       console.log("点击跳转");
        //     },
        //   });
        // }

        console.log(res);
      });
    } else {
      isLoading.value = false;
    }
  },
  2000,
  { trailing: false }
);
const loginResultHandle = (res) => {
  if (res.code == 0) {
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("userInfo", JSON.stringify(res.data));
    useUsersStore().isLogin = true;
    useUsersStore().userInfo = res.data;
    // 登录成功后 判断是否选择了勾选密码
    if (isRemember.value) {
      //添加cookie
      Cookies.set("account", loginForm.account, {
        expires: 30,
      });
      //使用crypto-js进行加密（需要npm加载后引入） 并存储到cookie中 此处user123! 为秘钥
      Cookies.set("pwd", CryptoJS.AES.encrypt(loginForm.pwd, "user123!"), {
        expires: 30, // 存储30天
      });
    } else {
      // 删除cookie
      Cookies.remove("account");
      Cookies.remove("pwd");
    }

    dialogTableVisible.value = false;
    if (pageUrl.value) {
      router.push(pageUrl.value);
    } else if (callbackFun) {
      callbackFun();
    } else if (componentVal.value == "address") {
      addressRef.value.init("add");
    } else {
      window.location.reload();
    }
  } else {
    send.value.errTip = res.msg;
  }
  isLoading.value = false;
};
const isRememberInfo = () => {
  loginForm.account = Cookies.get("account") ? Cookies.get("account") : "";
  const pwd = Cookies.get("pwd") ? Cookies.get("pwd") : "";
  if (pwd) {
    // 对密码进行解密
    loginForm.pwd = CryptoJS.AES.decrypt(pwd, "user123!").toString(
      CryptoJS.enc.Utf8
    );
    // 将是否记住密码置为true
    isRemember.value = true;
  } else {
    loginForm.pwd = "";
  }
};
let clearId = ref(null);
// 发送激活码
const sendCode = _.throttle(
  () => {
    if (send.value.isSendCode || send.value.count < 90) return;
    sendActiveEmail({
      account: loginForm.realEmailAddress || loginForm.account,
    }).then((res) => {
      send.value.isSendCode = true;
      if (res.code == 0) {
        clearId.value = setInterval(() => {
          send.value.count--;
          send.value.text = send.value.count + "s";
          if (send.value.count == 0) {
            clearInterval(clearId.value);
            send.value.count = 90;
            send.value.text = "resend";
            send.value.isSendCode = false;
          }
        }, 1000);
      }
    });
  },
  5000,
  { trailing: false }
);

const emailLoading = ref(false);
const inputBlur = () => {
  if (!loginForm.account.trim() && !loginForm.realEmailAddress.trim()) return;
  if (send.value.text != "Send code") {
    clearInterval(clearId.value);
    send.value = {
      count: 90,
      isSendCode: false,
      isLoading: false,
      text: "Send code",
      errTip: "",
    };
  }
};
const existAccount = () => {
  inputBlur();
  loginFormRef.value.clearValidate(["code"]);

  emailLoading.value = true;
  existUserAccount({
    account: loginForm.account,
  }).then((res) => {
    emailLoading.value = false;
    if (res.data && res.data.localUserinfo) {
      let { isEmailCheck } = res.data.localUserinfo;
      isExit.value = isEmailCheck;
    } else {
      isExit.value = 0;
    }
  });
};
const submitAddress = () => {
  useUsersStore().userAddress = defaultAddress;
};

onMounted(() => {
  isRememberInfo();
});
</script>
<style scoped lang="less">
.login {
  width: 87%;
  margin: 0 auto;
  // padding: 0 5px;
  // max-height: 60vh;
  // overflow-y: auto;
  .content {
    margin: 0 auto;
    width: 97%;
    font-size: 1.2rem;
    h2 {
      font-size: 1.6667rem;
      font-weight: 500;
      color: #333;
      margin: 15px 0;
    }
    .sub-title {
      color: #888888;
      line-height: 1.6667rem;
    }
    .email-tips {
      color: #888;
      font-size: 1rem;
    }
    .el-form {
      position: relative;

      .el-form-item {
        border: 1px solid #ccd9d9d9;
        margin-bottom: 1.1111rem;
        position: relative;
        border-radius: 4px;
        box-sizing: border-box;
        &.is-error {
          border: 3px solid var(--el-color-danger);
        }
        ::v-deep .el-form-item__content {
          padding: 0 1.3889rem;
          .el-form-item__error {
            top: 103%;
          }
          span {
            margin-top: 0.5rem;
            font-size: 1.2rem;
            color: #ccd9d9d9;
          }
          .el-input__wrapper {
            box-shadow: none;
            padding: 0 0 5px;
            margin-bottom: 5px;
            .el-input__inner {
              color: #000;
              font-size: 1.3333rem;
              height: 2rem;
              // line-height: 2.2222rem;
            }
          }
        }
        &.account {
          ::v-deep .el-icon {
            position: absolute;
            right: 2.4rem;
            bottom: 20%;
            font-size: 14px;
            .el-loading-mask {
              top: 150%;
              .el-loading-spinner {
                .circular {
                  width: 15px !important;
                  height: 15px !important;
                }
              }
            }
          }
          .question {
            position: absolute;
            right: -3rem;
            font-size: 2.2rem;
            color: #ccc;
            cursor: pointer;
          }
        }

        &.code {
          .send-btn {
            z-index: 99;
            padding: 0 8px;
            position: absolute;
            right: 34.2px;
            border-radius: 0.3333rem;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .remarks {
        font-size: 1.1rem;
        margin: 0.5rem 0 0.7rem;
      }
      .login-btn {
        margin: 1rem auto;
        text-align: center;
        width: 100%;
        font-size: 1.2rem;
        padding: 0.8333rem 0;
        cursor: pointer;
        background: var(--color-primary);
        border-radius: 0.3333rem;
        color: #fff;
      }
    }
    .err-tip {
      color: #ff0000;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-icon {
        margin-right: 0.5556rem;
      }
    }
    .checkbox {
      // margin: auto;
      text-align: start;
      width: 100%;
      padding: 1rem 0;
      // border-bottom: 1px solid #f6f6f6;
      ::v-deep .el-checkbox {
        .el-checkbox__input {
          .el-checkbox__inner {
            width: 18px;
            height: 18px;
            border-radius: 4px;
            &::after {
              border-width: 3px;
              left: 5px;
              top: 3px;
            }
          }
        }
        .is-checked {
          .el-checkbox__inner {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
          }
        }
        .el-checkbox__label {
          font-size: 16px;
        }
        .is-checked + .el-checkbox__label {
          color: #333;
        }
      }
    }
    .other {
      text-align: center;
      .link {
        color: var(--color-primary);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
::v-deep.el-alert--info {
  background-color: var(--light-color-primary2) !important;
  color: var(--color-primary) !important;
  padding: 0.8333rem 0.5556rem;
  margin-bottom: 0.8333rem;
  .el-alert__title {
    font-size: 1.2rem;
  }
  .el-alert__description {
    color: var(--color-primary) !important;
    font-size: 0.8rem;
  }
}
</style>
