export const redirectBasedOnDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  const currentUrl = window.location.href;
  const appSegment = "/app";
  if (isMobile) {
    if (!currentUrl.includes(appSegment)) {
      const newUrl = new URL(currentUrl);
      newUrl.pathname = appSegment + newUrl.pathname;
      window.location.href = newUrl.toString();
    }
  } else {
    if (currentUrl.includes(appSegment)) {
      const newUrl = new URL(currentUrl);
      newUrl.pathname = newUrl.pathname.replace(appSegment, "");
      window.location.href = newUrl.toString();
    }
  }
};
