// 英语
export default {
  // 文字中有 | 不要删掉！！
  // {params}平台 将{params}放在对应位置
  login: {
    login: "login",
    userName: "userName",
    password: "Password",
    email: "Email",
    realEmail: "Real Email",
    forgotPassword: "Forgot password",
    verificationCode: "Verification Code",
    resend: "resend | Resend",
    send: "Send",
    sendCode: "Send code",
    rememberMe: "Remember me",
    signIn: "Sign in with your Email to continue",
    signInDesc:
      "If you do not yet have a {params} account, it will automatically | create one for you",
    warrantyTips: "Just one step away from the success of extended warranty!",
    warrantyDescTips: `Log in and
    activate your account now for easy access to query warranty, return, and
    exchange records using this account. Plus, you'll have a chance to win
    gift cards!`,
    notReceivedCode: `Didn't receive the verification code? Please confirm if the email
    address you entered is valid and correct. If it's not,| please click here to submit.`,
    emailTips: `If you have not received the verification code for several times,
    please check whether the e-mail address is filled in correctly or
    change a new e-mail address.`,
    passwordValidate: "Your password must be at least 6 characters",
    emailFormat: "Please enter the correct email format",
    vaildFormat: "Please enter the correct {params} format",
    createYour: "Create Your",
    changeNewPassword: "Change new password",
    changeNewPasswordDesc: `Your password must be at least 8 characters, <br />containing numbers,
    uppercase letters and lowercase letters`,
  },
  forgetpwd: {
    title: "Are you having trouble signing in?",
    titleDesc: "Please enter your Email to get started.",
    verificationCode: "Type the characters in the image",
    sendSuccessTips:
      "The email to reset your password has been sent to your inbox. Please check your email.",
  },
  recordPage: {
    supportHistoryDesc:
      "View the status of your existing or previous support requests",
    timespan: "Timespan",
    status: "Status",
    expectedTips1: "Expected to complete in 3 working days",
    expectedTips2: "It is expected to be delivered within 10 working days",
    expectedTips3: "Expected to receive within 10 working days",
    review: "Review & Win Prizes!",
    additionalRating: "Additional Rating",
    product: "Product",
    sku: "Sku",
    type: "Type",
    created: "Created",
    initiated: "Initiated",
    update: "Update",
    action: "Action",
  },
  recordDetail: {
    warrantyStatus: "Warranty Status",
    headerTips1: "Your request is being processed",
    headerTips2: "Your return request has been approved",
    headerTips3: " You have successfully completed the payment",
    headerTips4: "Download label and send back your product",
    headerTips5: "Your return was received",
    headerTips6: "Your new item has been shipped",
    headerTips7: "Your extended warranty has been completed",
    headerTips8: "Your after-sale has been completed",
    headerTips9: "Your payment has not been completed yet",
    underReviewDesc: `We've received your request! We'll begin processing it promptly, and
    you can expect updates via email within the next 3 business days. If
    you have any questions, please don't hesitate to contact us.`,
    returnsInProgressDesc: `Your return request has been approved! Your return request has been
    approved! We will send you a return label through the system within 3
    business days. Please make sure to download it promptly and send the
    product back.`,
    notSended: "Not Sended?",
    haveSended: "Have Sended?",
    haveSendedDesc: `Your replacement will be processed once we receive your return ,
    please be patient and feel free to reach out if you have any
    questions or concerns. We're here to help! You can click the
    button below to track the detailed shipping information.`,
    resendDesc:
      "Your new product is about to be shipped. Please keep an eye on the shipping status updates.",
    awaitingPaymentDesc: `Your payment is still pending. Please complete it within 15
    minutes, otherwise the creation of this label will be
    automatically canceled.`,
    trackShippingStatus: "Track Shipping status",
    proceedToPayment: "Proceed to payment",
    successPay: "You have successfully completed the payment.",
    returnsInProgressDesc1: `Kindly pack your item back in its original packaging (if applicable). Just click the button below
    to download the shipping label and print it out. After that, simply
    affix it to your package and drop it off at any UPS location. `,
    returnsInProgressDesc2: `Your replacement will be processed once we receive your return ,
    please be patient and feel free to reach out if you have any
    questions or concerns. We're here to help! You can click the
    button below to track the detailed shipping information.`,
    exchangeInProgressDesc: `Your replacement will be processed once we receive your return ,
    please be patient and feel free to reach out if you have any
    questions or concerns. We're here to help!`,
    exchangeInProgressDesc1: `Your return is being processed at the Returns Center.Your new item
    has been shipped for you, with the tracking numbe |, If you have any questions, please don't hesitate to contact us. `,
    replacementInProgressDesc: `Your new item has been shipped for you, with the tracking numbe|, If you have any questions, please don't hesitate to contact us.`,
    refundsInProgressDesc: `Your return is being processed at the Returns Center. Your refund will
          be returned to your original account within 3 business days. Please
          keep an eye out for it.`,
    ReturnOrRefundStatus: "Return or Refund Status",
    reviewDetails: "Review Details",
    review: "Review",
    request: "Request",
    trackingNumber: "Tracking number",
    requestSupportInformation: "Request support information",
    shippingTips:
      "Your support request has been approved. | Please complete shipping information to continue.",
    downloadLabeltIPS:
      "{params} has sent a return label for you, | Please Click here to download | , then print and pack your item for return.",
    ProcessingMethodForPlatformReview: "Processing method for platform review",
    reviewInstructions: "Review instructions",
    refundGiftCardType: "Refund gift card type",
    refundGiftCardID: "Refund gift card ID",
    refundGiftCardAmount: "Refund gift card amount",
    refundGiftDesc: `Your refund request is being processed, and the amount({amount}) will be credited to your {type} (ID {id}).You can view the refund receipt by clicking the "View Details" button.`,
    refundVoucher: "Refund voucher",
    refundVoucherDesc: `Your refund request ({ params }) has been processed
    and the amount has been returned to your payment account. Please
    check your account to confirm the receipt.`,
    refundAmount: "Refund amount",
    giftCardType: "Gift card type",
    giftCardID: "Gifts Card ID",
    amount: "Amount",
    gifts: "Gifts",
    reviewNotApproved: "Review not approved",
    reviewContent: "Review content",
    rating: "Rating",
    photo: "Photo",
    ratingPhoto: "Rating Photo",
    trackingID: "Tracking ID",
    shippingCarrier: "Shipping carrier",
    purchaseDate: "Purchase Date",
    limitedDate: " Limited Warranty Expiration Date",
    stepDetailTips1: `Your claim is approved. Please send back the defective product at your
    own expense. Use the "Estimation and Get Shipping Label" icon to check
    the estimated shipping fee. If it's suitable, you can then purchase
    our shipping service. Feel free to watch the instructional video if
    necessary.`,
    getLabelStepTitle: "Instructions for returning your package:",
    getLabelStep1: "1.Pay for the shipping service and get the label.",
    getLabelStep2: "2.Use the original packaging box if possible.",
    getLabelStep3: "3.Print and attach the label to the box.",
    getLabelStep4: "4.Drop off the package at UPS.",
    estimationAndGetShippingLabel: "Estimation and Get Shipping Label",
    searchOthers: "Search Others",
    returnTips: `Your return request has been approved! Kindly pack your item back in
    its original packaging (if applicable). Just click the button below
    to download the shipping label and print it out. After that, simply
    affix it to your package and drop it off at any UPS location.`,
    downloadLabel: "Download Label",
    reasonForReturn: "Reason for return",
    expired: "Expired",
    underWarranty: "Under Warranty",
    qty: "qty",
  },
  evaluation: {
    exclusiveOpportunity: "Exclusive Opportunity",
    descriptions: `Get a chance to receive one of below giveaway item or<br />
    $20 voucher  <br /><b>Easy to participate:</b><br />
    Take 1-3 minutes to fill out the form, see" view detail"`,
    winGiftCard: "Win a $20-$50 Gift Card!",
    freeTips: " Absolutely Free - No Hidden Fees, No Credit Card Required!",
    feedbackTips: `We genuinely value your feedback and would be thrilled if you could
    spare just a few minutes to share your shopping experience with fellow,
    shoppers. Your input is incredibly valuable to us!`,
    steps: "3 Easy Steps To Win",
    steps1: "STEPS 1:",
    steps1Desc: `Take a photo of your new {params} massager.
    Make sure you get a great shoot for a good start-up!`,
    steps2: "STEPS 2:",
    steps2Desc: `Post it on Facebook, Instagram, Twitter,
    YouTube,Tiktok with the hashtag #{params}.`,
    steps3: "STEPS 3:",
    steps3Desc: `Copy the post URL and submit it in the form
    below.`,
    storeGiftCard: "$30 {params} Store Gift Card",
    pasteUrl: "Paste your URL here.",
    tips: "*Remember to set your post to Public so we can view it.",
    selectGiftTips: "Select the gift you want for",
    selectproduct: "Select your product",
    selectproductDesc: "Select the products you want to share.",
    footerTips: `will continue to be committed to creating a healthy lifestyle
    for our customers. Thank you for recognizing our products.`,
    fillInAddressTips:
      "Completing your delivery address so that you can receive your gift ASAP.",
    rateTitle: "Are you satisfied with the product？",
    submitSteps1: "Click here | to share your shopping experience.",
    submitSteps2:
      "Take a screenshot of the comment or rating you left and save it.",
    submitSteps3:
      " Back to this page and | upload the screenshot you just saved.",
    uploadScreenshot: "Upload your screenshot",
    copyUrl: "Copy the post URL and submit it in the form beloew",
    sharingSuccessTips: "Thank you for your sharing",
    sharingSuccessDesc: `We appreciate your great support. Your submission has been received and
    will be reviewed in 3 working days. Reward ID for gift card will be sent
    to your email shortly after we complete review. You can also check the
    review history for any update.`,
    sharingSuccessDesc1: `We apologize for the negative product experience you have had. We will
    make improvements to provide you with a better experience.`,
    afterSalesRateTitle: "Please rate this after-sales service",
    placeRate: "Please rate.",
  },
  logistics: {
    info: "Logistics Info",
    orderNumber: "Logistics Order Number",
    shippingInfo: "Complete Shipping Information",
    orderTime: "Time of order",
    company: "Logistics company",
    downloadFaceSheet: "Download Face Sheet",
    shippingInfoDesc: "Follow the steps below to complete Shipping Information",
    buyLable: "Buy a lable on {params}",
    packagingWeight: "Packaging weight",
    completed: "Completed",
    createTime: "Create Time",
    shippingAddress: "Shipping Address",
    shippingInformation: "Shipping Information",
    retry: "Retry",
    price: "Price",
    weight: "Weight",
    length: "Length",
    width: "Width",
    height: "Height",
    lb: "lb", //lb 重量 单位
    in: "in", //in 长宽高 单位
    tel: "Tel",
    finish: "Finish",
    ups: "UPS",
    total: "Total (with discount)",
    $: "$",
    postPaymentInstructions: "Post-Payment Instructions",
    postPaymentInstructionsDesc: `Please return to this page to download the shipping label after
    successfully completing your payment.`,
    paymentAlerts: `Please complete it within 15 minutes, otherwise the creation of
    this label will be automatically canceled.`,
    payNow: "Pay now",
    publishedCharges: "Published Charges",
    telephoneNumber: "telephone number",
    uplodLable: "Upload your own lable",
    shipTo: "You need to ship your item to",
    createOrderTips:
      "Please return to this page to download the shipping label after successfully completing your payment.",
    paymentTips: "Please complete the payment on the newly opened page",
    paymentTipsDesc: `Please do not close this window before the payment is completed. After
    the payment is done, please click the button below according to your
    situation.`,
    payTips:
      "Please note that discount codes or gift cards cannot be used for this order. Otherwise, the order will be invalidated.",
    howGetShippingLabel: "How do you get your shipping label?",
    priceTips: `*This price is an estimate. Placing an order will secure a
      discounted rate.`,
    payGetLabel: "Pay and get shipping label",
  },
  findOrder: {
    title: "How to find your amazon order number",
    step1: `step1.Open Amazon home page`,
    step2: `step2.Click on Returns & Orders`,
    step2Dec:
      "You should find this in the top-right corner, sandwiched between Accounts & Lists and your Basket.",
    step3: "step3.find your order",
    step3Dec: "The order number is generally composed of 17 digits.",
  },
  findScreenshot: {
    title: "HOW TO FIND YOUR ORDER SCREENSHOT?",
    step1: `1. Your orders (If you're dealing with older orders, use the filter
    to set a time range.)`,
    step2: `2. click "View order details"`,
    step3: '3. click "View Invoice"',
    step4: "4. Invoice",
  },
  faq: {
    title: "Frequently Asked Questions",
    faqDesc:
      "Before requesting support, you can quickly screen your issue through the FAQ",
    faqTips: `If you read through the FAQ but still cannot find a solution to your
    problem, | Clik here`,
  },
  theme: {
    all: "All",
    addAddress: "Add address",
    back: "Back",
    collapse: "Collapse",
    continue: "Continue",
    checking: "Checking...",
    cancel: "Cancel",
    canceled: "canceled",
    delete: "Delete",
    download: "Download",
    edit: "Edit",
    editAddress: "Edit address",
    receivingAddress: "receiving address",
    fullname: "Full name",
    nickname: "Nickname",
    phone: "Phone",
    zipcode: "ZIP Code",
    country: "Country/Region",
    state: "State",
    set: "Set",
    city: "City",
    search: "Search",
    more: "More",
    next: "Next",
    success: "Success",
    history: "History",
    confirm: "Confirm",
    save: "Save",
    ok: "OK",
    others: "Others",
    logout: "Logout",
    login: "Log in",
    notification: "Notification",
    firstName: "First Name",
    lastName: "Last Name",
    changePwd: "Change your password",
    currentPwd: "Current password",
    newPwd: "New password",
    confirmNewPwd: "Confirm new password",
    lastUpdated: "Last updated",
    logoutTips: "Please confirm if you want to log out?",
    viewDetails: "View Details",
    submit: "Submit",
    systemName: "Online Service Center",
    setAddress: "Set as default address",
    systemDesc: "Create and manage your support and warranty requests",
    requestSupport: "Request Support",
    requestSupportDesc:
      "Let us help with your refund, return and replacement issues",
    extendedWarranty: "Extended Warranty",
    refundsAndReturns: "Refunds & Returns",
    submitApplication: "Submit application",
    reviewPending: "Review Pending",
    finished: "Finished",
    returnPending: "Return Pending",
    returnInProgress: "Return in progress",
    refundInProgress: "Refund in progress",
    reshipPending: "Reship Pending",
    reshipInProgress: "Reship in progress",
    replacePending: "Replace pending",
    replaceInProgress: "Replace in progress",
    replacementInProgress: "Replacement in progress",
    close: "Close",
    toShip: "To Ship",
    inTransit: "In Transit",
    reviewRejected: "Review Rejected",
    amazonGifts: "Amazon Gifts",
    shopifyGifts: "Shopify Gifts",
    walmartGifts: "Walmart Gifts",
    lazadaGifts: "Lazada Gifts",
    shopeeGifts: "Shopee Gifts",
    last30Days: "Last 30 days",
    last90Days: "Last 90 days",
    lastYear: "Last year",
    thisYear: "This year",
    whatsAppContact: "WhatsApp contact",
    forFill: "For Fill",
    goTodownload: "Go to download",
    successfully: "Successfully!",
    confirmResetPwd: "Please confirm the password",
    shareExperience: "Share Your Experience",
    uploadScreenshot: "Place upload your screenshot",
    userInfoChangeSuccess: "User information changed successfully",
    connectionErr: "Check your internet connection and try again",
    mustBeJPGFormat: "Avatar picture must be JPG format!",
    inconsistentPasswords: "The passwords entered two times are inconsistent",
    successfulRegistration:
      "Registration succeeded. The activation code has been sent to the mailbox",
    ownShippingCostsTips: `Thank you for bringing this to our attention. As per our warranty
    policy, please return the defective item to our warehouse at your
    cost. Once received, we will promptly send you a replacement or
    issue a refund as requested. To assist your return, we will create
    a return label and obtain a price quote. You can also use any
    carrier you prefer, but please provide the tracking number for
    follow-up. Thank you for your cooperation.`,
    shippingCostsTips: `Thank you for letting us know about the issue. We have created a
    prepaid shipping label for your convenience. Please return the
    defective device, and upon receipt, we will promptly send you a
    replacement or issue a refund as requested. Thank you!`,
    feedbackTips: `Do you have a spare few minutes to give us some feedback？We are
    looking for some input making our services even better,We appreciate
    your time！`,
    extendedWarrantyDesc: "Verify your coverage and Extended warranty",
    supportHistory: "Support History",
    supportHistoryDesc: "Sign in to view status on existing or past requests ",
    APPDesc: "Find and compare products, get support, and connect with",
    needMoreHelp: "Need more help?",
    contactSupport: "Contact support",
    whereToPurchase: "Where to purchase",
    systemAutoCorrected: "System auto-corrected:",
    purchasePlatform: "Purchase Platform",
    orderNumberFormat: "Order Number Format:",
    copyright: "Copyright © { params } Corporation V2.3.0",
    addressManagementDesc: "Edit, add, or delete your shipping address.",
    personalInformation: "Personal Information",
    personalInformationDesc: `Manage your personal information, including password | and email
    addresses where you can be contacted.`,
    quickOptionsTips: "You can choose an existing order to initiate support.",
    importantNotice: "IMPORTANT NOTICE",
    orderProblemsTips1: "The warranty period has expired",
    orderProblemsTips2:
      "Sorry, this is a parts order and cannot extend the warranty or after sales.",
    orderProblemsTips3:
      "Sorry, the used product cannot provide support and cannot be extended for warranty.",
    uploadSuccessful: "Upload Successful",
    submitSuccessfully: "Submit Successfully",
    submitSuccessfullyDesc: `We will review your order within 3 business days. you can also check
    the <br> progress by viewing the support history.`,
    findWalmartOrder: "How to find Walmart Purchase order No.?",
    findWalmartOrder1: "1. Log into your Walmart account",
    findWalmartOrder2: "2. Select 'Purchase History'",
    findWalmartOrder3:
      "3. Scroll to the order you want and find the Purchase order#",
    findShopifyOrder: `You will receive an order number via email every time you make a
      purchase on www.{params}.com. Please check your email inbox for the order confirmation. If
      you have received {params1} products as a gift, kindly provide us
      with the contact information of the person who purchased it (name or
      email address), and we will assist you in checking the details.`,
    delAddressInfo: "Delete address information?",
    pwdError: " Current password input error",
    newPwdAgain: "Please enter a new password again",
    twoInputsDontMatch: "Two inputs don't match!",
  },
  search: {
    requestSupportDesc:
      "Follow the steps below to apply for a refund, return, and replacement.",
    findOrderTips: "How to find my order number？",
    findOrderTips1: "If you can't find the order number, ",
    clickHere: "please click here",
    checkProducts: "Check products",
    clear: "Clear",
    othersPlatform: "Others Platform",
    purchaseOrderNo: "Purchase Order No. ",
    orderNumber: "Order number |Order Number",
    newSupport: "Or you can enter an order number to initiate new support.",
    purchasePlatformTips: "Please select the purchase platform",
    orderNotFoundTips: `Sorry,We couldn’t find your order number.`,
    importantNotice: `IMPORTANT NOTICE: Only { params }® Products are eligible for warranty
    support, subject to { params }'s limited warranty terms. Please be
    advised as part of { params }'s ongoing efforts to prevent fraud in the
    marketplace, in the event the product you submit for warranty support is
    found to be re-marked or otherwise fraudulent product,{ params } reserves
    the rights to retain the product and/or destroy such product as
    appropriate.`,
    contactCustomer: `There is a non-technical issue with your request, in order to resolve
    you will need to contact {params} customer service at {mail}`,
  },
  searchTips: {
    orderDoesNotExist: `Please check if you have entered the correct order number. If you
    purchased your product through offline channels, please upload your
    purchase proof, and we will register it for you.`,
    OrdersUnderReview: `We apologize for any inconvenience. Your current order is currently
    under review. Rest assured, we will process it promptly and provide
    you with the latest updates via email. You can also check the latest
    status in your order history. Thank you for your understanding.`,
    dateOfPurchase: "Date of Purchase",
    warrantyExpiration: "Warranty Expiration",
    orderItemTips: "The order contains the following items.",
    skuName: "SKU Name",
    checkHistory: "Check history",
    extendedWarrantyForThisOrder: "Extended warranty for this order",
    uploadPurchaseProof: "Upload your purchase proof",
    uploadPurchaseProofTips:
      "Please provide additional details about your puchase to register warranty.",
    extendedWarrantySuccessTips: `We're pleased to confirm that your product, associated with the
    provided order number, has been granted a 3-year extended warranty.
    This warranty will remain in effect until | . Thank you for choosing our product!`,
    submissionReviewComplete: `We'll prcoces your request within the next 3 business days. and
    we'll keep you updated by email on the progress. | We've received your request! We'll begin processing it promptly,
    and you can expect updates via email within the next 3 business
    days.`,
    completeSupportInformation: "Complete support Information",
    fillTips: `Please fill in the following information to successfully register
    warranty.`,
    whatWouldYouLikeToDo: "What would you like to do?*",
    itemCondition: "Item condition*",
    withOriginalPackaging: "With original packaging",
    withoutOriginalPackaging: "Without original packaging",
    reasonForReturn: "Why are you returning this item?*",
    isExtendedWarranty: "Your order has already had an extended warranty",
    isGreaterThan90Days: "Sorry, your order purchase date exceeds 90 days",
    isGreaterThan90DaysDesc: `We're sorry, but it appears that your order was purchased more than 90 days ago. Unfortunately, as our warranty and return policy, we're unable to extend the warranty for your order at this time.`,
    isInAfterSalesDesc: `Sorry, your order is currently being processed for after-sales service, so we're unable to accept duplicate submissions.You can also check the latest status in your order history. Thank you for your understanding.`,
    isOvertime: "Sorry, your order has exceeded the warranty period",
    isOvertimeDesc1: `Your order has reached the end of service life and can no longer be extended. If you have any questions, don't hesitate to contact us.`,
    isOvertimeDesc2: `Your order has reached the end of service life and can no longer be refunded or replaced`,
    noOrdersExist: "Sorry, the current order is pending review.",
  },
  // {params} 字段名和其他参数
  form: {
    name: "name | Name",
    address: "Address",
    comments: "Comments",
    defaultAddress: "Default Address",
    returnAddress: "return address | Return Address",
    problemType: "Problem type",
    completePurchaseInformation: "Complete Purchase Information",
    orderTip: `If you have already submitted a support application, you can also | click here | to
    check the detailed status and records.`,
    uploadPhoto: "Upload a photo of the purchase receipt",
    rulesEnterTips: "Please enter the {params} ",
    rulesSelectTips: "Please select the {params} ",
    formatTips: "Wrong {params} format",
    uploadTips: "Please upload proof of purchase",
    uploadReceipt: "Upload a photo of the purchase receipt",
    formatError: "Format error",
    pictureSize: "Picture size exceeds {params}",
    processingResult: "Processing Result",
    findOrderScreenshot: "How to find my order screenshot?",
    helpTips: "What do you need help with?",
    processingMethod: "Processing method ",
    readPolicy: "Please read | return & refund policy | carefully",
    keepOriginalPackaging:
      "Are you keeping the outer packaging of the product?",
    whetherToKeep: "whether to keep",
    problemDescription: "Problem Description",
    problemDesc: "a description of the problem",
    uploadUp: "Upload up to {n} images",
    warrantyErrTips: "Sorry, your order has exceeded the warranty period.",
    warrantyErrTipsDesc: `If you still need support, please get in touch with
    {params} technical support. A technician will review the problem
    and give you a quote when you'd like.`,
    OutOfwarrantyTips: `This product has exceeded the warranty period cannot be eligible
    for support.`,
    accessoryTips:
      "This product is an accessory and cannot be eligible for support.",
    underMaintenanceTips: "The product is currently under maintenance.",
    clickViewDetail: "Click here to view details",
    warrantySuccessful: "Extended Warranty Successful",
    warrantySuccessfulDesc: `It is to confirm that 3 years of extend warranty has been honored to
    your <br />
    product associated with the provided order number. <br />The
    warranty will be effective until`,
    keepPackage: "Are you keeping the outer packaging of the product?",
    uploadDefect: "Upload visual evidence of the defect",
    yes: "Yes",
    no: "No",
    returnAndRefund: "Return and Refund",
    returnAndRefundFee: "Return and Refund-Shipping Fee",
    returnsAndReplace: "Return and Replace",
    returnsAndExchanges: "Returns and Exchanges",
    returnsAndExchangesFees: "Returns and Exchanges-Shipping Fee",
    noNeedToReturn: "No need to return - reissue",
    noNeedToReturnFee: "No need to return - reissue-Shipping Fee",
    rejection: "Rejection",
    reissueAccessory: "Reissue Accessory",
    alreadyExtendedWarranty: `Your order has already had an
    extended warranty`,
    outOfExtendedWarranty: "This product is out of extended warranty",
    extendedWarrantyFailTips: `This product has already applied for an extended warranty,cannot
    be re-applied again.`,
    contactCustomerService:
      "Please contact Amazon customer service. Thank you！",
    contactAmazon: "Contact Amazon",
    hasContactAmazon: "Has Amazon been contacted?",
    addressError: "Address parsing error, please re-enter.",
  },
  router: {
    home: "home",
    search: "search",
    findeOrder: "finde Order",
    findeScreenshot: "finde Screenshot",
    purchaseInformation: "Purchase Information",
    shippingInformation: "Shipping Information",
    shippingLabel: "Shipping Label",
    warrantyInfo: "Warranty Information",
    afterSalesInfo: "AfterSalesInfo",
    orderList: "OrderList",
    orderInformation: "Order Information",
    supportHistory: "Support History",
    orderDetails: "Order Details",
    evaluationRecordDetails: "Evaluation Record Details",
    editProfile: "Edit Profile",
    addressManagement: "Address Management",
    mailboxVerification: "Mailbox Verification",
    resetPassword: "Reset password",
  },
};
