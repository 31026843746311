import request from "@/plugins/axiosInstance";
import qs from "qs";
export function login(params) {
  return request({
    url: "/user/login",
    method: "post",
    data: params,
  });
}
export function tokenLogin(params) {
  return request({
    url: "/user/tokenLogin",
    method: "post",
    data: params,
  });
}
export function register(params) {
  return request({
    url: "/user/register",
    method: "post",
    data: params,
  });
}

export function activate(params) {
  return request({
    url: "/user/activate",
    method: "post",
    data: params,
  });
}
// 发送激活码邮件-账户不存在
export function sendActiveEmail(params) {
  return request({
    url: "/user/sendActiveEmail",
    method: "post",
    data: params,
  });
}
// 发送重置密码邮件

export function sendResetpwdemail(params) {
  return request({
    url: "/user/sendResetpwdemail",
    method: "post",
    data: params,
  });
}
export function resetPwd(params) {
  return request({
    url: "/user/resetPwd",
    method: "post",
    data: params,
  });
}

export function existUserAccount(params) {
  return request({
    url: "/user/existUserAccount",
    method: "post",
    data: qs.stringify(params),
  });
}
// 验证码
export function getCaptcha(query) {
  return request({
    url: "/user/captcha",
    params: query,
  });
}
// 校验激活码是否正确

export function checkResetPwdCodeCorrect(params) {
  return request({
    url: "/user/checkResetPwdCodeCorrect",
    method: "post",
    data: params,
  });
}
// 获取用户基本信息
export function getUserInfo(params) {
  return request({
    url: "/user/getUserInfo",
    method: "post",
    data: params,
  });
}
// 修改用户信息
export function saveUserInfo(params) {
  return request({
    url: "/user/saveUserInfo",
    method: "post",
    data: params,
  });
}

// 新增一条用户信息
export function createAddress(params) {
  return request({
    url: "/userAddress/create",
    method: "post",
    data: params,
  });
}
// 获取当前登录用户收货地址列表
export function getUserAllAddressList() {
  return request({
    url: "/userAddress/getUserAllAddressList",
    method: "get",
  });
}
// 删除地址信息
export function deleteAddress(params) {
  return request({
    url: "/userAddress/delete",
    method: "post",
    data: params,
  });
}
// 修改地址信息
export function updateAddress(params) {
  return request({
    url: "/userAddress/update",
    method: "post",
    data: params,
  });
}
