import { createI18n } from "vue-i18n";
import { getCache } from "@/utils/cache";
import app from "@/constants/app";
import elEn from "element-plus/lib/locale/lang/en";
import elDe from "element-plus/lib/locale/lang/de";
import elFr from "element-plus/lib/locale/lang/fr";
import elEs from "element-plus/lib/locale/lang/es";
import elIt from "element-plus/lib/locale/lang/it";
// // 本地语言包
import en from "./en"; //英语
import de from "./de"; //德语
import fr from "./fr"; //法语
import es from "./es"; //西班牙语
import it from "./it"; //意大利语

export const supportLangs = {
  en_US: {
    langName: "English",
    el: elEn,
    ...en,
  },
  de: {
    langName: "Deutsch",
    el: elDe,
    ...de,
  },
  fr: {
    langName: "Français",
    el: elFr,
    ...fr,
  },
  es: {
    langName: "Español",
    el: elEs,
    ...es,
  },
  it: {
    langName: "Italiano",
    el: elIt,
    ...it,
  },
};
// 定义消息
const messages = {
  en,
  de,
  fr,
  es,
  it,
};
/**
 * 取语言名称
 * @param lang
 * @returns
 */
export const getLangName = (lang) => {
  return supportLangs[lang]?.langName;
};
// 获取默认语言
export const getLocaleLang = () => {
  const lang = getCache("CacheLang", { isParse: false }, app.defaultLang);
  return lang;
};
// 默认为中文
const i18n = createI18n({
  legacy: false,
  locale: getLocaleLang(), // 存储在本地浏览器
  fallbackLocale: app.defaultLang,
  messages: supportLangs,
});

export default i18n;
