// 法语
export default {
  login: {
    login: "Connexion",
    userName: "Nom d'utilisateur",
    password: "Mot de passe",
    email: "E-mail",
    realEmail: "Email réel",
    forgotPassword: "Mot de passe oublié",
    verificationCode: "Code de vérification",
    resend: "Renvoyer",
    send: "Envoyer",
    sendCode: "Envoyer le code",
    rememberMe: "Se souvenir de moi",
    signIn: "Connectez-vous avec votre e-mail pour continuer",
    signInDesc:
      "Si vous n'avez pas encore de compte {params},| il sera automatiquement créé pour vous",
    warrantyTips: "À un pas du succès de la garantie prolongée !",
    warrantyDescTips: `Connectez-vous maintenant et activez
          votre compte pour un accès facile aux enregistrements de garantie, de retour et
          d'échange. De plus, vous aurez la chance de gagner des cartes-cadeaux !`,
    notReceivedCode: `Vous n'avez pas reçu le code de vérification ? Veuillez confirmer
          si l'adresse e-mail que vous avez saisie est valide et correcte. Si ce n'est pas le cas, | veuillez cliquer ici pour soumettre.`,
    emailTips: `Si vous n'avez pas reçu le code de vérification plusieurs fois,
          veuillez vérifier si l'adresse e-mail est correctement saisie ou
          changez pour une nouvelle adresse e-mail.`,
    passwordValidate: "Votre mot de passe doit comporter au moins 6 caractères",
    emailFormat: "Veuillez saisir le format e-mail correct",
    vaildFormat: "Veuillez saisir le format correct de {params}",
    createYour: "Créez votre",
    changeNewPassword: "Changer le nouveau mot de passe",
    changeNewPasswordDesc: `Votre mot de passe doit comporter au moins 8 caractères, <br />
          incluant des chiffres, des lettres majuscules et minuscules`,
  },
  forgetpwd: {
    title: "Rencontrez-vous des problèmes pour vous connecter ?",
    titleDesc: "Veuillez saisir votre adresse e-mail pour commencer.",
    verificationCode: "Saisissez les caractères figurant dans l'image",
    sendSuccessTips:
      "L'e-mail pour réinitialiser votre mot de passe a été envoyé dans votre boîte de réception. Veuillez vérifier vos e-mails.",
  },
  recordPage: {
    supportHistoryDesc:
      "Consultez le statut de vos demandes de support actuelles ou précédentes",
    timespan: "Période",
    status: "Statut",
    expectedTips1: "Prévu pour être terminé en 3 jours ouvrables",
    expectedTips2: "Il devrait être livré dans un délai de 10 jours ouvrables",
    expectedTips3: "Prévu pour être reçu dans un délai de 10 jours ouvrables",
    review: "Évaluez et gagnez des prix !",
    additionalRating: "Évaluation supplémentaire",
    product: "Produit",
    sku: "SKU",
    type: "Type",
    created: "Créé",
    initiated: "Initié",
    update: "Mise à jour",
    action: "Action",
  },
  recordDetail: {
    warrantyStatus: "État de la garantie",
    headerTips1: "Votre demande est en cours de traitement",
    headerTips2: "Votre demande de retour a été approuvée",
    headerTips3: "Votre paiement a été effectué avec succès",
    headerTips4: "Téléchargez l'étiquette et renvoyez votre produit",
    headerTips5: "Votre retour a été reçu",
    headerTips6: "Votre nouvel article a été expédié",
    headerTips7: "Votre garantie étendue a été finalisée",
    headerTips8: "Votre service après-vente a été terminé",
    headerTips9: "Votre paiement n'a pas encore été effectué.",
    underReviewDesc: `Nous avons bien reçu votre demande ! Nous commencerons à la traiter rapidement et vous recevrez des mises à 
    jour par e-mail dans les 3 jours ouvrables suivants. Si vous avez des questions, n'hésitez pas à nous contacter.`,
    returnsInProgressDesc: `Votre demande de retour a été approuvée ! Nous vous enverrons une étiquette de retour par le 
    système dans les 3 jours ouvrables. Veuillez la télécharger rapidement et renvoyer le produit.`,
    notSended: "Non envoyé?",
    haveSended: "Envoyé?",
    haveSendedDesc: `Votre remplacement sera traité une fois que nous aurons reçu votre retour. Veuillez être patient et n'hésitez pas à nous contacter si vous avez des questions ou des préoccupations. Nous sommes là pour vous aider! Vous pouvez cliquer sur le bouton ci-dessous pour suivre les informations d'expédition détaillées.`,
    resendDesc:
      "Votre nouveau produit est sur le point d'être expédié. Veuillez surveiller les mises à jour du statut d'expédition.",
    awaitingPaymentDesc: `Votre paiement est en attente. Veuillez le finaliser dans les 15 minutes, sinon la création de cet étiquette sera automatiquement annulée.`,
    trackShippingStatus: "Suivre l'état de l'expédition",
    proceedToPayment: "Procéder au paiement",
    successPay: "Vous avez complété le paiement avec succès.",
    returnsInProgressDesc1:
      "Veuillez remettre votre article dans son emballage d'origine (si applicable). Cliquez simplement sur le bouton ci-dessous pour télécharger et imprimer l'étiquette d'expédition. Ensuite, fixez-la simplement à votre colis et déposez-le dans n'importe quel point UPS.",
    returnsInProgressDesc2:
      "Votre remplacement sera traité une fois que nous aurons reçu votre retour. Veuillez être patient et n'hésitez pas à nous contacter si vous avez des questions ou des préoccupations. Nous sommes là pour vous aider ! Vous pouvez cliquer sur le bouton ci-dessous pour suivre les informations détaillées sur l'expédition.",
    exchangeInProgressDesc:
      "Votre remplacement sera traité dès réception de votre retour. Soyez patient et n'hésitez pas à nous contacter si vous avez des questions ou des préoccupations. Nous sommes là pour vous aider !",
    exchangeInProgressDesc1:
      "Votre retour est en cours de traitement au centre de retours. Votre nouvel article vous a été expédié, avec le numéro de suivi |, Si vous avez des questions, n'hésitez pas à nous contacter.",
    replacementInProgressDesc:
      "Votre nouvel article vous a été expédié, avec le numéro de suivi. Si vous avez des questions, n'hésitez pas à nous contacter.",
    refundsInProgressDesc:
      "Votre retour est en cours de traitement au centre de retours. Votre remboursement sera crédité sur votre compte d'origine dans un délai de 3 jours ouvrables. Veuillez surveiller votre compte.",
    ReturnOrRefundStatus: "État du retour ou du remboursement",
    reviewDetails: "Détails de l'évaluation",
    review: "Évaluation",
    request: "Demande",
    trackingNumber: "Numéro de suivi",
    requestSupportInformation: "Demander des informations de support",
    shippingTips:
      "Votre demande de support a été approuvée. | Veuillez compléter les informations d'expédition pour continuer.",
    downloadLabeltIPS:
      "{params} vous a envoyé une étiquette de retour, | Veuillez cliquer ici pour la télécharger | , puis imprimez et emballez votre article pour le retourner.",
    ProcessingMethodForPlatformReview:
      "Méthode de traitement pour l'examen de la plateforme",
    reviewInstructions: "Instructions d'évaluation",
    refundGiftCardType: "Type de carte cadeau de remboursement",
    refundGiftCardID: "Identifiant de carte cadeau de remboursement",
    refundGiftCardAmount: "Montant de la carte cadeau de remboursement",
    refundVoucherDesc:
      "Votre demande de remboursement ({ params }) a été traitée et le montant a été retourné sur votre compte de paiement. Veuillez vérifier votre compte pour confirmer la réception.",
    refundVoucher: "Bon de remboursement",
    refundGiftDesc:
      "Votre demande de remboursement est en cours de traitement, et le montant ({amount}) sera crédité sur votre {type} (ID {id}). Vous pouvez consulter le reçu de remboursement en cliquant sur le bouton « Voir les détails ».",
    refundAmount: "Montant du remboursement",
    giftCardType: "Type de carte cadeau",
    giftCardID: "ID de carte cadeau",
    amount: "Montant",
    gifts: "Cadeaux",
    reviewNotApproved: "Évaluation non approuvée",
    reviewContent: "Contenu de l'évaluation",
    rating: "Évaluation",
    photo: "Photo",
    ratingPhoto: "Photo de l'évaluation",
    trackingID: "ID de suivi",
    shippingCarrier: "Transporteur",
    purchaseDate: "Date d'achat",
    limitedDate: "Date d'expiration de la garantie limitée",
    stepDetailTips1: `Votre réclamation a été approuvée. Veuillez renvoyer le produit défectueux à vos frais. Utilisez l'icône "Estimation et obtenir une étiquette d'expédition" pour vérifier les frais d'expédition estimés. Si cela convient, vous pouvez ensuite acheter notre service d'expédition. N'hésitez pas à regarder la vidéo explicative si nécessaire.`,
    getLabelStepTitle: "Instructions pour le retour de votre colis :",
    getLabelStep1: "1. Payez le service d'expédition et obtenez l'étiquette. ",
    getLabelStep2: "2. Utilisez si possible la boîte d'emballage d'origine.",
    getLabelStep3: "3. Imprimez et collez l'étiquette sur la boîte.",
    getLabelStep4: "4. Déposez le colis chez UPS.",
    estimationAndGetShippingLabel:
      "Estimation et obtenir une étiquette d'expédition",
    searchOthers: "Rechercher d'autres",
    returnTips: ` Votre demande de retour a été approuvée ! Veuillez emballer votre article dans son emballage d'origine (si applicable). Cliquez simplement sur le bouton ci-dessous pour télécharger l'étiquette d'expédition et imprimez-la. Ensuite, apposez-la simplement sur votre colis et déposez-le dans n'importe quel point UPS.`,
    downloadLabel: "Télécharger l'étiquette",
    reasonForReturn: "Raison du retour",
    expired: "Expiré",
    underWarranty: "Sous garantie",
    qty: "Quantité",
  },
  evaluation: {
    exclusiveOpportunity: "Opportunité Exclusive",
    descriptions: `Ayez la chance de recevoir l'un des articles ci-dessous en cadeau ou<br />
    un bon de 20 $  <br /><b>Facile à participer:</b><br />
    Prenez 1 à 3 minutes pour remplir le formulaire, voir "voir les détails"`,
    winGiftCard: "Gagnez une carte cadeau de 20 $ à 50 $ !",
    freeTips:
      " Absolument gratuit - Pas de frais cachés, pas de carte de crédit requise !",
    feedbackTips:
      "Nous apprécions vraiment vos commentaires et serions ravis si vous pouviez consacrer quelques minutes pour partager votre expérience d'achat avec d'autres clients. Votre contribution est incroyablement précieuse pour nous !",
    steps: "3 étapes faciles pour gagner",
    steps1: "ÉTAPE 1 :",
    steps1Desc:
      "Prenez une photo de votre nouveau masseur {params}. Assurez-vous de prendre une belle photo pour un bon démarrage !",
    steps2: "ÉTAPE 2 :",
    steps2Desc:
      "Postez-la sur Facebook, Instagram, Twitter, YouTube, Tiktok avec le hashtag #{params}.",
    steps3: "ÉTAPE 3 :",
    steps3Desc:
      "Copiez l'URL de la publication et soumettez-la dans le formulaire ci-dessous.",
    storeGiftCard: "Carte cadeau de 30 $ du magasin {params}",
    pasteUrl: "Collez votre URL ici.",
    tips: "*N'oubliez pas de rendre votre publication publique pour que nous puissions la voir.",
    selectGiftTips: "Sélectionnez le cadeau que vous voulez pour",
    selectproduct: "Sélectionnez votre produit",
    selectproductDesc: "Sélectionnez les produits que vous souhaitez partager.",
    footerTips:
      "continuera à s'engager à créer un mode de vie sain pour nos clients. Merci de reconnaître nos produits.",
    fillInAddressTips:
      "Complétez votre adresse de livraison pour que vous puissiez recevoir votre cadeau dès que possible.",
    rateTitle: "Êtes-vous satisfait du produit?",
    submitSteps1: "Cliquez ici | pour partager votre expérience d'achat.",
    submitSteps2:
      "Prenez une capture d'écran du commentaire ou de l'évaluation que vous avez laissé et enregistrez-la.",
    submitSteps3:
      "Revenez sur cette page et | téléchargez la capture d'écran que vous venez d'enregistrer.",
    uploadScreenshot: "Téléchargez votre capture d'écran",
    copyUrl:
      "Copiez l'URL de la publication et soumettez-la dans le formulaire ci-dessous.",
    sharingSuccessTips: "Merci pour votre partage",
    sharingSuccessDesc:
      "Nous vous remercions pour votre formidable soutien. Votre soumission a été reçue et sera examinée dans un délai de 3 jours ouvrables. L'ID de récompense pour la carte-cadeau vous sera envoyé par e-mail peu de temps après la fin de l'examen. Vous pouvez également consulter l'historique de révision pour toute mise à jour.",
    sharingSuccessDesc1:
      "Nous nous excusons pour l'expérience produit négative que vous avez eue. Nous apporterons des améliorations pour vous offrir une meilleure expérience.",
    afterSalesRateTitle: "Veuillez évaluer ce service après-vente",
    placeRate: "Veuillez évaluer.",
  },
  logistics: {
    info: "Informations sur la logistique",
    orderNumber: "Numéro de commande logistique",
    shippingInfo: "Informations d'expédition complètes",
    orderTime: "Heure de la commande",
    company: "Société de logistique",
    downloadFaceSheet: "Télécharger la feuille de visage",
    shippingInfoDesc:
      "Suivez les étapes ci-dessous pour compléter les informations d'expédition",
    buyLable: "Acheter une étiquette sur {params}",
    packagingWeight: "Poids de l'emballage",
    completed: "Terminé",
    createTime: "Heure de création",
    shippingAddress: "Adresse de livraison",
    shippingInformation: "Informations d'expédition",
    retry: "Réessayer",
    price: "Prix",
    weight: "Poids",
    length: "Longueur",
    width: "Largeur",
    height: "Hauteur",
    lb: "lb",
    in: "po",
    tel: "Tél",
    finish: "Terminer",
    ups: "UPS",
    total: "Total (avec remise)",
    $: "$",
    postPaymentInstructions: "Instructions Après Paiement",
    postPaymentInstructionsDesc:
      "Veuillez revenir sur cette page pour télécharger l'étiquette d'expédition après avoir terminé votre paiement avec succès.",
    paymentAlerts: `Veuillez le compléter dans les 15 minutes, sinon la création de cet étiquette sera automatiquement annulée.`,
    payNow: "Payer maintenant",
    publishedCharges: "Frais publiés",
    payGetLabel: "Payer et obtenir une étiquette",
    telephoneNumber: "numéro de téléphone",
    uploadLabel: "Téléchargez votre propre étiquette",
    shipTo: "Vous devez expédier votre article à",
    createOrderTips:
      "Veuillez revenir sur cette page pour télécharger l'étiquette d'expédition après avoir réussi votre paiement.",
    paymentTips: "Veuillez effectuer le paiement sur la nouvelle page ouverte",
    paymentTipsDesc:
      "Veuillez ne pas fermer cette fenêtre avant que le paiement ne soit terminé. Après le paiement, veuillez cliquer sur le bouton ci-dessous en fonction de votre situation.",
    payTips:
      "Veuillez noter que les codes de réduction ou les cartes-cadeaux ne peuvent pas être utilisés pour cette commande. Sinon, la commande sera invalidée.",
    howGetShippingLabel: "Comment obtenez-vous votre étiquette d'expédition ?",
    priceTips:
      "*Ce prix est une estimation. Passer une commande vous garantira un tarif réduit.",
    payGetLabel: "Payer et obtenir l'étiquette d'expédition",
  },
  findOrder: {
    title: "Comment trouver votre numéro de commande Amazon ?",
    step1: `Étape 1 : Ouvrez la page d'accueil d'Amazon`,
    step2: `Étape 2 : Cliquez sur "Retours et commandes"`,
    step2Dec: `Vous devriez trouver cela en haut à droite, entre "Compte et listes" et votre panier.`,
    step3: `Étape 3 : Trouvez votre commande`,
    step3Dec: `Le numéro de commande est généralement composé de 17 chiffres.`,
  },
  findScreenshot: {
    title: "COMMENT TROUVER LA CAPTURE D'ÉCRAN DE VOTRE COMMANDE ?",
    step1:
      "1. Vos commandes (Si vous traitez avec des commandes plus anciennes, utilisez le filtre pour définir une plage horaire.)",
    step2: '2. Cliquez sur "Voir les détails de la commande"',
    step3: '3. Cliquez sur "Voir la facture"',
    step4: "4. Facture",
  },
  faq: {
    title: "Foire aux questions",
    faqDesc:
      "Avant de demander de l'aide, vous pouvez rapidement vérifier votre problème à travers la FAQ",
    faqTips:
      "Si vous avez lu la FAQ mais que vous ne parvenez toujours pas à trouver une solution à votre problème, | Cliquez ici",
  },
  theme: {
    all: "Tout",
    addAddress: "Ajouter une adresse",
    back: "Retour",
    collapse: "Réduire",
    continue: "Continuer",
    checking: "Vérification...",
    cancel: "Annuler",
    canceled: "Annulé",
    delete: "Supprimer",
    download: "Télécharger",
    edit: "Éditer",
    editAddress: "Modifier l'adresse",
    receivingAddress: "Adresse de réception",
    fullname: "Nom complet",
    nickname: "Pseudo",
    phone: "Téléphone",
    zipcode: "Code postal",
    country: "Pays/Région",
    state: "État",
    set: "Définir",
    city: "Ville",
    search: "Recherche",
    more: "Plus",
    next: "Suivant",
    success: "Succès",
    history: "L'histoire",
    save: "Enregistrer",
    ok: "OK",
    others: "Autres",
    logout: "Se déconnecter",
    login: "Connexion",
    notification: "Notification",
    firstName: "Prénom",
    lastName: "Nom de famille",
    changePwd: "Changer votre mot de passe",
    currentPwd: "Mot de passe actuel",
    newPwd: "Nouveau mot de passe",
    confirmNewPwd: "Confirmer le nouveau mot de passe",
    lastUpdated: "Dernière mise à jour",
    logoutTips: "Veuillez confirmer si vous souhaitez vous déconnecter?",
    viewDetails: "Voir les détails",
    submit: "Soumettre",
    systemName: "Centre de services en ligne",
    setAddress: "Définir comme adresse par défaut",
    systemDesc: "Créez et gérez vos demandes de support et de garantie",
    requestSupport: "Demander du support",
    requestSupportDesc:
      "Laissez-nous vous aider avec vos problèmes de remboursement, de retour et de remplacement",
    extendedWarranty: "Garantie prolongée",
    refundsAndReturns: "Remboursements et retours",
    submitApplication: "Soumettre une demande",
    reviewPending: "Examen en attente",
    finished: "Terminé",
    returnPending: "Retour en attente",
    returnInProgress: "Retour en cours",
    refundInProgress: "Remboursement en cours",
    reshipPending: "Réexpédition en attente",
    reshipInProgress: "Réexpédition en cours",
    replacePending: "Remplacement en attente",
    replaceInProgress: "Remplacement en cours",
    replacementInProgress: "Remplacement en cours",
    close: "Fermer",
    toShip: "À expédier",
    inTransit: "En transit",
    reviewRejected: "Examen rejeté",
    amazonGifts: "Cadeaux Amazon",
    shopifyGifts: "Cadeaux Shopify",
    walmartGifts: "Cadeaux Walmart",
    lazadaGifts: "Cadeaux Lazada",
    shopeeGifts: "Cadeaux Shopee",
    last30Days: "30 derniers jours",
    last90Days: "90 derniers jours",
    lastYear: "L'année dernière",
    thisYear: "Cette année",
    whatsAppContact: "Contact WhatsApp",
    forFill: "À remplir",
    goTodownload: "Aller au téléchargement",
    successfully: "Avec succès!",
    confirmResetPwd: "Veuillez confirmer le mot de passe",
    shareExperience: "Partagez votre expérience",
    uploadScreenshot: "Veuillez télécharger votre capture d'écran",
    userInfoChangeSuccess:
      "Les informations utilisateur ont été modifiées avec succès",
    connectionErr: "Vérifiez votre connexion Internet et réessayez",
    mustBeJPGFormat: "L'image d'avatar doit être au format JPG!",
    inconsistentPasswords:
      "Les mots de passe saisis deux fois sont incohérents",
    successfulRegistration:
      "L'inscription a réussi. Le code d'activation a été envoyé à la boîte aux lettres",
    ownShippingCostsTips:
      "Nous vous remercions de nous avoir signalé cela. Conformément à notre politique de garantie, veuillez retourner l'article défectueux à notre entrepôt à vos frais. Dès réception, nous vous enverrons rapidement un remplacement ou un remboursement selon votre demande. Pour faciliter votre retour, nous créerons une étiquette de retour et obtiendrons un devis. Vous pouvez également utiliser le transporteur de votre choix, mais veuillez fournir le numéro de suivi pour le suivi. Merci de votre coopération.",
    shippingCostsTips:
      "Nous vous remercions de nous avoir informés du problème. Nous avons créé une étiquette d'expédition prépayée pour votre commodité. Veuillez retourner l'appareil défectueux et dès réception, nous vous enverrons rapidement un remplacement ou un remboursement selon votre demande. Merci!",
    feedbackTips:
      "Avez-vous quelques minutes à nous accorder pour nous donner votre avis ? Nous recherchons des suggestions pour améliorer encore nos services. Nous apprécions votre temps !",
    extendedWarrantyDesc: "Vérifiez votre couverture et la garantie étendue",
    supportHistory: "Historique du support",
    supportHistoryDesc:
      "Connectez-vous pour consulter l'état des demandes existantes ou passées",
    APPDesc:
      "Trouvez et comparez des produits, obtenez de l'aide et connectez-vous avec",
    needMoreHelp: "Besoin de plus d'aide ?",
    contactSupport: "Contacter le support",
    whereToPurchase: "Où acheter",
    systemAutoCorrected: "Système auto-corrected:",
    purchasePlatform: "Plateforme d'achat",
    orderNumberFormat: "Format du numéro de commande:",
    copyright: "Droits d'auteur © { params } Corporation V2.3.0",
    addressManagementDesc:
      "Modifiez, ajoutez ou supprimez votre adresse de livraison.",
    personalInformation: "Informations personnelles",
    personalInformationDesc:
      "Gérez vos informations personnelles, y compris le mot de passe et les adresses e-mail où vous pouvez être contacté.",
    quickOptionsTips:
      "Vous pouvez choisir une commande existante pour initier le support.",
    importantNotice: "AVIS IMPORTANT",
    orderProblemsTips1: "La période de garantie a expiré.",
    orderProblemsTips2:
      "Désolé, il s'agit d'une commande de pièces et elle ne peut pas prolonger la garantie ou le service après-vente.",
    orderProblemsTips3:
      "Désolé, le produit d'occasion ne peut pas bénéficier d'un support et ne peut pas être étendu pour la garantie.",
    uploadSuccessful: "Téléchargement réussi",
    submitSuccessfully: "Soumis avec succès",
    submitSuccessfullyDesc:
      "Nous examinerons votre commande dans les 3 jours ouvrables. Vous pouvez également vérifier la progression en consultant l'historique du support.",
    findWalmartOrder: "Comment trouver le numéro de commande Walmart ?",
    findWalmartOrder1: "1. Connectez-vous à votre compte Walmart.",
    findWalmartOrder2: "2. Sélectionnez 'Historique des achats'.",
    findWalmartOrder3:
      "3. Faites défiler jusqu'à la commande souhaitée et trouvez le numéro de commande.",
    findShopifyOrder: `Vous recevrez un numéro de commande par e-mail à chaque fois que vous effectuerez un achat sur www.{params}.com. Veuillez vérifier votre boîte de réception pour la confirmation de commande. Si vous avez reçu {params1} produits en cadeau, veuillez nous fournir les coordonnées de la personne qui les a achetés (nom ou adresse e-mail), et nous vous aiderons à vérifier les détails.`,
    delAddressInfo: "Supprimer les informations d'adresse ?",
    pwdError: "Erreur de saisie du mot de passe actuel",
    newPwdAgain: "Veuillez entrer à nouveau le nouveau mot de passe",
    twoInputsDontMatch: "Les deux saisies ne correspondent pas !",
  },
  search: {
    requestSupportDesc:
      "Suivez les étapes ci-dessous pour demander un remboursement, un retour et un remplacement.",
    findOrderTips: "Comment trouver mon numéro de commande ?",
    findOrderTips1: "Si vous ne parvenez pas à trouver le numéro de commande, ",
    clickHere: "veuillez cliquer ici",
    checkProducts: "Vérifier les produits",
    clear: "Effacer",
    othersPlatform: "Autres plates-formes",
    purchaseOrderNo: "Numéro de commande ",
    orderNumber: "Numéro de commande",
    newSupport:
      "Ou vous pouvez entrer un numéro de commande pour initier un nouveau support.",
    purchasePlatformTips: "Veuillez sélectionner la plateforme d'achat",
    orderNotFoundTips:
      "Désolé, nous n'avons pas pu trouver votre numéro de commande.",
    importantNotice:
      "AVIS IMPORTANT : Seuls les produits { params }® sont éligibles au support de garantie, sous réserve des conditions de garantie limitée de { params }. Veuillez noter que dans le cadre des efforts continus de { params } pour prévenir la fraude sur le marché, si le produit que vous soumettez pour un support de garantie est jugé être reconditionné ou autrement frauduleux,{ params } se réserve le droit de conserver le produit et/ou de détruire un tel produit selon le cas.",
    contactCustomer:
      "Il y a un problème non technique avec votre demande, pour le résoudre vous devrez contacter le service client de {params} à {mail}.",
  },
  searchTips: {
    orderDoesNotExist: `Veuillez vérifier si vous avez saisi le bon numéro de commande. Si vous avez acheté votre produit hors ligne, veuillez télécharger votre preuve d'achat, et nous la enregistrerons pour vous.`,
    OrdersUnderReview: `Nous nous excusons pour tout désagrément. Votre commande actuelle est en cours de révision. Nous la traiterons rapidement et vous fournirons les dernières mises à jour par e-mail. Vous pouvez également vérifier le statut actuel dans votre historique des commandes. Merci pour votre compréhension.`,
    dateOfPurchase: "Date d'achat",
    warrantyExpiration: " Date d'expiration de la garantie",
    orderItemTips: "La commande contient les articles suivants.",
    skuName: "Nom du SKU",
    checkHistory: "Vérifier l'historique",
    extendedWarrantyForThisOrder: "Garantie étendue pour cette commande",
    uploadPurchaseProof: "Téléchargez votre preuve d'achat",
    uploadPurchaseProofTips: `Veuillez fournir des informations supplémentaires sur votre achat pour enregistrer la garantie.`,
    extendedWarrantySuccessTips: `Nous sommes heureux de vous informer que votre produit, associé au numéro de commande fourni, bénéficie d'une garantie étendue de 3 ans. Cette garantie restera valide jusqu'à |. Merci d'avoir choisi notre produit !`,
    submissionReviewComplete: `Nous traiterons votre demande dans les 3 jours ouvrables. et nous vous tiendrons informé par e-mail de l'avancement. | Nous avons bien reçu votre demande ! Nous la traiterons rapidement, et vous pouvez vous attendre à des mises à jour par e-mail dans les 3 jours ouvrables suivants.`,
    completeSupportInformation: "Informations de support complètes",
    fillTips:
      "Veuillez remplir les informations suivantes pour enregistrer la garantie avec succès.",
    whatWouldYouLikeToDo: "Que souhaitez-vous faire ?*",
    itemCondition: "État de l'article*",
    withOriginalPackaging: "Avec emballage d'origine",
    withoutOriginalPackaging: "Sans emballage d'origine",
    reasonForReturn: "Pourquoi renvoyez-vous cet article ?*",
    isExtendedWarranty: "Votre commande bénéficie déjà d'une garantie étendue",
    isGreaterThan90Days:
      "Désolé, la date d'achat de votre commande dépasse 90 jours.",
    isGreaterThan90DaysDesc:
      "Nous sommes désolés, mais il semble que votre commande a été passée il y a plus de 90 jours. Malheureusement, conformément à notre politique de garantie et de retour, nous ne pouvons pas prolonger la garantie pour votre commande à ce moment.",
    isInAfterSalesDesc: `Désolé, votre commande est actuellement en cours de traitement pour le service après-vente, nous ne pouvons donc pas accepter les soumissions en double. Vous pouvez également vérifier le statut actuel dans votre historique des commandes. Merci pour votre compréhension.`,
    isOvertime: "Désolé, votre commande a dépassé la période de garantie.",
    isOvertimeDesc1:
      "Votre commande a atteint la fin de sa durée de service et ne peut plus être prolongée. Si vous avez des questions, n'hésitez pas à nous contacter.",
    isOvertimeDesc2:
      "Votre commande a atteint la fin de sa durée de service et ne peut plus être remboursée ou remplacée.",
    noOrdersExist: "Désolé, la commande actuelle est en cours de révision.",
  },
  form: {
    name: "Nom",
    address: "Adresse",
    comments: "Commentaires",
    defaultAddress: "Adresse par défaut",
    returnAddress: "Adresse de retour",
    problemType: "Type de problème",
    completePurchaseInformation: "Compléter les informations d'achat",
    orderTip:
      "Si vous avez déjà soumis une demande de support, vous pouvez également | cliquer ici | pour vérifier le statut détaillé et les enregistrements.",
    uploadPhoto: "Télécharger une photo du reçu d'achat",
    rulesEnterTips: "Veuillez saisir {params}",
    rulesSelectTips: "Veuillez sélectionner {params}",
    formatTips: "Format {params} incorrect",
    uploadTips: "Veuillez télécharger une preuve d'achat",
    uploadReceipt: "Télécharger une photo du reçu d'achat",
    formatError: "Erreur de format",
    pictureSize: "Taille de l'image dépasse {params}",
    processingResult: "Résultat du traitement",
    findOrderScreenshot: "Comment trouver la capture d'écran de ma commande?",
    helpTips: "De quoi avez-vous besoin d'aide?",
    processingMethod: "Méthode de traitement",
    readPolicy:
      "Veuillez lire | la politique de retour et de remboursement | attentivement",
    keepOriginalPackaging: "Conservez-vous l'emballage d'origine du produit?",
    whetherToKeep: "si conserver",
    problemDescription: "Description du problème",
    problemDesc: "une description du problème",
    uploadUp: "Télécharger jusqu'à {n} images",
    warrantyErrTips: "Désolé, votre commande a dépassé la période de garantie.",
    warrantyErrTipsDesc:
      "Si vous avez encore besoin d'assistance, veuillez contacter le support technique de {params}. Un technicien examinera le problème et vous fournira un devis si vous le souhaitez.",
    OutOfwarrantyTips:
      "Ce produit a dépassé la période de garantie et n'est pas éligible à une assistance.",
    accessoryTips:
      "Ce produit est un accessoire et n'est pas éligible à une assistance.",
    underMaintenanceTips: "Le produit est actuellement en maintenance.",
    clickViewDetail: "Cliquez ici pour voir les détails",
    warrantySuccessful: "Extension de garantie réussie",
    warrantySuccessfulDesc:
      "Nous confirmons par la présente qu'une extension de garantie de 3 ans a été accordée à votre produit associé au numéro de commande fourni.<br /> La garantie sera valide jusqu'à",
    keepPackage: "Gardez-vous l'emballage extérieur du produit?",
    uploadDefect: "Téléchargez la preuve visuelle du défaut",
    yes: "Oui",
    no: "Non",
    returnAndRefund: "Retour et Remboursement",
    returnAndRefundFee: "Retour et Remboursement - Frais d'Expédition",
    returnsAndExchanges: "Retours et Échanges",
    returnsAndExchangesFees: "Retours et Échanges - Frais d'Expédition",
    noNeedToReturn: "Pas besoin de retour - réémission",
    noNeedToReturnFee: "Pas besoin de retour - réémission - Frais d'Expédition",
    rejection: "Rejet",
    reissueAccessory: "Réémission Accessoire",
    alreadyExtendedWarranty:
      "Votre commande bénéficie déjà d'une garantie prolongée.",
    outOfExtendedWarranty: "Ce produit est en dehors de la garantie prolongée.",
    extendedWarrantyFailTips:
      "Ce produit a déjà demandé une garantie prolongée et ne peut pas être demandé à nouveau.",
    contactCustomerService:
      "Veuillez contacter le service client d'Amazon. Merci !",
    contactAmazon: "Contacter Amazon",
    hasContactAmazon: "Amazon a-t-il été contacté ?",
    addressError:
      "Erreur de traitement de l'adresse, veuillez la saisir à nouveau.",
  },
  router: {
    home: "Accueil",
    search: "Recherche",
    findeOrder: "Trouver la commande",
    findeScreenshot: "Trouver la capture d'écran",
    purchaseInformation: "Informations d'achat",
    shippingInformation: "Informations d'expédition",
    shippingLabel: "Étiquette d'expédition",
    warrantyInfo: "Informations sur la garantie",
    afterSalesInfo: "Informations sur le service après-vente",
    orderList: "Liste des commandes",
    orderInformation: "Informations sur la commande",
    supportHistory: "Historique du support",
    orderDetails: "Détails de la commande",
    evaluationRecordDetails: "Détails de l'enregistrement de l'évaluation",
    editProfile: "Modifier le profil",
    addressManagement: "Gestion des adresses",
    mailboxVerification: "Vérification de la boîte aux lettres",
    resetPassword: "Réinitialiser le mot de passe",
  },
};
