// 西班牙语
export default {
  login: {
    login: "Inicio de sesión",
    userName: "Nombre de usuario",
    password: "Contraseña",
    email: "Correo electrónico",
    realEmail: "Correo electrónico real",
    forgotPassword: "Olvidé mi contraseña",
    verificationCode: "Código de verificación",
    resend: "Reenviar",
    send: "Enviar",
    sendCode: "Enviar código",
    rememberMe: "Recuérdame",
    signIn: "Inicia sesión con tu correo electrónico para continuar",
    signInDesc:
      "Si aún no tienes una cuenta {params}, se creará automáticamente una para ti",
    warrantyTips: "¡A solo un paso del éxito de la garantía extendida!",
    warrantyDescTips: `¡Inicia sesión ahora y activa
          tu cuenta para acceder fácilmente a los registros de garantía, devolución y
          cambio. Además, tendrás la oportunidad de ganar tarjetas de regalo!`,
    notReceivedCode: `¿No recibiste el código de verificación? Confirma
          si la dirección de correo electrónico que ingresaste es válida y correcta. Si no lo es, |haz clic aquí para enviar otro.`,
    emailTips: `Si no has recibido el código de verificación varias veces,
          verifica si la dirección de correo electrónico está correctamente ingresada o
          cambia a una nueva dirección de correo electrónico.`,
    passwordValidate: "Tu contraseña debe tener al menos 6 caracteres",
    emailFormat: "Por favor ingresa el formato de correo electrónico correcto",
    vaildFormat: "Por favor, introduzca el formato correcto de {params}",
    createYour: "Crea tu",
    changeNewPassword: "Cambiar nueva contraseña",
    changeNewPasswordDesc: `Tu contraseña debe tener al menos 8 caracteres, <br />
          incluyendo números, letras mayúsculas y minúsculas`,
  },
  forgetpwd: {
    title: "¿Estás teniendo problemas para iniciar sesión?",
    titleDesc: "Por favor, ingresa tu correo electrónico para comenzar.",
    verificationCode: "Escribe los caracteres de la imagen",
    sendSuccessTips:
      "El correo electrónico para restablecer tu contraseña ha sido enviado a tu bandeja de entrada. Por favor, revisa tu correo electrónico.",
  },
  recordPage: {
    supportHistoryDesc:
      "Vea el estado de sus solicitudes de soporte actuales o anteriores",
    timespan: "Periodo de tiempo",
    status: "Estado",
    expectedTips1: "Se espera que se complete en 3 días laborables",
    expectedTips2:
      "Se espera que se entregue en un plazo de 10 días laborables",
    expectedTips3: "Se espera recibirlo en un plazo de 10 días laborables",
    review: "¡Evalúa y gana premios!",
    additionalRating: "Valoración adicional",
    product: "Producto",
    sku: "SKU",
    type: "Tipo",
    created: "Creado",
    initiated: "Iniciado",
    update: "Actualizar",
    action: "Acción",
  },
  recordDetail: {
    warrantyStatus: "Estado de la garantía",
    headerTips1: "Su solicitud está siendo procesada",
    headerTips2: "Su solicitud de devolución ha sido aprobada",
    headerTips3: "Ha completado el pago con éxito",
    headerTips4: "Descargue la etiqueta y envíe su producto de vuelta",
    headerTips5: "Su devolución ha sido recibida",
    headerTips6: "Su nuevo artículo ha sido enviado",
    headerTips7: "Su garantía extendida ha sido completada",
    headerTips8: "Su servicio posventa ha sido completado",
    headerTips9: "Su pago aún no ha sido completado.",
    underReviewDesc: `¡Hemos recibido su solicitud! Comenzaremos a procesarla de inmediato y recibirá actualizaciones por correo electrónico en los
     próximos 3 días hábiles. Si tiene alguna pregunta, no dude en contactarnos.`,
    returnsInProgressDesc: ` ¡Su solicitud de devolución ha sido aprobada! Le enviaremos una etiqueta de devolución a través del sistema en un
     plazo de 3 días hábiles. Asegúrese de descargarla rápidamente y enviar el producto de vuelta.`,
    notSended: "¿No enviado?",
    haveSended: "¿Enviado?",
    haveSendedDesc: `Su reemplazo será procesado una vez que recibamos su devolución. Por favor, tenga paciencia y no dude en ponerse en contacto con nosotros si tiene alguna pregunta o inquietud. ¡Estamos aquí para ayudar! Puede hacer clic en el botón de abajo para rastrear la información detallada del envío.`,
    resendDesc:
      "Su nuevo producto está a punto de ser enviado. Por favor, manténgase atento a las actualizaciones del estado del envío.",
    awaitingPaymentDesc: `Su pago está pendiente. Por favor, complételo dentro de los 15 minutos, de lo contrario la creación de esta etiqueta se cancelará automáticamente.`,
    trackShippingStatus: "Rastrear estado del envío",
    proceedToPayment: "Proceder al pago",
    successPay: "Has completado el pago con éxito.",
    returnsInProgressDesc1:
      "Por favor, empaquete su artículo en su embalaje original (si corresponde). Simplemente haga clic en el botón de abajo para descargar e imprimir la etiqueta de envío. Después, simplemente fíjela a su paquete y entréguelo en cualquier ubicación de UPS. ",
    returnsInProgressDesc2:
      "Su reemplazo se procesará una vez que hayamos recibido su devolución. Por favor, tenga paciencia y no dude en contactarnos si tiene alguna pregunta o inquietud. ¡Estamos aquí para ayudar! Puede hacer clic en el botón de abajo para rastrear la información detallada del envío.",
    exchangeInProgressDesc:
      "Su reemplazo se procesará una vez que recibamos su devolución. Por favor, sea paciente y no dude en contactarnos si tiene preguntas o inquietudes. ¡Estamos aquí para ayudar!",
    exchangeInProgressDesc1:
      "Su devolución está siendo procesada en el centro de devoluciones. Su nuevo artículo ha sido enviado para usted, con el número de seguimiento|, Si tiene preguntas, no dude en contactarnos.",
    replacementInProgressDesc:
      "Su nuevo artículo ha sido enviado para usted, con el número de seguimiento|, Si tiene preguntas, no dude en contactarnos.",
    refundsInProgressDesc:
      "Su devolución está siendo procesada en el centro de devoluciones. El reembolso se abonará en su cuenta original dentro de los 3 días hábiles. Por favor, esté atento a su cuenta.",
    ReturnOrRefundStatus: "Estado de la devolución o el reembolso",
    reviewDetails: "Detalles de la revisión",
    review: "Revisión",
    request: "Solicitud",
    trackingNumber: "Número de seguimiento",
    requestSupportInformation: "Solicitar información de soporte",
    shippingTips:
      "Su solicitud de soporte ha sido aprobada. | Complete la información de envío para continuar.",
    downloadLabeltIPS:
      "{params} le ha enviado una etiqueta de devolución, | Haga clic aquí para descargarla | , luego imprima y empaque su artículo para devolverlo.",
    ProcessingMethodForPlatformReview:
      "Método de procesamiento para la revisión de la plataforma",
    reviewInstructions: "Instrucciones de revisión",
    refundGiftCardType: "Tipo de tarjeta regalo para el reembolso",
    refundGiftCardID: "ID de tarjeta regalo para el reembolso",
    refundGiftCardAmount: "Importe de la tarjeta regalo para el reembolso",
    refundVoucherDesc:
      "Su solicitud de reembolso ({ params }) ha sido procesada y el monto ha sido devuelto a su cuenta de pago. Por favor, verifique su cuenta para confirmar la recepción.",
    refundVoucher: "Vale de reembolso",
    refundGiftDesc:
      "Su solicitud de reembolso está siendo procesada, y el monto ({amount}) se acreditará a su {type} (ID {id}). Puede ver el recibo de reembolso haciendo clic en el botón « Ver detalles ».",
    refundAmount: "Importe del reembolso",
    giftCardType: "Tipo de tarjeta regalo",
    giftCardID: "ID de tarjeta regalo",
    amount: "Cantidad",
    gifts: "Regalos",
    reviewNotApproved: "Revisión no aprobada",
    reviewContent: "Contenido de la revisión",
    rating: "Calificación",
    photo: "Foto",
    ratingPhoto: "Foto de la calificación",
    trackingID: "ID de seguimiento",
    shippingCarrier: "Transportista",
    purchaseDate: "Fecha de compra",
    limitedDate: "Fecha de vencimiento de la garantía limitada",
    stepDetailTips1: `Su reclamación ha sido aprobada. Por favor, devuelva el producto defectuoso por su cuenta. Utilice el icono "Estimación y Obtención de la Etiqueta de Envío" para verificar el costo de envío estimado. Si es adecuado, puede comprar nuestro servicio de envío. No dude en ver el video instructivo si es necesario.`,
    getLabelStepTitle: "Instrucciones para devolver su paquete: ",
    getLabelStep1: "1. Pague el servicio de envío y obtenga la etiqueta.",
    getLabelStep2: "2. Utilice la caja de embalaje original si es posible.",
    getLabelStep3: "3. Imprima y pegue la etiqueta en la caja.",
    getLabelStep4: "4. Deje el paquete en UPS.",
    estimationAndGetShippingLabel:
      "Estimación y Obtención de la Etiqueta de Envío",
    searchOthers: "Buscar otros",
    returnTips: `¡Su solicitud de devolución ha sido aprobada! Por favor, embale su artículo de nuevo en su embalaje original (si corresponde). Simplemente haga clic en el botón a continuación para descargar la etiqueta de envío e imprímala. Después, simplemente adhiérala a su paquete y déjela en cualquier punto de UPS.`,
    downloadLabel: "Descargar etiqueta",
    reasonForReturn: "Motivo de la devolución",
    expired: "Expirado",
    underWarranty: "Bajo garantía",
    qty: "Cantidad",
  },
  evaluation: {
    exclusiveOpportunity: "Oportunidad Exclusiva",
    descriptions: `Obtén la oportunidad de recibir uno de los siguientes regalos o <br />
    cupón de $20 <br /><b>Fácil de participar:</b><br />
    Toma de 1 a 3 minutos para completar el formulario, consulta "ver detalles".`,
    winGiftCard: "¡Gana una tarjeta de regalo de $20 a $50!",
    freeTips:
      " ¡Absolutamente gratis - Sin tarifas ocultas, no se requiere tarjeta de crédito!",
    feedbackTips:
      "Valoramos sinceramente tus comentarios y estaríamos encantados si pudieras dedicar unos minutos para compartir tu experiencia de compra con otros clientes. ¡Tu opinión es increíblemente valiosa para nosotros!",
    steps: "3 Pasos Fáciles Para Ganar",
    steps1: "PASO 1:",
    steps1Desc:
      "Toma una foto de tu nuevo masajeador {params}. ¡Asegúrate de obtener una excelente toma para un buen comienzo!",
    steps2: "PASO 2:",
    steps2Desc:
      "Publícalo en Facebook, Instagram, Twitter, YouTube, Tiktok con el hashtag #{params}.",
    steps3: "PASO 3:",
    steps3Desc:
      "Copia la URL de la publicación y envíala en el formulario a continuación.",
    storeGiftCard: "Tarjeta de regalo de $30 de la tienda {params}",
    pasteUrl: "Pega tu URL aquí.",
    tips: "*Recuerda configurar tu publicación como Pública para que podamos verla.",
    selectGiftTips: "Selecciona el regalo que deseas para",
    selectproduct: "Selecciona tu producto",
    selectproductDesc: "Selecciona los productos que deseas compartir.",
    footerTips:
      "continuará comprometiéndose a crear un estilo de vida saludable para nuestros clientes. Gracias por reconocer nuestros productos.",
    fillInAddressTips:
      "Completa tu dirección de envío para que puedas recibir tu regalo lo antes posible.",
    rateTitle: "¿Estás satisfecho con el producto?",
    submitSteps1: "Haga clic aquí | para compartir tu experiencia de compra.",
    submitSteps2:
      "Toma una captura de pantalla del comentario o la valoración que dejaste y guárdala.",
    submitSteps3:
      "Regrese a esta página y | cargue la captura de pantalla que acaba de guardar.",
    uploadScreenshot: "Cargar tu captura de pantalla",
    copyUrl:
      "Copia la URL de la publicación y envíala en el formulario a continuación.",
    sharingSuccessTips: "Gracias por compartir",
    sharingSuccessDesc:
      "Agradecemos tu gran apoyo. Tu presentación ha sido recibida y será revisada en un plazo de 3 días hábiles. El ID de premio para la tarjeta regalo se te enviará por correo electrónico poco después de que completemos la revisión. También puedes consultar el historial de revisiones para ver cualquier actualización.",
    sharingSuccessDesc1:
      "Lamentamos la experiencia negativa del producto que has tenido. Realizaremos mejoras para ofrecerte una mejor experiencia.",
    afterSalesRateTitle: "Por favor califique este servicio posventa",
    placeRate: "Por favor califique.",
  },
  logistics: {
    info: "Información Logística",
    orderNumber: "Número de orden logística",
    shippingInfo: "Información de envío completa",
    orderTime: "Hora del pedido",
    company: "Empresa de logística",
    downloadFaceSheet: "Descargar hoja frontal",
    shippingInfoDesc:
      "Siga los pasos a continuación para completar la información de envío",
    buyLable: "Comprar una etiqueta en {params}",
    packagingWeight: "Peso del embalaje",
    completed: "Completado",
    createTime: "Hora de creación",
    shippingAddress: "Dirección de envío",
    shippingInformation: "Información de envío",
    retry: "Reintentar",
    price: "Precio",
    weight: "Peso",
    length: "Longitud",
    width: "Ancho",
    height: "Altura",
    lb: "lb",
    in: "pulgadas",
    tel: "Tel",
    finish: "Finalizar",
    ups: "UPS",
    total: "Total (con descuento)",
    $: "$",
    postPaymentInstructions: "Instrucciones Post-Pago",
    postPaymentInstructionsDesc:
      "Por favor, regrese a esta página para descargar la etiqueta de envío después de completar exitosamente su pago.",
    paymentAlerts: `Por favor, complételo en 15 minutos, de lo contrario la creación de esta etiqueta se cancelará automáticamente.`,
    payNow: "Pagar ahora",
    publishedCharges: "Cargos publicados",
    payGetLabel: "Pagar y obtener etiqueta",
    telephoneNumber: "número de teléfono",
    uploadLabel: "Cargue su propia etiqueta",
    shipTo: "Debe enviar su artículo a",
    createOrderTips:
      "Por favor regrese a esta página para descargar la etiqueta de envío después de completar con éxito su pago.",
    paymentTips: "Por favor complete el pago en la página recién abierta",
    paymentTipsDesc:
      "Por favor, no cierre esta ventana antes de que se complete el pago. Después del pago, haga clic en el botón de abajo según su situación.",
    payTips:
      "Tenga en cuenta que los códigos de descuento o las tarjetas de regalo no se pueden utilizar para este pedido. De lo contrario, el pedido será invalidado.",
    howGetShippingLabel: "¿Cómo obtienes tu etiqueta de envío?",
    priceTips:
      "*Este precio es una estimación. Realizar un pedido asegurará una tarifa con descuento.",
    payGetLabel: "Pagar y obtener la etiqueta de envío",
  },
  findOrder: {
    title: `Cómo encontrar tu número de pedido en Amazon`,
    step1: `Paso 1: Abre la página de inicio de Amazon`,
    step2: `Paso 2: Haz clic en "Devolver o reemplazar productos"`,
    step2Dec: `Deberías encontrar esto en la esquina superior derecha, entre "Cuenta y listas" y tu carrito.`,
    step3: `Paso 3: Encuentra tu pedido`,
    step3Dec: `El número de pedido generalmente está compuesto por 17 dígitos.`,
  },
  findScreenshot: {
    title: "¿CÓMO ENCONTRAR LA CAPTURA DE PANTALLA DE SU PEDIDO?",
    step1:
      "1. Sus pedidos (Si está tratando con pedidos más antiguos, use el filtro para establecer un rango de tiempo.)",
    step2: '2. haga clic en "Ver detalles del pedido"',
    step3: '3. haga clic en "Ver factura"',
    step4: "4. Factura",
  },
  faq: {
    title: "Preguntas frecuentes",
    faqDesc:
      "Antes de solicitar ayuda, puede revisar rápidamente su problema a través de las preguntas frecuentes",
    faqTips:
      "Si ha leído las preguntas frecuentes pero aún no puede encontrar una solución a su problema, | Haga clic aquí",
  },
  theme: {
    all: "Todo",
    addAddress: "Agregar dirección",
    back: "Atrás",
    collapse: "Colapso",
    continue: "Continuar",
    checking: "Comprobando...",
    cancel: "Cancelar",
    canceled: "Cancelado",
    delete: "Eliminar",
    download: "Descargar",
    edit: "Editar",
    editAddress: "Editar dirección",
    receivingAddress: "Dirección de recepción",
    fullname: "Nombre completo",
    nickname: "Apodo",
    phone: "Teléfono",
    zipcode: "Código postal",
    country: "País/Región",
    state: "Estado",
    set: "Establecer",
    city: "Ciudad",
    search: "Buscar",
    more: "Más",
    next: "Siguiente",
    success: "Éxito",
    history: "Historia",
    save: "Guardar",
    ok: "Aceptar",
    others: "Otros",
    logout: "Cerrar sesión",
    login: "Iniciar sesión",
    notification: "Notificación",
    firstName: "Nombre",
    lastName: "Apellido",
    changePwd: "Cambiar tu contraseña",
    currentPwd: "Contraseña actual",
    newPwd: "Nueva contraseña",
    confirmNewPwd: "Confirmar nueva contraseña",
    lastUpdated: "Última actualización",
    logoutTips: "Por favor, confirma si deseas cerrar sesión?",
    viewDetails: "Ver detalles",
    submit: "Enviar",
    systemName: "Centro de Servicio en Línea",
    setAddress: "Establecer como dirección predeterminada",
    systemDesc: "Cree y gestione sus solicitudes de soporte y garantía",
    requestSupport: "Solicitar Soporte",
    requestSupportDesc:
      "Permítanos ayudarlo con sus problemas de reembolso, devolución y reemplazo",
    extendedWarranty: "Garantía Extendida",
    refundsAndReturns: "Reembolsos y Devoluciones",
    submitApplication: "Enviar solicitud",
    reviewPending: "Revisión pendiente",
    finished: "Finalizado",
    returnPending: "Devolución pendiente",
    returnInProgress: "Devolución en progreso",
    refundInProgress: "Reembolso en progreso",
    reshipPending: "Reenvío pendiente",
    reshipInProgress: "Reenvío en progreso",
    replacePending: "Reemplazo pendiente",
    replaceInProgress: "Reemplazo en progreso",
    replacementInProgress: "Reemplazo en progreso",
    close: "Cerrar",
    toShip: "Por enviar",
    inTransit: "En tránsito",
    reviewRejected: "Revisión rechazada",
    amazonGifts: "Regalos de Amazon",
    shopifyGifts: "Regalos de Shopify",
    walmartGifts: "Regalos de Walmart",
    lazadaGifts: "Regalos de Lazada",
    shopeeGifts: "Regalos de Shopee",
    last30Days: "Últimos 30 días",
    last90Days: "Últimos 90 días",
    lastYear: "Año pasado",
    thisYear: "Este año",
    whatsAppContact: "Contacto de WhatsApp",
    forFill: "Para completar",
    goTodownload: "Ir a descargar",
    successfully: "¡Exitosamente!",
    confirmResetPwd: "Confirme la contraseña, por favor",
    shareExperience: "Comparte tu experiencia",
    uploadScreenshot: "Sube tu captura de pantalla, por favor",
    userInfoChangeSuccess: "Información de usuario cambiada exitosamente",
    connectionErr: "Compruebe su conexión a Internet e inténtelo de nuevo",
    mustBeJPGFormat: "¡La imagen de avatar debe ser en formato JPG!",
    inconsistentPasswords: "Las contraseñas ingresadas dos veces no coinciden",
    successfulRegistration:
      "Registro exitoso. El código de activación ha sido enviado al buzón",
    ownShippingCostsTips:
      "Gracias por informarnos sobre este problema. Según nuestra política de garantía, por favor devuelve el artículo defectuoso a nuestro almacén a tu cargo. Una vez recibido, te enviaremos prontamente un artículo de reemplazo o emitiremos un reembolso según lo solicitado. Para ayudar con tu devolución, crearemos una etiqueta de devolución y obtendremos un presupuesto de costo. También puedes utilizar cualquier transportista que prefieras, pero por favor proporciona el número de seguimiento para seguimiento. ¡Gracias por tu cooperación!",
    shippingCostsTips:
      "Gracias por informarnos sobre el problema. Hemos creado una etiqueta de envío prepaga para tu conveniencia. Por favor, devuelve el dispositivo defectuoso y, una vez recibido, te enviaremos prontamente un artículo de reemplazo o emitiremos un reembolso según lo solicitado. ¡Gracias!",
    feedbackTips:
      "¿Tienes unos minutos para darnos tu opinión? Estamos buscando comentarios para mejorar nuestros servicios. ¡Apreciamos tu tiempo!",
    extendedWarrantyDesc: "Verifica tu cobertura y garantía extendida",
    supportHistory: "Historial de soporte",
    supportHistoryDesc:
      "Inicia sesión para ver el estado de las solicitudes de soporte actuales o pasadas",
    APPDesc: "Encuentra y compara productos, obtén soporte y conecta con",
    needMoreHelp: "¿Necesitas más ayuda?",
    contactSupport: "Contactar soporte",
    whereToPurchase: "Dónde comprar",
    systemAutoCorrected: "Corregido automáticamente por el sistema:",
    purchasePlatform: "Plataforma de compra",
    orderNumberFormat: "Formato del número de pedido:",
    copyright: "Copyright © { params } Corporation V2.3.0",
    addressManagementDesc: "Edita, agrega o elimina tu dirección de envío.",
    personalInformation: "Información personal",
    personalInformationDesc:
      "Administra tu información personal, incluyendo la contraseña y las direcciones de correo electrónico donde puedes ser contactado.",
    quickOptionsTips:
      "Puedes elegir un pedido existente para iniciar el soporte.",
    orderProblemsTips1: "El período de garantía ha expirado.",
    orderProblemsTips2:
      "Lo sentimos, este es un pedido de piezas y no puede extender la garantía o el servicio postventa.",
    orderProblemsTips3:
      "Lo siento, el producto usado no puede proporcionar soporte y no puede extenderse para la garantía.",
    uploadSuccessful: "Carga exitosa",
    submitSuccessfully: "Enviado con éxito",
    importantNotice: "AVISO IMPORTANTE",
    submitSuccessfullyDesc:
      "Revisaremos tu pedido dentro de los 3 días hábiles. También puedes verificar el progreso consultando el historial de soporte.",
    findWalmartOrder: "¿Cómo encontrar el número de pedido de Walmart ?",
    findWalmartOrder1: "1. Inicia sesión en tu cuenta de Walmart.",
    findWalmartOrder2: "2. Selecciona 'Historial de compras'.",
    findWalmartOrder3:
      "3. Desplázate hasta el pedido deseado y encuentra el número de pedido.",
    findShopifyOrder:
      "Recibirás un número de pedido por correo electrónico cada vez que realices una compra en www.{params}.com. Por favor, revisa tu bandeja de entrada de correo electrónico para la confirmación del pedido. Si has recibido {params1} productos como regalo, por favor, proporciónanos la información de contacto de la persona que los compró (nombre o dirección de correo electrónico), y te ayudaremos a verificar los detalles.",
    delAddressInfo: "¿Eliminar información de dirección?",
    pwdError: "Error al ingresar la contraseña actual",
    newPwdAgain: "Por favor, ingresa de nuevo la nueva contraseña",
    twoInputsDontMatch: "¡Las dos entradas no coinciden!",
  },
  search: {
    requestSupportDesc:
      "Siga los siguientes pasos para solicitar un reembolso, una devolución y una sustitución.",
    findOrderTips: "¿Cómo encuentro mi número de pedido?",
    findOrderTips1: "Si no puede encontrar el número de pedido, ",
    clickHere: "haga clic aquí",
    checkProducts: "Verificar productos",
    clear: "Borrar",
    othersPlatform: "Otras plataformas",
    purchaseOrderNo: "Número de orden de compra ",
    orderNumber: "Número de orden",
    newSupport:
      "O puedes ingresar un número de orden para iniciar un nuevo soporte.",
    purchasePlatformTips: "Seleccione la plataforma de compra",
    orderNotFoundTips:
      "Lo sentimos, no pudimos encontrar su número de pedido. ",
    importantNotice:
      "AVISO IMPORTANTE: Solo los productos { params }® son elegibles para el soporte de garantía, sujetos a los términos de garantía limitada de { params }. Tenga en cuenta que como parte de los esfuerzos continuos de { params } para prevenir el fraude en el mercado, en caso de que el producto que envíe para el soporte de garantía se encuentre reacondicionado o de otra manera fraudulento,{ params } se reserva el derecho de retener el producto y/o destruir dicho producto, según corresponda.",
    contactCustomer:
      "Hay un problema no técnico con su solicitud, para resolverlo deberá ponerse en contacto con el servicio al cliente de {params} en {mail}.",
  },
  searchTips: {
    orderDoesNotExist: `Por favor, verifica si has ingresado el número de pedido correcto. Si compraste tu producto fuera de línea, carga tu comprobante de compra y lo registraremos por ti.`,
    OrdersUnderReview: `Pedimos disculpas por cualquier inconveniente. Tu pedido actual está siendo revisado actualmente. Lo procesaremos rápidamente y te proporcionaremos las últimas actualizaciones por correo electrónico. También puedes verificar el estado actual en tu historial de pedidos. Gracias por tu comprensión.`,
    dateOfPurchase: `Fecha de compra`,
    warrantyExpiration: `Vencimiento de la garantía`,
    orderItemTips: `El pedido contiene los siguientes artículos.`,
    skuName: `Nombre de SKU`,
    checkHistory: `Ver historial`,
    extendedWarrantyForThisOrder: `Garantía extendida para este pedido`,
    uploadPurchaseProof: `Carga tu comprobante de compra`,
    uploadPurchaseProofTips: `Por favor, proporciona detalles adicionales sobre tu compra para registrar la garantía.`,
    extendedWarrantySuccessTips: `Nos complace confirmar que tu producto, asociado al número de pedido proporcionado, ha sido otorgado con una garantía extendida de 3 años. Esta garantía estará vigente hasta |. ¡Gracias por elegir nuestro producto!`,
    submissionReviewComplete: `Procesaremos tu solicitud en los próximos 3 días hábiles. y te mantendremos informado por correo electrónico sobre el progreso. | ¡Hemos recibido tu solicitud! La procesaremos rápidamente y puedes esperar actualizaciones por correo electrónico en los próximos 3 días hábiles.`,
    completeSupportInformation: `Información de soporte completa`,
    fillTips: `Por favor, completa la siguiente información para registrar la garantía con éxito.`,
    whatWouldYouLikeToDo: `¿Qué te gustaría hacer?*`,
    itemCondition: `Condición del artículo*`,
    withOriginalPackaging: `Con embalaje original`,
    withoutOriginalPackaging: `Sin embalaje original`,
    reasonForReturn: `¿Por qué estás devolviendo este artículo?*`,
    isExtendedWarranty: "Su pedido ya tiene una garantía extendida",
    isGreaterThan90Days: `Lo sentimos, la fecha de compra de tu pedido supera los 90 días.`,
    isGreaterThan90DaysDesc:
      "Lo sentimos, pero parece que su pedido fue realizado hace más de 90 días. Desafortunadamente, según nuestra política de garantía y devolución, no podemos extender la garantía de su pedido en este momento.",
    isInAfterSalesDesc: `Lo sentimos, tu pedido está actualmente en proceso para el servicio posventa, por lo que no podemos aceptar presentaciones duplicadas. También puedes verificar el estado actual en tu historial de pedidos. Gracias por tu comprensión.`,
    isOvertime: `Lo sentimos, tu pedido ha superado el período de garantía.`,
    isOvertimeDesc1: `Tu pedido ha alcanzado el final de su vida útil y ya no puede ser extendido. Si tienes alguna pregunta, no dudes en contactarnos.`,
    isOvertimeDesc2: `Tu pedido ha alcanzado el final de su vida útil y ya no puede ser reembolsado ni reemplazado.`,
    noOrdersExist: `Lo sentimos, el pedido actual está pendiente de revisión.`,
  },
  form: {
    name: "Nombre",
    address: "Dirección",
    comments: "Comentarios",
    defaultAddress: "Dirección predeterminada",
    returnAddress: "Dirección de devolución",
    problemType: "Tipo de problema",
    completePurchaseInformation: "Completa la información de compra",
    orderTip:
      "Si ya has enviado una solicitud de soporte, también puedes | hacer clic aquí | para comprobar el estado detallado y los registros.",
    uploadPhoto: "Cargar una foto del recibo de compra",
    rulesEnterTips: "Por favor, ingresa el {params}",
    rulesSelectTips: "Por favor, selecciona el {params}",
    formatTips: "Formato incorrecto de {params}",
    uploadTips: "Por favor, carga una prueba de compra",
    uploadReceipt: "Cargar una foto del recibo de compra",
    formatError: "Error de formato",
    pictureSize: "Tamaño de imagen superior a {params}",
    processingResult: "Resultado del procesamiento",
    findOrderScreenshot: "¿Cómo encuentro la captura de pantalla de mi pedido?",
    helpTips: "¿En qué necesitas ayuda?",
    processingMethod: "Método de procesamiento",
    readPolicy:
      "Por favor, lee | la política de devolución y reembolso | detenidamente",
    keepOriginalPackaging:
      "¿Estás manteniendo el embalaje original del producto?",
    whetherToKeep: "si mantener",
    problemDescription: "Descripción del problema",
    problemDesc: "una descripción del problema",
    uploadUp: "Cargar hasta {n} imágenes",
    warrantyErrTips:
      "Lo sentimos, su pedido ha superado el período de garantía.",
    warrantyErrTipsDesc:
      "Si aún necesita asistencia, póngase en contacto con el soporte técnico de {params}. Un técnico revisará el problema y le proporcionará un presupuesto si lo desea.",
    OutOfwarrantyTips:
      "Este producto ha superado el período de garantía y no es elegible para asistencia.",
    accessoryTips:
      "Este producto es un accesorio y no es elegible para asistencia.",
    underMaintenanceTips: "El producto está actualmente en mantenimiento.",
    clickViewDetail: "Haga clic aquí para ver detalles",
    warrantySuccessful: "Extensión de garantía exitosa",
    warrantySuccessfulDesc:
      "Por la presente confirmamos que se ha otorgado una extensión de garantía de 3 años a su producto asociado al número de pedido proporcionado.<br /> La garantía será válida hasta",
    keepPackage: "¿Estás conservando el embalaje exterior del producto?",
    uploadDefect: "Cargar evidencia visual del defecto",
    yes: "Sí",
    no: "No",
    returnAndRefund: "Devolución y Reembolso",
    returnAndRefundFee: "Devolución y Reembolso - Gastos de Envío",
    returnsAndReplace: "Devolución y Reemplazo",
    returnsAndExchanges: "Devoluciones e Intercambios",
    returnsAndExchangesFees: "Devoluciones e Intercambios - Gastos de Envío",
    noNeedToReturn: "No es necesario devolver - reemisión",
    noNeedToReturnFee: "No es necesario devolver - reemisión - Gastos de Envío",
    rejection: "Rechazo",
    reissueAccessory: "Reemisión de Accesorios",
    alreadyExtendedWarranty: "Tu pedido ya tiene una garantía extendida.",
    outOfExtendedWarranty: "Este producto está fuera de la garantía extendida.",
    extendedWarrantyFailTips:
      "Este producto ya ha solicitado una garantía extendida y no se puede solicitar de nuevo.",
    contactCustomerService:
      "Por favor, contacta con el servicio al cliente de Amazon. ¡Gracias!",
    contactAmazon: "Contactar a Amazon",
    hasContactAmazon: "¿Se ha contactado con Amazon?",
    addressError:
      "Error al analizar la dirección, por favor vuelva a ingresarla.",
  },
  router: {
    home: "Inicio",
    search: "Buscar",
    findeOrder: "Encontrar pedido",
    findeScreenshot: "Encontrar captura de pantalla",
    purchaseInformation: "Información de compra",
    shippingInformation: "Información de envío",
    shippingLabel: "Etiqueta de envío",
    warrantyInfo: "Información de garantía",
    afterSalesInfo: "Información postventa",
    orderList: "Lista de pedidos",
    orderInformation: "Información del pedido",
    supportHistory: "Historial de soporte",
    orderDetails: "Detalles del pedido",
    evaluationRecordDetails: "Detalles del registro de evaluación",
    editProfile: "Editar perfil",
    addressManagement: "Gestión de direcciones",
    mailboxVerification: "Verificación del buzón de correo",
    resetPassword: "Restablecer contraseña",
  },
};
