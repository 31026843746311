import pinia from "@/stores/index";
import { getShopNameList } from "@/api/Process";
import { getUserAllAddressList } from "@/api/LoginRegister";
import { onMounted, ref, watch } from "vue";
import { useUsersStore } from "@/stores/user";
import i18n from "@/lang";
const { t } = i18n.global;
let { isLogin } = useUsersStore(pinia);
export default function () {
  const methodList = ref([]);
  const addressList = ref([]);
  let defaultAddress = ref("");
  let userAddress = ref({});
  let userAddressId = ref("");
  const getMethodList = () => {
    getShopNameList({ type: 1 }).then((res) => {
      methodList.value =
        res.data.map((item) => {
          if (item.statusCode == "Resend") {
            item.statusUs = t("login.resend");
          }
          if (item.statusCode == "RETURN_AND_EXCHANGE") {
            item.statusUs = t("form.returnsAndReplace");
          }
          if (item.statusCode == "RETURN_REFUND") {
            item.statusUs = t("form.returnAndRefund");
          }
          return item;
        }) || [];
    });
  };
  const getAddressList = () => {
    getUserAllAddressList().then((res) => {
      if (res.code == 0) {
        addressList.value = res.data || [];
        if (res.data && res.data.length > 0) {
          let defaultObj = res.data.find((item) => item.defaultFlag);
          if (defaultObj) {
            let {
              nickNake,
              receivingAddress,
              city,
              state,
              country,
              zipCode,
              phone,
            } = defaultObj;
            let receivingAddressObj = receivingAddress.split("|");
            userAddress.value = {
              country,
              state,
              city,
              address: receivingAddressObj[0],
              address1:
                receivingAddressObj.length > 1 ? receivingAddressObj[1] : "",
              postcode: zipCode,
              name: nickNake,
              telephone: phone,
            };
            defaultAddress.value =
              nickNake +
              "," +
              receivingAddress +
              "," +
              city +
              "," +
              state +
              "," +
              country +
              "," +
              zipCode +
              "," +
              phone;
            userAddressId.value = defaultObj.id;
          } else {
            let {
              nickNake,
              receivingAddress,
              city,
              state,
              country,
              zipCode,
              phone,
            } = res.data[0];
            let receivingAddressObj = receivingAddress.split("|");
            userAddress.value = {
              country,
              state,
              city,
              address: receivingAddressObj[0],
              address1:
                receivingAddressObj.length > 1 ? receivingAddressObj[1] : "",
              postcode: zipCode,
              name: nickNake,
              telephone: phone,
            };
            defaultAddress.value =
              nickNake +
              "," +
              receivingAddress +
              "," +
              city +
              "," +
              state +
              "," +
              country +
              "," +
              zipCode +
              "," +
              phone;
            userAddressId.value = res.data[0].id;
          }
        }
      }
    });
  };
  onMounted(() => {
    getMethodList();
    // if (isLogin) {
    //   getAddressList();
    // }
  });
  return {
    methodList,
    addressList,
    getAddressList,
    defaultAddress,
    userAddress,
    userAddressId,
  };
}
