// 意大利语
export default {
  login: {
    login: "Accesso",
    userName: "Nome utente",
    password: "Password",
    email: "E-mail",
    realEmail: "E-mail reale",
    forgotPassword: "Password dimenticata",
    verificationCode: "Codice di verifica",
    resend: "Rinvia",
    send: "Invia",
    sendCode: "Invia codice",
    rememberMe: "Ricordami",
    signIn: "Accedi con la tua email per continuare",
    signInDesc:
      "Se non hai ancora un account {params}, ne verrà automaticamente creato uno per te",
    warrantyTips: "A un passo dal successo della garanzia estesa!",
    warrantyDescTips: `Accedi ora e attiva
          il tuo account per un facile accesso ai record di garanzia, reso e
          scambio. Inoltre, avrai la possibilità di vincere buoni regalo!`,
    notReceivedCode: `Non hai ricevuto il codice di verifica? Conferma
          se l'indirizzo e-mail che hai inserito è valido e corretto. Se non lo è, |fai clic qui per inviarne un altro.`,
    emailTips: `Se non hai ricevuto il codice di verifica più volte,
          controlla se l'indirizzo e-mail è stato inserito correttamente o
          cambia l'indirizzo e-mail.`,
    passwordValidate: "La tua password deve essere lunga almeno 6 caratteri",
    emailFormat: "Inserisci il formato e-mail corretto",
    vaildFormat: "Si prega di inserire il formato corretto di {params}",
    createYour: "Crea il tuo",
    changeNewPassword: "Cambia nuova password",
    changeNewPasswordDesc: `La tua password deve contenere almeno 8 caratteri, <br />
          includendo numeri, lettere maiuscole e minuscole`,
  },
  forgetpwd: {
    title: "Hai problemi a effettuare l'accesso?",
    titleDesc: "Inserisci la tua email per iniziare.",
    verificationCode: "Digita i caratteri nell'immagine",
    sendSuccessTips:
      "L'email per reimpostare la tua password è stata inviata nella tua casella di posta in arrivo. Controlla la tua email.",
  },
  recordPage: {
    supportHistoryDesc:
      "Visualizza lo stato delle tue richieste di supporto attuali o precedenti",
    timespan: "Periodo",
    status: "Stato",
    expectedTips1: "Previsto per essere completato in 3 giorni lavorativi",
    expectedTips2: "Si prevede che verrà consegnato entro 10 giorni lavorativi",
    expectedTips3: "Previsto per essere ricevuto entro 10 giorni lavorativi",
    review: "Valuta e vinci premi!",
    additionalRating: "Valutazione aggiuntiva",
    product: "Prodotto",
    sku: "SKU",
    type: "Tipo",
    created: "Creato",
    initiated: "Iniziato",
    update: "Aggiornamento",
    action: "Azione",
  },
  recordDetail: {
    warrantyStatus: "Stato della garanzia",
    headerTips1: "La tua richiesta è in fase di elaborazione",
    headerTips2: "La tua richiesta di reso è stata approvata",
    headerTips3: "Hai completato il pagamento con successo",
    headerTips4: "Scarica l'etichetta e restituisci il prodotto",
    headerTips5: "Il tuo reso è stato ricevuto",
    headerTips6: "Il tuo nuovo articolo è stato spedito",
    headerTips7: "La tua garanzia estesa è stata completata",
    headerTips8: "Il tuo assistenza post-vendita è stata completata",
    headerTips9: "Il tuo pagamento non è ancora stato completato.",
    underReviewDesc: `Abbiamo ricevuto la tua richiesta! La elaboreremo prontamente e ti aggiorneremo via email entro 
    i prossimi 3 giorni lavorativi. Se hai domande, non esitare a contattarci.`,
    returnsInProgressDesc: ` La tua richiesta di reso è stata approvata! Ti invieremo un'etichetta di reso tramite il sistema entro 
    3 giorni lavorativi. Si prega di scaricarla prontamente e restituire il prodotto.`,
    notSended: "Non inviato?",
    haveSended: "Inviato?",
    haveSendedDesc: `Il tuo sostituto sarà elaborato una volta che avremo ricevuto il tuo reso. Si prega di essere paziente e non esitate a contattarci se avete domande o dubbi. Siamo qui per aiutarti! Puoi fare clic sul pulsante qui sotto per tracciare le informazioni dettagliate sulla spedizione.`,
    resendDesc:
      "Il tuo nuovo prodotto sta per essere spedito. Si prega di tenere d'occhio gli aggiornamenti sullo stato della spedizione.",
    awaitingPaymentDesc: ` Il suo pagamento è ancora in sospeso. Si prega di completarlo entro 15 minuti, altrimenti la creazione di questo etichetta verrà automaticamente annullata.`,
    trackShippingStatus: "Traccia lo stato della spedizione",
    proceedToPayment: "Procedi al pagamento",
    successPay: "Hai completato il pagamento con successo.",
    returnsInProgressDesc1:
      "Si prega di reimballare l'articolo nel suo imballaggio originale (se applicabile). Basta cliccare sul pulsante qui sotto per scaricare e stampare l'etichetta di spedizione. Dopodiché, basta applicarla al pacco e consegnarlo in un punto UPS. ",
    returnsInProgressDesc2:
      "Il tuo sostituto sarà elaborato una volta ricevuto il tuo reso. Si prega di essere paziente e non esitate a contattarci se avete domande o dubbi. Siamo qui per aiutare! Puoi cliccare sul pulsante qui sotto per tracciare le informazioni dettagliate sulla spedizione.",
    exchangeInProgressDesc:
      "La tua sostituzione verrà elaborata non appena riceveremo il tuo reso. Ti preghiamo di essere paziente e non esitare a contattarci se hai domande o preoccupazioni. Siamo qui per aiutarti!",
    exchangeInProgressDesc1:
      "Il tuo reso è in fase di elaborazione presso il centro resi. Il nuovo articolo è stato spedito per te, con il numero di tracciamento|, Se hai domande, non esitare a contattarci.",
    replacementInProgressDesc:
      "Il tuo nuovo articolo è stato spedito per te, con il numero di tracciamento|, Se hai domande, non esitare a contattarci.",
    refundsInProgressDesc:
      "Il tuo reso è in fase di elaborazione presso il centro resi. Il rimborso sarà accreditato sul tuo account originale entro 3 giorni lavorativi. Ti preghiamo di controllare il tuo account.",
    ReturnOrRefundStatus: "Stato del reso o del rimborso",
    reviewDetails: "Dettagli della recensione",
    review: "Recensione",
    request: "Richiesta",
    trackingNumber: "Numero di tracciamento",
    requestSupportInformation: "Richiedi informazioni di supporto",
    shippingTips:
      "La tua richiesta di supporto è stata approvata. | Completa le informazioni sulla spedizione per continuare.",
    downloadLabeltIPS:
      "{params} ti ha inviato un'etichetta di reso, | Clicca qui per scaricarla | , quindi stampa e imballa il tuo articolo per il reso.",
    ProcessingMethodForPlatformReview:
      "Metodo di elaborazione per la revisione della piattaforma",
    reviewInstructions: "Istruzioni per la recensione",
    refundGiftCardType: "Tipo di carta regalo per il rimborso",
    refundGiftCardID: "ID della carta regalo per il rimborso",
    refundGiftCardAmount: "Importo della carta regalo per il rimborso",
    refundVoucherDesc:
      "La tua richiesta di rimborso ({ params }) è stata elaborata e l'importo è stato restituito al tuo conto di pagamento. Si prega di controllare il proprio conto per confermare la ricezione.",
    refundVoucher: "Voucher di rimborso",
    refundGiftDesc:
      "La tua richiesta di rimborso è in fase di elaborazione e l'importo ({amount}) sarà accreditato sul tuo {type} (ID {id}). Puoi visualizzare la ricevuta di rimborso cliccando sul pulsante « Visualizza dettagli ».",
    refundAmount: "Importo del rimborso",
    giftCardType: "Tipo di carta regalo",
    giftCardID: "ID carta regalo",
    amount: "Importo",
    gifts: "Regali",
    reviewNotApproved: "Recensione non approvata",
    reviewContent: "Contenuto della recensione",
    rating: "Valutazione",
    photo: "Foto",
    ratingPhoto: "Foto della valutazione",
    trackingID: "ID di tracciamento",
    shippingCarrier: "Corriere",
    purchaseDate: "Data di acquisto",
    limitedDate: "Data di scadenza della garanzia limitata",
    stepDetailTips1: `La tua richiesta è stata approvata. Si prega di restituire il prodotto difettoso a proprie spese. Utilizza l'icona "Stima e Ottieni l'Etichetta di Spedizione" per verificare i costi di spedizione stimati. Se sono adatti, puoi quindi acquistare il nostro servizio di spedizione. Sentiti libero di guardare il video illustrativo se necessario.`,
    getLabelStepTitle: "Istruzioni per il ritorno del tuo pacchetto:",
    getLabelStep1: "1. Paga il servizio di spedizione e ottieni l'etichetta.",
    getLabelStep2: "2. Utilizza, se possibile, la scatola originale.",
    getLabelStep3: "3. Stampa e attacca l'etichetta alla scatola.",
    getLabelStep4: "4. Consegna il pacco presso UPS.",
    estimationAndGetShippingLabel: "Stima e Ottieni l'Etichetta di Spedizione",
    searchOthers: "Cerca altri",
    returnTips: `La tua richiesta di reso è stata approvata! Ti preghiamo di imballare nuovamente il tuo articolo nella confezione originale (se disponibile). Clicca semplicemente sul pulsante qui sotto per scaricare l'etichetta di spedizione e stamparla. Successivamente, appiccicala semplicemente al tuo pacco e lascialo in qualsiasi punto UPS.`,
    downloadLabel: " Scarica l'etichetta",
    reasonForReturn: "Motivo del reso",
    expired: "Scaduto",
    underWarranty: "Sotto garanzia",
    qty: "Quantità",
  },
  evaluation: {
    exclusiveOpportunity: "Opportunità Esclusiva",
    descriptions: `Avrai la possibilità di ricevere uno dei seguenti articoli in omaggio o <br/> un voucher da $20<br/><b>Facile da partecipare:</b><br/>
    Compila il modulo in 1-3 minuti, consulta "vedi dettagli"`,
    winGiftCard: "Vinci una carta regalo da $20 a $50!",
    freeTips:
      " Assolutamente gratuito - Nessuna spesa nascosta, nessuna carta di credito richiesta!",
    feedbackTips:
      "Apprezziamo sinceramente il tuo feedback e saremmo entusiasti se potessi dedicare solo pochi minuti a condividere la tua esperienza di acquisto con altri clienti. Il tuo contributo è incredibilmente prezioso per noi!",
    steps: "3 Semplici Passaggi Per Vincere",
    steps1: "PASSO 1:",
    steps1Desc:
      "Scatta una foto del tuo nuovo massaggiatore {params}. Assicurati di ottenere un ottimo scatto per un buon inizio!",
    steps2: "PASSO 2:",
    steps2Desc:
      "Pubblicalo su Facebook, Instagram, Twitter, YouTube, Tiktok con l'hashtag #{params}.",
    steps3: "PASSO 3:",
    steps3Desc: "Copia l'URL del post e invialo nel modulo sottostante.",
    storeGiftCard: "Carta regalo da $30 del negozio {params}",
    pasteUrl: "Incolla il tuo URL qui.",
    tips: "*Ricorda di impostare il tuo post su Pubblico in modo che possiamo vederlo.",
    selectGiftTips: "Seleziona il regalo che desideri per",
    selectproduct: "Seleziona il tuo prodotto",
    selectproductDesc: "Seleziona i prodotti che desideri condividere.",
    footerTips:
      "continuerà ad impegnarsi per creare uno stile di vita sano per i nostri clienti. Grazie per il riconoscimento dei nostri prodotti.",
    fillInAddressTips:
      "Completa il tuo indirizzo di consegna in modo da poter ricevere il tuo regalo il prima possibile.",
    rateTitle: "Sei soddisfatto del prodotto?",
    submitSteps1: "Clicca qui | per condividere la tua esperienza di acquisto.",
    submitSteps2:
      "Scatta uno screenshot del commento o della valutazione che hai lasciato e salvalo.",
    submitSteps3:
      "Torna a questa pagina e | carica lo screenshot che hai appena salvato.",
    uploadScreenshot: "Carica il tuo screenshot",
    copyUrl: "Copia l'URL del post e invialo nel modulo sottostante.",
    sharingSuccessTips: "Grazie per la condivisione",
    sharingSuccessDesc:
      "Apprezziamo il tuo grande supporto. La tua presentazione è stata ricevuta e verrà esaminata entro 3 giorni lavorativi. L'ID premio per la carta regalo ti verrà inviato via email poco dopo il completamento della revisione. Puoi anche controllare la cronologia delle revisioni per eventuali aggiornamenti.",
    sharingSuccessDesc1:
      "Ci scusiamo per l'esperienza negativa del prodotto che hai avuto. Apporteremo miglioramenti per offrirti un'esperienza migliore.",
    afterSalesRateTitle:
      "Si prega di valutare questo servizio di assistenza post-vendita",
    placeRate: "Si prega di valutare.",
  },
  logistics: {
    info: "Informazioni sulla logistica",
    orderNumber: "Numero d'ordine logistica",
    shippingInfo: "Informazioni di spedizione complete",
    orderTime: "Ora dell'ordine",
    company: "Azienda di logistica",
    downloadFaceSheet: "Scarica la facciata",
    shippingInfoDesc:
      "Segui i passaggi seguenti per completare le informazioni di spedizione",
    buyLable: "Acquista un'etichetta su {params}",
    packagingWeight: "Peso dell'imballaggio",
    completed: "Completato",
    createTime: "Ora di creazione",
    shippingAddress: "Indirizzo di spedizione",
    shippingInformation: "Informazioni di spedizione",
    retry: "Riprova",
    price: "Prezzo",
    weight: "Peso",
    length: "Lunghezza",
    width: "Larghezza",
    height: "Altezza",
    lb: "lb",
    in: "in",
    tel: "Tel",
    finish: "Termina",
    ups: "UPS",
    total: "Totale (con sconto)",
    $: "$",
    postPaymentInstructions: "Istruzioni Post-Pagamento",
    postPaymentInstructionsDesc: `Si prega di completarlo entro quindici minuti, altrimenti la creazione di questo etichetta verrà automaticamente annullata.`,
    payNow: "Paga ora",
    publishedCharges: "Spese pubblicate",
    payGetLabel: "Paga e ottieni l'etichetta",
    telephoneNumber: "numero di telefono",
    uploadLabel: "Carica la tua etichetta",
    shipTo: "Devi spedire il tuo articolo a",
    createOrderTips:
      "Si prega di tornare a questa pagina per scaricare l'etichetta di spedizione dopo aver completato con successo il pagamento.",
    paymentTips:
      "Si prega di completare il pagamento sulla pagina appena aperta",
    paymentTipsDesc:
      "Si prega di non chiudere questa finestra prima che il pagamento sia completato. Dopo il pagamento, fare clic sul pulsante sottostante in base alla propria situazione.",
    payTips:
      "Si prega di notare che i codici sconto o le carte regalo non possono essere utilizzati per questo ordine. In caso contrario, l'ordine verrà annullato.",
    howGetShippingLabel: "Come ottieni la tua etichetta di spedizione?",
    priceTips:
      "*Questo prezzo è una stima. Effettuando un ordine si otterrà una tariffa scontata.",
    payGetLabel: "Paga e ottieni l'etichetta di spedizione",
  },
  findOrder: {
    title: `Come trovare il numero d'ordine Amazon?`,
    step1: `Passaggio 1: Apri la homepage di Amazon`,
    step2: `Passaggio 2: Clicca su "Resi e ordini"`,
    step2Dec: `Dovresti trovarlo in alto a destra, tra "Account e liste" e il tuo carrello.`,
    step3: `Passaggio 3: Trova il tuo ordine`,
    step3Dec: `Il numero d'ordine è generalmente composto da 17 cifre.`,
  },
  findScreenshot: {
    title: "COME TROVARE LO SCREENSHOT DEL TUO ORDINE?",
    step1:
      "1. I tuoi ordini (Se stai gestendo ordini più vecchi, usa il filtro per impostare un intervallo di tempo.)",
    step2: '2. fai clic su "Visualizza dettagli ordine"',
    step3: '3. fai clic su "Visualizza fattura"',
    step4: "4. Fattura",
  },
  faq: {
    title: "Domande frequenti",
    faqDesc:
      "Prima di richiedere assistenza, puoi rapidamente esaminare il tuo problema attraverso le FAQ",
    faqTips:
      "Se hai letto le FAQ ma non riesci ancora a trovare una soluzione al tuo problema, | Clicca qui",
  },
  theme: {
    all: "Tutto",
    addAddress: "Aggiungi indirizzo",
    back: "Indietro",
    collapse: "Collasso",
    continue: "Continua",
    checking: "Controllo...",
    cancel: "Annulla",
    canceled: "Annullato",
    delete: "Elimina",
    download: "Scaricare",
    edit: "Modifica",
    editAddress: "Modifica indirizzo",
    receivingAddress: "Indirizzo di ricezione",
    fullname: "Nome completo",
    nickname: "Soprannome",
    phone: "Telefono",
    zipcode: "Codice postale",
    country: "Paese/Regione",
    state: "Stato",
    set: "Imposta",
    city: "Città",
    search: "Ricerca",
    more: "Di Più",
    next: "Avanti",
    success: "Successo",
    history: "La storia",
    save: "Salvare",
    ok: "OK",
    others: "Altri",
    logout: "Disconnettersi",
    login: "Accedere",
    notification: "Notifica",
    firstName: "Nome di battesimo",
    lastName: "Cognome",
    changePwd: "Cambia la tua password",
    currentPwd: "Password attuale",
    newPwd: "Nuova password",
    confirmNewPwd: "Conferma la nuova password",
    lastUpdated: "Ultimo aggiornamento",
    logoutTips: "Conferma se vuoi disconnetterti?",
    viewDetails: "Visualizza dettagli",
    submit: "Invia",
    systemName: "Centro Assistenza Online",
    setAddress: "Imposta come indirizzo predefinito",
    systemDesc: "Crea e gestisci le tue richieste di supporto e garanzia",
    requestSupport: "Richiedi supporto",
    requestSupportDesc:
      "Facci aiutare con i tuoi problemi di rimborso, reso e sostituzione",
    extendedWarranty: "Garanzia estesa",
    refundsAndReturns: "Rimborsi e Resi",
    submitApplication: "Invia domanda",
    reviewPending: "Revisione in sospeso",
    finished: "Finito",
    returnPending: "Restituzione in sospeso",
    returnInProgress: "Restituzione in corso",
    refundInProgress: "Rimborso in corso",
    reshipPending: "Respedizione in sospeso",
    reshipInProgress: "Respedizione in corso",
    replacePending: "Sostituzione in sospeso",
    replaceInProgress: "Sostituzione in corso",
    replacementInProgress: "Sostituzione in corso",
    close: "Chiudi",
    toShip: "Da spedire",
    inTransit: "In transito",
    reviewRejected: "Revisione rifiutata",
    amazonGifts: "Regali Amazon",
    shopifyGifts: "Regali Shopify",
    walmartGifts: "Regali Walmart",
    lazadaGifts: "Regali Lazada",
    shopeeGifts: "Regali Shopee",
    last30Days: "Ultimi 30 giorni",
    last90Days: "Ultimi 90 giorni",
    lastYear: "L'anno scorso",
    thisYear: "Quest'anno",
    whatsAppContact: "Contatto WhatsApp",
    forFill: "Per compilare",
    goTodownload: "Vai al download",
    successfully: "Con successo!",
    confirmResetPwd: "Conferma la password, per favore",
    shareExperience: "Condividi la tua esperienza",
    uploadScreenshot: "Si prega di caricare lo screenshot",
    userInfoChangeSuccess: "Informazioni utente modificate con successo",
    connectionErr: "Controlla la tua connessione Internet e riprova",
    mustBeJPGFormat: "L'immagine dell'avatar deve essere in formato JPG!",
    inconsistentPasswords: "Le password inserite due volte non coincidono",
    successfulRegistration:
      "Registrazione avvenuta con successo. Il codice di attivazione è stato inviato alla casella di posta",
    ownShippingCostsTips:
      "Grazie per averci informato di questo problema. In base alla nostra politica di garanzia, ti chiediamo di restituire l'articolo difettoso al nostro magazzino a tue spese. Una volta ricevuto, ti invieremo prontamente un articolo sostitutivo o emetteremo un rimborso come richiesto. Per facilitare il reso, creeremo un'etichetta di reso e otterremo un preventivo di costo. Puoi anche utilizzare qualsiasi corriere preferisci, ma per favore fornisci il numero di tracciamento per il monitoraggio. Grazie per la tua collaborazione.",
    shippingCostsTips:
      "Grazie per averci informato del problema. Abbiamo creato un'etichetta di spedizione prepagata per la tua convenienza. Si prega di restituire il dispositivo difettoso e, una volta ricevuto, ti invieremo prontamente un articolo sostitutivo o emetteremo un rimborso come richiesto. Grazie!",
    feedbackTips:
      "Hai qualche minuto libero per darci un feedback? Siamo alla ricerca di commenti per migliorare i nostri servizi. Apprezziamo il tuo tempo!",
    extendedWarrantyDesc: "Verifica la tua copertura e la garanzia estesa",
    supportHistory: "Cronologia del supporto",
    supportHistoryDesc:
      "Accedi per visualizzare lo stato delle richieste di supporto attuali o passate",
    APPDesc: "Trova e confronta prodotti, ottieni supporto e connettiti con",
    needMoreHelp: "Hai bisogno di ulteriore assistenza?",
    contactSupport: "Contatta il supporto",
    whereToPurchase: "Dove acquistare",
    systemAutoCorrected: "Corretto automaticamente dal sistema:",
    purchasePlatform: "Piattaforma di acquisto",
    orderNumberFormat: "Formato del numero d'ordine:",
    copyright: "Copyright © { params } Corporation V2.3.0",
    addressManagementDesc:
      "Modifica, aggiungi o elimina il tuo indirizzo di spedizione.",
    personalInformation: "Informazioni personali",
    personalInformationDesc:
      "Gestisci le tue informazioni personali, inclusa la password e gli indirizzi email a cui puoi essere contattato.",
    quickOptionsTips:
      "Puoi scegliere un ordine esistente per avviare il supporto.",
    importantNotice: "AVVISO IMPORTANTE",
    orderProblemsTips1: "Il periodo di garanzia è scaduto.",
    orderProblemsTips2:
      "Spiacente, questo è un ordine di parti e non può estendere la garanzia o il servizio post-vendita.",
    orderProblemsTips3:
      "Spiacenti, il prodotto usato non può fornire supporto e non può essere esteso per la garanzia.",
    uploadSuccessful: "Caricamento riuscito",
    submitSuccessfully: "Inviato con successo",
    submitSuccessfullyDesc:
      "Esamineremo il tuo ordine entro 3 giorni lavorativi. Puoi anche controllare il progresso visualizzando la cronologia del supporto.",
    findWalmartOrder: "Come trovare il numero d'ordine di Walmart ?",
    findWalmartOrder1: "1. Accedi al tuo account Walmart.",
    findWalmartOrder2: "2. Seleziona 'Cronologia degli acquisti'.",
    findWalmartOrder3:
      "3. Scorri fino all'ordine desiderato e trova il numero d'ordine.",
    findShopifyOrder:
      "Riceverai un numero d'ordine via email ogni volta che effettui un acquisto su www.{params}.com. Controlla la tua casella di posta elettronica per la conferma dell'ordine. Se hai ricevuto {params1} prodotti come regalo, gentilmente fornisci le informazioni di contatto della persona che li ha acquistati (nome o indirizzo email), e ti assisteremo nel verificare i dettagli.",
    delAddressInfo: "Eliminare le informazioni sull'indirizzo?",
    pwdError: "Errore nell'inserimento della password corrente",
    newPwdAgain: "Inserisci nuovamente la nuova password",
    twoInputsDontMatch: "Le due inserzioni non corrispondono!",
  },
  search: {
    requestSupportDesc:
      "Segui i passaggi seguenti per richiedere un rimborso, un reso e una sostituzione.",
    findOrderTips: "Come trovo il mio numero d'ordine?",
    findOrderTips1: "Se non riesci a trovare il numero d'ordine, ",
    clickHere: "clicca qui",
    checkProducts: "Controlla i prodotti",
    clear: "Cancella",
    othersPlatform: "Altre piattaforme",
    purchaseOrderNo: "Numero d'ordine ",
    orderNumber: "Numero d'ordine",
    newSupport:
      "Oppure puoi inserire un numero d'ordine per avviare un nuovo supporto.",
    purchasePlatformTips: "Seleziona la piattaforma di acquisto",
    orderNotFoundTips:
      "Spiacenti, non siamo riusciti a trovare il tuo numero d'ordine. ",
    importantNotice:
      "AVVISO IMPORTANTE: Solo i prodotti { params }® sono idonei per il supporto garantito, soggetti ai termini di garanzia limitata di { params }. Si prega di notare che come parte degli sforzi continui di { params } per prevenire frodi sul mercato, nel caso in cui il prodotto che si invia per il supporto garantito venga trovato essere ricondizionato o altrimenti fraudolento,{ params } si riserva il diritto di trattenere il prodotto e/o distruggere tale prodotto, secondo opportunità.",
    contactCustomer:
      "C'è un problema non tecnico con la tua richiesta, per risolverlo dovrai contattare il servizio clienti di {params} all'indirizzo {mail}.",
  },
  searchTips: {
    orderDoesNotExist: `Si prega di verificare se è stato inserito il numero di ordine corretto. Se hai acquistato il prodotto offline, carica la tua prova d'acquisto e la registreremo per te.`,
    OrdersUnderReview: `Ci scusiamo per eventuali inconvenienti. Il tuo ordine attuale è attualmente in fase di revisione. Lo elaboreremo prontamente e ti forniremo gli ultimi aggiornamenti via e-mail. Puoi anche verificare lo stato attuale nella tua cronologia ordini. Grazie per la comprensione.`,
    dateOfPurchase: `Data di acquisto`,
    warrantyExpiration: `Scadenza della garanzia`,
    orderItemTips: `L'ordine contiene i seguenti articoli.`,
    skuName: `Nome SKU`,
    checkHistory: `Controlla la cronologia`,
    extendedWarrantyForThisOrder: `Garanzia estesa per questo ordine`,
    uploadPurchaseProof: `Carica la prova d'acquisto`,
    uploadPurchaseProofTips: `Fornisci ulteriori dettagli sul tuo acquisto per registrare la garanzia.`,
    extendedWarrantySuccessTips: `Siamo lieti di confermarti che il tuo prodotto, associato al numero d'ordine fornito, ha ottenuto una garanzia estesa di 3 anni. Questa garanzia sarà valida fino a |. Grazie per aver scelto il nostro prodotto!`,
    submissionReviewComplete: `Elaboreremo la tua richiesta entro i prossimi 3 giorni lavorativi. e ti aggiorneremo per e-mail sui progressi. | Abbiamo ricevuto la tua richiesta! La elaboreremo prontamente e potrai aspettarti aggiornamenti via e-mail entro i prossimi 3 giorni lavorativi.`,
    completeSupportInformation: `Informazioni di supporto complete`,
    fillTips: `Si prega di compilare le seguenti informazioni per registrare con successo la garanzia.`,
    whatWouldYouLikeToDo: `Cosa desideri fare?*`,
    itemCondition: `Condizione dell'articolo*`,
    withOriginalPackaging: `Con imballaggio originale`,
    withoutOriginalPackaging: `Senza imballaggio originale`,
    reasonForReturn: `Perché stai restituendo questo articolo?*`,
    isExtendedWarranty: "Il tuo ordine ha già una garanzia estesa",
    isGreaterThan90Days: `Spiacenti, la data di acquisto della tua ordinazione supera i 90 giorni.`,
    isGreaterThan90DaysDesc:
      "Siamo spiacenti, ma sembra che il tuo ordine sia stato acquistato più di 90 giorni fa. Sfortunatamente, secondo la nostra politica di garanzia e restituzione, non possiamo estendere la garanzia per il tuo ordine in questo momento.",
    isInAfterSalesDesc: `Spiacenti, la tua ordinazione è attualmente in fase di elaborazione per il servizio post-vendita, quindi non possiamo accettare doppie presentazioni. Puoi anche verificare lo stato attuale nella tua cronologia ordini. Grazie per la comprensione.`,
    isOvertime: `Spiacenti, la tua ordinazione ha superato il periodo di garanzia.`,
    isOvertimeDesc1: `La tua ordinazione ha raggiunto la fine della sua vita utile e non può più essere estesa. Se hai domande, non esitare a contattarci.`,
    isOvertimeDesc2: `La tua ordinazione ha raggiunto la fine della sua vita utile e non può più essere rimborsata o sostituita.`,
    noOrdersExist: `Spiacenti, l'ordinazione attuale è in fase di revisione.`,
  },
  form: {
    name: "Nome",
    address: "Indirizzo",
    comments: "Commenti",
    defaultAddress: "Indirizzo predefinito",
    returnAddress: "Indirizzo di ritorno",
    problemType: "Tipo di problema",
    completePurchaseInformation: "Completa le informazioni sull'acquisto",
    orderTip:
      "Se hai già inviato una richiesta di supporto, puoi anche | cliccare qui | per controllare lo stato dettagliato e i record.",
    uploadPhoto: "Carica una foto dello scontrino d'acquisto",
    rulesEnterTips: "Inserisci il {params}",
    rulesSelectTips: "Seleziona il {params}",
    formatTips: "Formato {params} errato",
    uploadTips: "Carica una prova d'acquisto",
    uploadReceipt: "Carica una foto dello scontrino d'acquisto",
    formatError: "Errore di formato",
    pictureSize: "Dimensione dell'immagine superiore a {params}",
    processingResult: "Risultato dell'elaborazione",
    findOrderScreenshot: "Come trovo lo screenshot del mio ordine?",
    helpTips: "Di cosa hai bisogno aiuto?",
    processingMethod: "Metodo di elaborazione",
    readPolicy:
      "Si prega di leggere attentamente | la politica di reso e rimborso |",
    keepOriginalPackaging:
      "Stai mantenendo l'imballaggio originale del prodotto?",
    whetherToKeep: "se mantenere",
    problemDescription: "Descrizione del problema",
    problemDesc: "una descrizione del problema",
    uploadUp: "Carica fino a {n} immagini",
    warrantyErrTips:
      "Spiacenti, il vostro ordine ha superato il periodo di garanzia.",
    warrantyErrTipsDesc:
      "Se avete ancora bisogno di assistenza, vi preghiamo di contattare l'assistenza tecnica di {params}. Un tecnico verificherà il problema e vi fornirà un preventivo se lo desiderate.",
    OutOfwarrantyTips:
      "Questo prodotto ha superato il periodo di garanzia e non è idoneo per il supporto.",
    accessoryTips:
      "Questo prodotto è un accessorio e non è idoneo per il supporto.",
    underMaintenanceTips: "Il prodotto è attualmente in manutenzione.",
    clickViewDetail: "Fare clic qui per visualizzare i dettagli",
    warrantySuccessful: "Estensione della garanzia riuscita",
    warrantySuccessfulDesc:
      "Con la presente confermiamo che è stata concessa un'estensione di garanzia di 3 anni al vostro prodotto associato al numero d'ordine fornito.<br /> La garanzia sarà valida fino a",
    keepPackage: "Conservi l'imballaggio esterno del prodotto?",
    uploadDefect: "Carica la prova visiva del difetto",
    yes: "Sì",
    no: "No",
    returnAndRefund: "Reso e Rimborso",
    returnAndRefundFee: "Reso e Rimborso - Spese di Spedizione",
    returnsAndExchanges: "Resi e Scambi",
    returnsAndExchangesFees: "Resi e Scambi - Spese di Spedizione",
    noNeedToReturn: "Nessuna necessità di restituzione - ristampa",
    noNeedToReturnFee:
      "Nessuna necessità di restituzione - ristampa - Spese di Spedizione",
    rejection: "Rifiuto",
    reissueAccessory: "Ristampa Accessorio",
    alreadyExtendedWarranty: "Il tuo ordine ha già una garanzia estesa.",
    outOfExtendedWarranty: "Questo prodotto è fuori dalla garanzia estesa.",
    extendedWarrantyFailTips:
      "Questo prodotto ha già richiesto una garanzia estesa e non può essere richiesto nuovamente.",
    contactCustomerService:
      "Si prega di contattare il servizio clienti di Amazon. Grazie!",
    contactAmazon: "Contattare Amazon",
    hasContactAmazon: "Amazon è stato contattato?",
    addressError: "Errore di analisi dell'indirizzo, si prega di reinserire.",
  },
  router: {
    home: "Home",
    search: "Cerca",
    findeOrder: "Trova ordine",
    findeScreenshot: "Trova screenshot",
    purchaseInformation: "Informazioni sull'acquisto",
    shippingInformation: "Informazioni sulla spedizione",
    shippingLabel: "Etichetta di spedizione",
    warrantyInfo: "Informazioni sulla garanzia",
    afterSalesInfo: "Informazioni post-vendita",
    orderList: "Lista ordini",
    orderInformation: "Informazioni ordine",
    supportHistory: "Cronologia supporto",
    orderDetails: "Dettagli ordine",
    evaluationRecordDetails: "Dettagli registrazione valutazione",
    editProfile: "Modifica profilo",
    addressManagement: "Gestione degli indirizzi",
    mailboxVerification: "Verifica della casella di posta",
    resetPassword: "Ripristina password",
  },
};
