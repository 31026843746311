<template>
  <div class="nav">
    <el-row type="flex" class="row-bg" justify="space-between" align="center">
      <el-col :xl="3" :sm="8" :xs="4" class="nav-left">
        <router-link to="/">
          <img style="width: 8.6111rem" :src="$themeConfig.logoUrl" />
        </router-link>
      </el-col>
      <el-col :xl="21" :sm="16" :xs="20" class="nav-right">
        <ul class="right">
          <li style="display: flex; align-items: center">
            <img
              style="width: 1.2rem; height: 1.2rem; margin-right: 5px"
              src="@/assets//imgs/langIcon.png"
            />
            <lang />
          </li>
          <li>
            <el-icon><Message /></el-icon>{{ $themeConfig.email }}
          </li>
          <li>
            <el-icon><Phone /></el-icon>{{ $themeConfig.phone }}
          </li>
          <li>
            <el-dropdown v-if="userData">
              <!-- <span class="el-dropdown-link"
                ><el-icon><User /></el-icon
              ></span> -->
              <div class="user-info">
                <img :src="userData.headimg ? userData.headimg : noImg" />
                <p v-if="userData.account">
                  {{ userData.username || userData.account || "" }}
                </p>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <template v-if="userData.account">
                    <el-dropdown-item style="justify-content: center"
                      ><router-link
                        style="text-decoration: none; color: #606266"
                        to="/editUser"
                      >
                        {{ $t("router.editProfile") }}
                      </router-link></el-dropdown-item
                    >
                    <el-dropdown-item
                      ><router-link
                        style="text-decoration: none; color: #606266"
                        to="/address"
                      >
                        {{ $t("router.addressManagement") }}</router-link
                      ></el-dropdown-item
                    >
                    <el-dropdown-item
                      @click="handleLogout"
                      style="justify-content: center"
                      ><span style="color: #f56c6c">{{
                        $t("theme.logout")
                      }}</span></el-dropdown-item
                    >
                  </template>
                  <template v-else>
                    <el-dropdown-item @click="goLogin">{{
                      $t("theme.login")
                    }}</el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
  <div class="banner" :style="{ '--bgLogo': bgLogo }">
    <div class="title" :class="$theme == 'Comfier' ? '' : 'sl-title'">
      <h2>{{ $t("theme.systemName") }}</h2>
      <div class="tips">
        {{ $t("theme.systemDesc") }}
      </div>
    </div>
  </div>
  <Login ref="loginRef" />
</template>

<script setup>
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import { useUsersStore } from "@/stores/user";
import { watch, ref, computed, getCurrentInstance } from "vue";
import lang from "./lang.vue";
// 获取当前组件实例
const { appContext } = getCurrentInstance();
// 从应用程序上获取$theme
const theme = appContext.config.globalProperties.$theme;
let selectPng = theme == "Comfier" ? require("@/assets/imgs/bg-logo.png") : "";
const cf_default = require("@/assets/imgs/cf_default.png");
const sl_default = require("@/assets/imgs/sl_default.png");

const noImg = theme == "Comfier" ? cf_default : sl_default;
let bgLogo = ref(`url(${selectPng})`);
import { useI18n } from "vue-i18n";
const { t } = useI18n();
let loginRef = ref(null);
let userData = computed(() => useUsersStore().userInfo || {});
const router = useRouter();

const handleLogout = () => {
  ElMessageBox.confirm(t("theme.logoutTips"), {
    confirmButtonText: t("theme.logout"),
    cancelButtonText: t("theme.cancel"),
  })
    .then(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      router.go(0);
    })
    .catch(() => {});
};
const goLogin = () => {
  loginRef.value.init();
};
</script>
<style scoped lang="less">
.nav {
  border-top: 5px solid var(--color-primary);
  &-left {
    display: flex;
    align-items: center;
  }

  .row-bg {
    background-color: #fff;
    padding: 0 40px;
    .sl-logo {
      height: 1.8rem;
    }
    .right {
      display: flex;
      justify-content: end;
      padding: 0.8333rem 0;
      li {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-primary);
        .el-icon {
          font-size: 1.5rem;
          margin-right: 0.8333rem;
          color: var(--color-primary);
        }
      }
    }
  }
}
.user-info {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #888;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 10px;
  }
  p {
    max-width: 11rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.banner {
  text-align: center;
  background-color: var(--color-primary);
  width: 100%;
  height: 19rem;
  position: relative;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 21vw;
  //   top: 7rem;
  //   width: 33.5556rem;
  //   height: 2.6667rem;
  //   background-image: url("@/assets/imgs/bg-text.png");
  //   background-size: 100%;
  //   z-index: 9;
  // }
  &::before {
    content: "";
    position: absolute;
    right: 18vw;
    bottom: 0;
    width: 21.4444rem;
    height: 17rem;
    background-image: var(--bgLogo);
    background-size: 100%;
    z-index: 9;
  }
  .title {
    position: absolute;
    left: 15vw;
    top: 5.8rem;
  }
  .sl-title {
    left: 35%;
    top: 5rem;
  }
  h2 {
    color: #fff;
    margin: 1rem 0;
    font-size: 2.6667rem;
  }
  .tips {
    color: #fff;
    font-size: 1.4rem;
  }
  .logout {
    position: absolute;
    right: 2vw;
    top: 1rem;
    color: #fff;
    cursor: pointer;
  }
}
@media screen and (max-width: 750px) {
  .banner {
    display: none;
  }
}
</style>
