import i18n from "@/lang";
const { t } = i18n.global;
export const rule = {
  /**
   * 校验 请输入中文、英文、数字包括下划线
   * 名称校验
   */
  validatorNameCn(rule, value, callback) {
    let acount = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
    if (value && !acount.test(value)) {
      callback(new Error("请输入中文、英文、数字包括下划线"));
    } else {
      callback();
    }
  },
  /**
   * 校验 请输入英文、数字包括下划线
   * 名称校验
   */
  validatorName(rule, value, callback) {
    let acount = /^[A-Z_]+$/;
    if (value && !acount.test(value)) {
      callback(new Error("请输入大写英文、下划线"));
    } else {
      callback();
    }
  },
  /**
   * 校验 请输入中文、英文、数字包括下划线
   * 名称校验
   */
  validatorKey(rule, value, callback) {
    let acount = /^[A-Z_]+$/;
    if (value && !acount.test(value)) {
      callback(new Error("请输入大写英文、下划线"));
    } else {
      callback();
    }
  },

  /**
   * 校验首尾空白字符的正则表达式
   *
   */
  checkSpace(rule, value, callback) {
    let longrg = /[^\s]+$/;
    if (!longrg.test(value)) {
      callback(new Error("请输入非空格信息"));
    } else {
      callback();
    }
  },
  /**
   * 邮箱校验
   */
  checkEmail(rule, value, callback) {
    // /^([a-zA-Z0-9_-|.])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
    let regEmail = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]{2,})$/;
    if (!value) {
      callback(new Error());
    }
    if (!regEmail.test(value)) {
      callback(new Error(t("login.emailFormat")));
    } else {
      callback();
    }
  },
  /**
   * zip code校验
   */
  checkZipCode(rule, value, callback) {
    let regCode = /^[0-9]*$/;
    if (!value) {
      callback(
        new Error(t("form.rulesEnterTips"), {
          params: t("theme.zipcode").toLocaleLowerCase(),
        })
      );
    }
    if (!regCode.test(value)) {
      callback(
        new Error(
          t("login.vaildFormat", {
            params: t("theme.zipcode").toLocaleLowerCase(),
          })
        )
      );
    } else {
      callback();
    }
  },
};
