/**
 * 设置主题色
 * @param key
 * @param value
 */
export const setThemeColor = (key, value) => {
  const elm = window.document.querySelector("body");
  if (elm) {
    elm.style.setProperty(key, value);
    elm.style.setProperty(key + "-light", value + "14");
    for (let i = 0; i < 8; i++) {
      // #87b9b3
      elm.style.setProperty(key + "-light-" + (i + 2), lighten(value, i / 10));
      elm.style.setProperty(key + "-dark-" + (i + 2), darken(value, i / 10));
    }
  }
};
export function hex2Rgb(color) {
  color = color.replace("#", "");
  const result = color.match(/../g);
  for (let i = 0; i < 3; i++) {
    result[i] = parseInt(result[i], 16);
  }
  return result;
}
export function rgb2Hex(r, g, b) {
  const hexs = [r.toString(16), g.toString(16), b.toString(16)];
  for (let i = 0; i < 3; i++) {
    if (hexs[i].length === 1) {
      hexs[i] = "0" + hexs[i];
    }
  }
  const result = "#" + hexs.join("");
  return result;
}
export function lighten(color, level) {
  const rgb = hex2Rgb(color);
  for (let i = 0; i < 3; i++) {
    rgb[i] = Math.floor((255 - rgb[i]) * level + rgb[i]);
  }
  const result = rgb2Hex(rgb[0], rgb[1], rgb[2]);
  return result;
}
export function darken(color, level) {
  const rgb = hex2Rgb(color);
  for (let i = 0; i < 3; i++) {
    rgb[i] = Math.floor(rgb[i] * (1 - level));
  }
  const result = rgb2Hex(rgb[0], rgb[1], rgb[2]);
  return result;
}
